const env = "prod"; //prod | dev
const config = {
    dev: {
        APP_ENV_NAME: "development",
        APP_BASE_API_URL: "http://gateway.5paisa.in/",
        APP_OCP_APIM_KEY: "98180aa74c694bbd92393f3bb8160573",
        APP_HEAD_KEY: "4dddd59bdab77bace6189d001f96487e",
        APP_HEAD_REQUEST_CODE: "5PGOU",
    },
    prod: {
        APP_ENV_NAME: "production",
        APP_BASE_API_URL: "https://gateway.5paisa.com/",
        APP_OCP_APIM_KEY: "a4af51382266497bb5464d95fbb2017b",
        APP_HEAD_KEY: "8eeee59bdab88bace6189d001f96487e",
        APP_HEAD_REQUEST_CODE: "5PNPNWV2",
    },
};
export const getConfig = () => {
    return config[env];
};
// (() => {
//   var wzrk = document.createElement("script");
//   wzrk.type = "text/javascript";
//   wzrk.async = true;
//   wzrk.src =
//     ("https:" == document.location.protocol
//       ? "https://d2r1yp2w7bby2u.cloudfront.net"
//       : "http://static.clevertap.com") + "/js/clevertap.min.js";
//   var firstScript = document.querySelector("script");
//   if (firstScript && firstScript.parentNode) {
//     firstScript.parentNode.insertBefore(wzrk, firstScript);
//   } else {
//     document.body.appendChild(wzrk);
//   }
// })();
