//@ts-nocheck
import { checkIsEmpty, uintToJson, getToken } from "../helpers";
import { SOCKET_EVENT } from "./constant";
// import {
//   SESSION_KEYS,
//   getCompleteSessionData,
//   getDataFromSession,
// } from "./session-storage";
// const marketFeedSubMap: Record<string, CallbackMethod> = {};
// const greeksSubMap: Record<string, CallbackMethod> = {};
// const depthSubMap: Record<string, CallbackMethod> = {};
// const oiSubMap: Record<string, CallbackMethod> = {};
let feedSocketRef;
let token = await getToken(localStorage.TokenResponse);
let localclientCode = localStorage.getItem("ClientCode");
const subscriberList = [];
//const heartBeatSubscriberList: SubscriptionMethod[] = [];
const scripMap = {};
// const reqTypeUpdate = {
//   R: { position: false, orders: true }, // R reject
//   T: { position: true, orders: true }, // T Fully executed
//   P: { position: true, orders: true }, // P placed/Pending
//   M: { position: false, orders: true }, // M modified
//   C: { position: false, orders: true }, // C Cancelled
// };
const publishMessage = (eventType, data) => {
    if (feedSocketRef != undefined) {
        // console.log("This is a tribute GD", eventType, data);
        feedSocketRef.postMessage({ eventType: eventType, data: data });
    }
};
// let lastSyncTime = Date.now();
// const checkAndLogMessage = (
//   method: "CONNECT" | "DISCONNECT" | "SOCKET_DATA_STREAM_STOPPED",
//   moreInfo?: object
// ): void => {
//   let clientCode = getDataFromSession(SESSION_KEYS.CLIENT_CODE);
//   if (!clientCode) {
//     clientCode = "";
//   }
//   logAdvancedMessage(method, clientCode, moreInfo);
//   // if (
//   //   remoteConfigData &&
//   //   !isEmpty(clientCode) &&
//   //   remoteConfigData.status == "ON"
//   // ) {
//   //   const isClientCodePresent = remoteConfigData.clientlist.find(
//   //     (singleClientCode) => {
//   //       return singleClientCode == clientCode;
//   //       // return singleClientCode == "57675310";
//   //     }
//   //   );
//   // }
// };
// const resetLastSyncTime = (reason: string): void => {
//   lastSyncTime = Date.now();
//   const getSocketErrorEventTime = "ws-error";
//   if (getSocketErrorEventTime) {
//     const message = {
//       duration: Date.now() - getSocketErrorEventTime,
//       reason: reason,
//       disconnectedError: "",
//     };
//     // checkAndLogMessage("SOCKET_DATA_STREAM_STOPPED", message);
//     // setDataInSession(SESSION_KEYS.WS_ERROR, undefined);
//   }
// };
export const initFeedSocket = () => {
    if (feedSocketRef == undefined) {
        feedSocketRef = new Worker(new URL("websocket.worker.ts", import.meta.url));
        // console.log("This is a tribute GD feedsocketref", feedSocketRef);
        // console.log(
        //   "This is a tribute GD feedsocketref",
        //   feedSocketRef.postMessage
        // );
        if (window.Worker) {
            let accessToken = token.access_token;
            const swarajWsurl = `wss://ChartFeedsSocket.5paisa.com/ws?access_token=${accessToken}`;
            publishMessage("CONNECT_SOCKET", {
                wsUrl: swarajWsurl,
                clientCode: localclientCode,
            });
            feedSocketRef.onmessage = (e) => {
                // console.log("This is a tribute GD feedSocketref.onmessage", e);
                try {
                    const eventData = uintToJson(e.data);
                    // if (eventData.data.Token == "999920000") {
                    //   console.log(
                    //     Date.now(),
                    //     "This is a tribute to Nifty",
                    //     eventData.data
                    //   );
                    // }
                    handleAndDispatchMessage(eventData.eventType, eventData.data);
                }
                catch (error) {
                    console.error(error);
                }
                // requestAnimationFrame(() => {
                // });
            };
        }
    }
    else {
        onFocusChange(true);
    }
};
export const subscribeToData = (subscriptionMethod, subscriberName, callBackMethod) => {
    let subsData = subscriberList.find((singleSubData) => {
        if (subscriptionMethod.Method === singleSubData.Method) {
            return singleSubData;
        }
    });
    if (subsData) {
        subsData.MarketFeedData.push(...subscriptionMethod.MarketFeedData);
        // scripMap
        subsData.MarketFeedData = arrayUnique(subsData.MarketFeedData);
    }
    else {
        subsData = subscriptionMethod;
        subscriberList.push(subscriptionMethod);
    }
    subscriptionMethod.MarketFeedData.forEach((singleElement) => {
        if (callBackMethod) {
            const isNew = addSingleScripSubscriber(subscriberName, singleElement, callBackMethod, subscriptionMethod.Method);
        }
    });
    publishMessage("SUBSCRIBE", {
        ...subscriptionMethod,
        subscriberName,
    });
    // }
};
const addSingleScripSubscriber = (subscriberName, marketFeedSingleElement, callBackMethod, methodName) => {
    let scripObj = scripMap[marketFeedSingleElement.Exch +
        marketFeedSingleElement.ExchType +
        marketFeedSingleElement.ScripCode];
    if (scripObj) {
        const subsObj = scripObj.subscribers.find((singleElement) => {
            const isAlreadySubscribed = singleElement.componentName == subscriberName;
            return isAlreadySubscribed;
        });
        if (subsObj) {
            const alreadySubscribedMethod = subsObj.method.find((singleElement) => {
                return singleElement == methodName;
            });
            //Just updating it in case there was rerender.Ideally this component itself will not call again
            subsObj.callback = callBackMethod;
            if (checkIsEmpty(alreadySubscribedMethod)) {
                subsObj.method.push(methodName);
                return true;
            }
            else {
                return false;
            }
        }
        else {
            scripObj.subscribers.push({
                method: [methodName],
                callback: callBackMethod,
                componentName: subscriberName,
            });
            return true;
        }
    }
    else {
        scripObj = {
            subscribers: [
                {
                    method: [methodName],
                    callback: callBackMethod,
                    componentName: subscriberName,
                },
            ],
            data: undefined,
            lastUpdateTime: undefined,
        };
        scripMap[marketFeedSingleElement.Exch +
            marketFeedSingleElement.ExchType +
            marketFeedSingleElement.ScripCode] = scripObj;
        return true;
    }
};
export const unsubscribeDataBySubscriber = (subscriberName, methodName) => {
    publishMessage(SOCKET_EVENT.UNSUBSCRIBE_BY_SUBSCRIBER, {
        subscriberName,
        methodName,
    });
    Object.keys(scripMap).forEach((singleScripKey) => {
        unSubscribeDataByScripKey(subscriberName, singleScripKey, methodName);
    });
};
export const unsubscribeDataByScrip = (subscriberName, marketFeedSingleElement, methodName) => {
    publishMessage(SOCKET_EVENT.UNSUBSCRIBE, {
        subscriberName,
        scripData: marketFeedSingleElement,
        methodName,
    });
    unSubscribeDataByScripKey(subscriberName, getScripKey(marketFeedSingleElement), methodName);
};
const unSubscribeDataByScripKey = (subscriberName, singleScripKey, methodName) => {
    const scripObj = scripMap[singleScripKey];
    if (scripObj) {
        if (checkIsEmpty(methodName)) {
            const newSubList = scripObj.subscribers.filter((singleElement) => {
                return singleElement.componentName != subscriberName;
            });
            if (newSubList.length > 0) {
                scripObj.subscribers = newSubList;
            }
            else {
                delete scripMap[singleScripKey];
            }
        }
        else {
            const newSubList = scripObj.subscribers.filter((singleElement) => {
                return singleElement.componentName == subscriberName;
            });
            if (newSubList.length > 0) {
                const newMethodList = newSubList[0].method.filter((singleMethod) => {
                    return singleMethod != methodName;
                });
                if (newMethodList.length > 0) {
                    newSubList[0].method = newMethodList;
                }
                else {
                    //Recursive call as we need to remove the subscriber itself
                    unSubscribeDataByScripKey(subscriberName, singleScripKey);
                }
            }
        }
    }
};
const arrayUnique = (array) => {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i].ScripCode == a[j].ScripCode && a[i].Exch == a[j].Exch)
                a.splice(j--, 1);
        }
    }
    return a;
};
const getScripKey = (marketFeedSingleElement) => {
    return (marketFeedSingleElement.Exch +
        marketFeedSingleElement.ExchType +
        marketFeedSingleElement.ScripCode);
};
// const updatePositionOrderBook = (reqType: string): void => {
//   //Update  Positoin if ReqType Found
//   //console.log(reqTypeUpdate[reqType]?.position, "REQTYPE CHECK FUN", reqTypeUpdate[reqType]?.orders);
//   if (reqTypeUpdate[reqType]?.position) {
//     store.dispatch(
//       positionUpdateSocketTrigger(reqType + moment().format("HH:mm:ss"))
//     );
//   }
//   //Update Order Book if ReqType Found
//   if (reqTypeUpdate[reqType]?.orders) {
//     store.dispatch(
//       orderUpdateSocketTrigger(reqType + moment().format("HH:mm:ss"))
//     );
//   }
//   //Update Order Book and Position if uncertain ReqType received
//   if (!reqTypeUpdate[reqType]) {
//     store.dispatch(positionUpdateSocketTrigger(reqType));
//     store.dispatch(orderUpdateSocketTrigger(reqType));
//   }
//   return;
// };
//-----------Listening and Dispatching message section-------------------
const handleAndDispatchMessage = (methodName, message) => {
    // if (message?.ReqType) {
    //   console.log(message.ReqType, "RETYPE RECEIVED AT FUN", getDataFromSession(SESSION_KEYS.CHECK_FIREBASE_POLLING));
    // }
    //if (message && message.Exch) {
    if (message) {
        if (message.PacketIdentifier && (message === null || message === void 0 ? void 0 : message.PacketIdentifier) == "G") {
            message.Exch = message.Exch ? message.Exch : "N";
            message.ExchType = message.ExchType ? message.ExchType : "D";
        }
        else if (message.Exch == undefined) {
            return;
        }
        // if (Date.now() - lastSyncTime > 3000) {
        //   resetLastSyncTime("DATA_STARTED_COMING");
        //   // store.dispatch(openSocketNotification(false));
        // }
        const key = message.Exch + message.ExchType + message.Token + "";
        const scripObj = scripMap[key];
        if (scripObj) {
            scripObj.lastUpdateTime = new Date();
            // let dataObj: {
            //   openInterest?: SocketResponseSyncObject;
            //   marketDepthData?: SocketResponseSyncObject;
            //   marketFeedData?: SocketResponseSyncObject;
            //   greekFeedData?: SocketResponseSyncObject;
            // };
            // if (scripObj?.data) {
            //   dataObj = scripObj.data;
            // } else {
            //   dataObj = {};
            //   scripObj.data = dataObj;
            // }
            // let selectedSocketResponseSyncObject: SocketResponseSyncObject;
            // let methodName = "";
            // if (!selectedSocketResponseSyncObject.syncTime) {
            //   dispatchSingleMessage(scripObj, selectedSocketResponseSyncObject);
            // }
            const isDataSubscribed = checkAnddispatchDataUsingCallBack(scripObj, message, methodName);
            // if (isDataSubscribed) {
            //   //Commented this to remove throttling completely
            //   // if (REACT_APP_ENV == "staging" || REACT_APP_ENV == "development") {
            //   //   dispatchSingleMessage(scripObj, selectedSocketResponseSyncObject);
            //   // } else
            //   if (!selectedSocketResponseSyncObject.syncTime) {
            //     dispatchSingleMessage(scripObj, message, methodName);
            //   }
            //   // } else {
            //   //   // unsubscribeSpecificMethodFromSocket(
            //   //   //   message.Exch,
            //   //   //   message.ExchType,
            //   //   //   message.Token,
            //   //   //   methodName
            //   //   // );x
            //   // }
            // }
            // else if (isSocketConnected) {
            //   unsubscribeFromSocket(message.Exch, message.ExchType, message.Token);
            // }
        }
    }
};
const checkAnddispatchDataUsingCallBack = (scripObj, message, methodName) => {
    let isDataSubscribed = false;
    // if (message?.Token == 35003) {
    //   console.log(
    //     "This is a tribute nifty fut in callback socket",
    //     message,
    //     scripObj
    //   );
    // }
    scripObj.subscribers.forEach((singleSubscriber) => {
        let localSubsribed = false;
        singleSubscriber.method.forEach((singleMethod) => {
            if (singleMethod == methodName) {
                isDataSubscribed = true;
                localSubsribed = true;
            }
        });
        if (localSubsribed && singleSubscriber.callback) {
            // if (message?.Token == 999920000) {
            // console.log(
            //   Date.now(),
            //   "This is a tribute to nifty",
            //   message,
            //   singleSubscriber
            // );
            // }
            singleSubscriber.callback(message);
        }
    });
    return isDataSubscribed;
};
// const dispatchSingleMessage = (
//   scripObj: SocketScripObject,
//   message: any,
//   methodName: string
// ): void => {
//   console.log("Dispatch");
//   // if (message?.Token == 35003) {
//   //   console.log("This is a tribute nifty fut", message);
//   // }
//   // if (methodName == SOCKET_EVENT.GREEKS_DATA) {
//   //   store.dispatch(setGreeksMsg(message));
//   // } else {
//   store.dispatch(setMessage(message));
//   // }
// };
export const onFocusChange = (isWindowFocused) => {
    // if (isSocketConnected) {
    //   return;
    // }
    publishMessage(SOCKET_EVENT.IS_WINDOW_FOCUSED, isWindowFocused);
};
//--- unused methods for now-----------------
export const heartBeatData = (subscriptionMethod, subscriberName, callbackMethod) => {
    return subscribeToData(subscriptionMethod, subscriberName, callbackMethod);
};
// const subscribeToData = (
//   subscriptionMethod: SubscriptionMethod,
//   subscriberName: string,
//   callBackMethod: any
// ): void => {
//   if (subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.MARKET_FEED_V3) {
//     marketFeedSubMap[subscriberName] = callBackMethod;
//   } else if (
//     subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.BASE_GREEKS
//   ) {
//     greeksSubMap[subscriberName] = callBackMethod;
//   } else if (
//     subscriptionMethod.Method ==
//     SOCKET_SUBSCRIPTION_METHODS.SCRIPT_INFO_FOR_FUTURE
//   ) {
//     oiSubMap[subscriberName] = callBackMethod;
//   } else if (
//     subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.DEPTH_SERVICE
//   ) {
//     depthSubMap[subscriberName] = callBackMethod;
//   }
//   publishMessage(SOCKET_EVENT.SUBSCRIBE, subscriptionMethod);
// };
// const unSubscribeToData = (
//   subscriptionMethod: SubscriptionMethod,
//   subscriberName: string
// ): void => {
//   if (subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.MARKET_FEED_V3) {
//     delete marketFeedSubMap[subscriberName];
//   } else if (
//     subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.BASE_GREEKS
//   ) {
//     delete greeksSubMap[subscriberName];
//   } else if (
//     subscriptionMethod.Method ==
//     SOCKET_SUBSCRIPTION_METHODS.SCRIPT_INFO_FOR_FUTURE
//   ) {
//     delete oiSubMap[subscriberName];
//   } else if (
//     subscriptionMethod.Method == SOCKET_SUBSCRIPTION_METHODS.DEPTH_SERVICE
//   ) {
//     delete depthSubMap[subscriberName];
//   }
//   publishMessage(SOCKET_EVENT.SUBSCRIBE, subscriptionMethod);
// };
