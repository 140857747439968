export default {
    //check device
    checkAndGetOsName: () => {
        let detectedOS = "UNKNOWN";
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (navigator.appVersion.indexOf("Mac") != -1)
            detectedOS = "MACOS";
        if (navigator.appVersion.indexOf("Win") != -1)
            detectedOS = "WINDOWS";
        if (navigator.appVersion.indexOf("Linux") != -1)
            detectedOS = "LINUX";
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return detectedOS;
    },
    //get browser name
    getBrowserName: () => {
        const userAgent = navigator.userAgent;
        let browserName;
        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "CHROME";
        }
        else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "FIREFOX";
        }
        else if (userAgent.match(/safari/i)) {
            browserName = "SAFARI";
        }
        else if (userAgent.match(/opr\//i)) {
            browserName = "OPERA";
        }
        else if (userAgent.match(/edg/i)) {
            browserName = "EDGE";
        }
        else {
            browserName = "UNKNOWN";
        }
        return browserName;
    },
};
