// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB_gteKiDdJKB2dGZLD0X1GXEvZWa9XZXA",
    authDomain: "paisa-mf.firebaseapp.com",
    databaseURL: "https://paisa-mf.firebaseio.com",
    projectId: "paisa-mf",
    storageBucket: "paisa-mf.appspot.com",
    messagingSenderId: "945111842441",
    appId: "1:945111842441:web:62b18ca398857c472b5058",
    measurementId: "G-FW30J08J2Z",
};
export const initializeFireBaseAppAndRemoteConfig = () => {
    if (!window.firebase) {
        return;
    }
    try {
        const app = window.firebase.initializeApp(firebaseConfig);
        const remoteConfig = window.firebase.getRemoteConfig(app);
        remoteConfig.settings.minimumFetchIntervalMillis = 60000;
        let remoteConfigData;
        window.firebase
            .fetchAndActivate(remoteConfig)
            .then(() => {
            const frontend_crossSell_keys = window.firebase
                .getValue(remoteConfig, "frontend_crossSell_keys")
                .asString();
            try {
                remoteConfigData = JSON.parse(frontend_crossSell_keys);
                // console.log("PS remoteConfigData", remoteConfigData);
                localStorage.getItem("tradingview.current_theme.name")
                    ? window.location.replace(remoteConfigData.LoginRedirectionURL +
                        "?returnurl=" +
                        window.location.origin +
                        `&theme=${localStorage.getItem("tradingview.current_theme.name")}`)
                    : window.location.replace(remoteConfigData.LoginRedirectionURL +
                        "?returnurl=" +
                        window.location.origin);
            }
            catch (error) {
                console.error("Error while parsing and getting key values");
                window.location.replace("https://login.5paisa.com/?returnurl=" + window.location.origin);
            }
        })
            .catch((err) => {
            console.error(err, "Fetch and Activate failed");
            window.location.replace("https://login.5paisa.com/?returnurl=" + window.location.origin);
        });
    }
    catch (err) {
        console.error("Firebase config failed Error", err);
        window.location.replace("https://login.5paisa.com/?returnurl=" + window.location.origin);
    }
};
