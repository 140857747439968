import { makeApiRequest } from "../helpers";
import { APPNAME, APPVER, APP_SOURCE, OSNAME, REACT_APP_API_HEADER_KEY, REACT_APP_BASE_URL, REACT_APP_TRADE_API_GATEWAY_KEY, } from "../utils/constant";
export const checkLogin = () => {
    return makeApiRequest(`${REACT_APP_BASE_URL}tradeapi/CheckLoginResponse`, {
        method: "POST",
        credentials: "include",
        headers: {
            "content-type": "application/json",
            "Ocp-Apim-Subscription-Key": REACT_APP_TRADE_API_GATEWAY_KEY || "",
            //Authorization: `Bearer ${getJwtToken()}`,
        },
        body: JSON.stringify({
            body: {},
            head: {
                appName: APPNAME,
                appSource: APP_SOURCE,
                appVer: APPVER,
                key: REACT_APP_API_HEADER_KEY || "",
                osName: OSNAME,
                requestCode: "5PCLRes",
            },
        }),
    });
};
//JaSMYKpkOb1EV9LnfYJYbg==
//G/4txWu/ryzebd+Gq7EXNg==
//YAftqUV3rrXnbuCkD9Z+LA==
export const checkDevLogin = (emailid, password, dob) => {
    return makeApiRequest(`http://gateway.5paisa.in/tradeapi/V5/LoginRequestMobileNewbyEmail`, {
        method: "POST",
        credentials: "include",
        headers: {
            "content-type": "application/json",
            "Ocp-Apim-Subscription-Key": "98180aa74c694bbd92393f3bb8160573",
            UserID: "Hek68PU5r76",
            Password: "D86utK8Mn7",
        },
        body: JSON.stringify({
            head: {
                appName: APPNAME,
                appVer: APPVER,
                key: "4dddd59bdab77bace6189d001f96487e",
                osName: OSNAME,
                requestCode: "5PLoginV5",
            },
            body: {
                Email_id: emailid,
                Password: password,
                VersionNo: "3.1",
                RequestNo: "1",
                My2PIN: dob,
                ConnectionType: "1",
            },
        }),
    });
};
