/* ------------ For login api ------------------- */
export const REACT_APP_BASE_URL = "https://gateway.5paisa.com/";
export const REACT_APP_API_HEADER_KEY = "8eeee59bdab88bace6189d001f96487e";
export const REACT_APP_TRADE_API_GATEWAY_KEY = "a4af51382266497bb5464d95fbb2017b";
export const APPNAME = "5PTRADE";
export const APPVER = "1.0";
export const APP_SOURCE = "21";
export const SESSIONTRANFERKEY = "4dddd59bdab77bace6189d001f96487e";
export const OSNAME = "Web"; //"Android";
export const REQUESTCODE = "5PSTWV";
export const GET_PREFERENCE_BASE_URL = "https://gateway.5paisa.com/tradeapi/chart/preference/";
export const POST_PREFERENCE_BASE_URL = "https://gateway.5paisa.com/tradeapi/post/chart/preference/";
export const METHOD_FOR_CHART_SOCKET = "subscribe";
export const OPT_FOR_CHART_SOCKET = "marketfeed";
export const UNSUBS_METHOD_FOR_CHART_SOCKET = "unsubscribe";
export const SOCKET_SUBSCRIPTION_METHODS = {
    MARKET_FEED_V3: "MarketFeedV3",
    DEPTH_20_SERVICE: "20depth",
    BASE_GREEKS: "optiongreek",
    DEPTH_SERVICE: "MarketDepthService",
    SCRIPT_INFO_FOR_FUTURE: "GetScripInfoForFuture",
    NEW_BASE_GREEKS: "optiongreek",
    GREEKS_INSTRUMENT: "OG",
};
export const SOCKET_EVENT = {
    CONNECT_SOCKET: "CONNECT_SOCKET",
    DISCONNECT_SOCKET: "DISCONNECT_SOCKET",
    SOCKET_STATUS: "SOCKET_STATUS",
    SUBSCRIBE: "SUBSCRIBE",
    UNSUBSCRIBE: "UNSUBSCRIBE",
    UNSUBSCRIBE_BY_SUBSCRIBER: "UNSUBSCRIBE_BY_SUBSCRIBER",
    UNSUBSCRIBE_ALL: "UNSUBSCRIBE_ALL",
    RESUBSCRIBE: "RESUBSCRIBE",
    RECONNECT_TO_SOCKET: "RECONNECT_TO_SOCKET",
    SOCKET_ERROR: "SOCKET_ERROR",
    IS_WINDOW_FOCUSED: "IS_WINDOW_FOCUSED",
    SOCKET_DATA: "SOCKET_DATA",
    MARKETFEED_DATA: SOCKET_SUBSCRIPTION_METHODS.MARKET_FEED_V3,
    DEPTH_DATA: SOCKET_SUBSCRIPTION_METHODS.DEPTH_SERVICE,
    DEPTH_20_DATA: SOCKET_SUBSCRIPTION_METHODS.DEPTH_20_SERVICE,
    OPEN_INTEREST_DATA: SOCKET_SUBSCRIPTION_METHODS.SCRIPT_INFO_FOR_FUTURE,
    GREEKS_DATA: SOCKET_SUBSCRIPTION_METHODS.BASE_GREEKS,
    ORDER_DATA: "ORDER_DATA",
    LOGGLY_DATA: "LOGGLY_DATA",
    ANALYTICS_API_FAILURE: "ANALYTICS_API_FAILURE",
    CALL_POSITION_DATA: "CALL_POSITION_DATA",
};
/* -------------- end of login api ----------------- */
