const getPageVisibility = ({ setIsWindowVisible, }) => {
    const browserPrefixes = ["moz", "ms", "o", "webkit"];
    let isVisible = true; // internal flag, defaults to true
    // get the correct attribute name
    const getHiddenPropertyName = (prefix) => {
        return prefix ? prefix + "Hidden" : "hidden";
    };
    // get the correct event name
    const getVisibilityEvent = (prefix) => {
        return (prefix ? prefix : "") + "visibilitychange";
    };
    // get current browser vendor prefix
    const getBrowserPrefix = () => {
        for (let i = 0; i < browserPrefixes.length; i++) {
            if (getHiddenPropertyName(browserPrefixes[i]) in document) {
                // return vendor prefix
                return browserPrefixes[i];
            }
        }
        // no vendor prefix needed
        return null;
    };
    // bind and handle events
    const browserPrefix = getBrowserPrefix(), hiddenPropertyName = getHiddenPropertyName(browserPrefix), visibilityEventName = getVisibilityEvent(browserPrefix);
    const onVisible = () => {
        // prevent double execution
        if (isVisible) {
            return;
        }
        setIsWindowVisible(true);
        // change flag value
        isVisible = true;
        document.removeEventListener("mousemove", onMouseMoveCallBack);
        document.removeEventListener("keydown", onMouseMoveCallBack);
    };
    const onHidden = () => {
        // prevent double execution
        // console.log("Priyansh");
        if (!isVisible) {
            return;
        }
        setIsWindowVisible(false);
        // change flag value
        isVisible = false;
        // const rootDiv = document.getElementById("root");
        document.addEventListener("mousemove", onMouseMoveCallBack);
        document.addEventListener("keydown", onMouseMoveCallBack);
    };
    const onMouseMoveCallBack = (event) => {
        // console.log(
        //   "This is a tribute mousehove and onkeydown",
        //   JSON.stringify(event)
        // );
        handleVisibilityChange(true);
    };
    const handleVisibilityChange = (forcedFlag) => {
        // forcedFlag is a boolean when this event handler is triggered by a
        // focus or blur eventotherwise it's an Event object
        if (typeof forcedFlag === "boolean") {
            if (forcedFlag) {
                return onVisible();
            }
            return onHidden();
        }
        if (document[hiddenPropertyName]) {
            return onHidden();
        }
        return onVisible();
    };
    document.addEventListener(visibilityEventName, handleVisibilityChange, false);
    return isVisible;
};
export default getPageVisibility;
