import { subscribeToData } from "./utils/websocketwrapper";
import { accountSummaryColumns, ordersPageColumns, positionsPageColumns, holdingsColumns, closed_positionsPageColumns, } from "./columns";
import { getConfig } from "./config/config";
import { copyTextToClipboard } from "./utils/clipboard";
import getPageVisibility from "./utils/tabFocus";
import getInternetStatus from "./utils/intLost";
import { captureEvent } from "./helpers";
import { METHOD_FOR_CHART_SOCKET, OPT_FOR_CHART_SOCKET, } from "./utils/constant";
// Now you can use 'socket' in your broker.ts file for further operations.
const { APP_BASE_API_URL, APP_OCP_APIM_KEY, APP_HEAD_KEY, APP_HEAD_REQUEST_CODE, } = getConfig();
const activeOrderStatuses = [3 /* OrderStatus.Inactive */, 6 /* OrderStatus.Working */];
let data_to_return, book_data_five_sec, pos_book_data_five_sec, order_book_data_five_sec, data_hol, TimeOrder, currentTime, pos_time, data_close;
let product_flag, def_close_position = false;
let isGeneratePinRunning = false;
let posbookedPL = [], posunbookedPL = [];
let intervalId = undefined;
class ScripDetailsService {
    constructor() {
        this.cachedVal = {};
        this.ongoingRequests = new Map();
    }
    static getInstance() {
        if (!ScripDetailsService.instance) {
            ScripDetailsService.instance = new ScripDetailsService();
        }
        return ScripDetailsService.instance;
    }
    async ScripDetailsFoFOOrder(exch, ExchType, SCode) {
        const cacheKey = `${exch}-${ExchType}-${SCode}`;
        // Return cached result if it exists
        if (this.cachedVal[cacheKey]) {
            return Promise.resolve(this.cachedVal[cacheKey]);
        }
        // Return ongoing request if it exists
        if (this.ongoingRequests.has(cacheKey)) {
            return this.ongoingRequests.get(cacheKey);
        }
        // Create a new request
        const request = this.fetchScripDetailsFoFOOrder(exch, ExchType, SCode)
            .then(data => {
            this.cachedVal[cacheKey] = data; // Cache the result
            this.ongoingRequests.delete(cacheKey); // Remove from ongoing requests
            return data;
        })
            .catch(error => {
            this.ongoingRequests.delete(cacheKey); // Remove from ongoing requests
            throw error;
        });
        this.ongoingRequests.set(cacheKey, request);
        return request;
    }
    async fetchScripDetailsFoFOOrder(exch, ExchType, SCode) {
        //dev Api start
        // try {
        //     const response = await fetch(
        //         `${APP_BASE_API_URL}tradeapi/V4/ScripDetailsFoFOOrder`,
        //         {
        //             method: "POST",
        //             credentials: "include",
        //             headers: {
        //                 Authorization: `Bearer ${localStorage.getItem("JWTToken")?.replace("JWTToken", "")} `,
        //                 ClientId: "NTk5NDkwMDQ=",
        //                 "Content-Type": "application/json",
        //                 "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
        //             },
        //             body: JSON.stringify({
        //                 Exch: exch,
        //                 ExchType: ExchType,
        //                 ScripCode: Number(SCode),
        //             }),
        //         }
        //     );
        //     if (!response.ok) {
        //         throw new Error("Request failed with status " + response.status);
        //     }
        //     const data = await response.json();
        //     return data; // Resolve the promise with the API response data
        // } catch (error) {
        //     return Promise.reject(error); // Reject the promise if there's an error
        // }
        //dev API end
        //prod
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("UserID", "ZyT47UW2g56");
            myHeaders.append("Password", "H98qlU4Sn2");
            myHeaders.append("Ocp-apim-subscription-key", "df55f1c3f19646bb9cf803d1dafe316b");
            const response = await fetch(`${APP_BASE_API_URL}Tradestation/ScripDetailsFoFOOrder`, {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: JSON.stringify({
                    Exch: exch,
                    ExchType: ExchType,
                    ScripCode: SCode,
                }),
                redirect: "follow",
            });
            if (!response.ok) {
                throw new Error("Request failed with status " + response.status);
            }
            const data = await response.json();
            return data; // Resolve the promise with the API response data
        }
        catch (error) {
            return Promise.reject(error); // Reject the promise if there's an error
        }
        // prod end
    }
}
class OrderHandler {
    constructor(jwtToken, clientCode, appSource, Short_FNO_Data) {
        this.jwtToken = jwtToken;
        this.clientCode = clientCode;
        this.appSource = appSource;
        this.Short_FNO_Data = Short_FNO_Data;
        this._idsCounter = 0;
        this.marketStatusValues = [];
    }
    async fetchMarketStatus() {
        var _a;
        const marketResponse = await fetch(`${APP_BASE_API_URL}/tradeapi/MarketStatus`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${this.jwtToken}`,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
        });
        const res1 = await marketResponse.json();
        const tempdata = (_a = res1.body) === null || _a === void 0 ? void 0 : _a.Data;
        this.marketStatusValues = tempdata.map((item) => ({
            marketstatus: item.MarketStatus,
            exchangeVal: item.Exch,
            exchangeTypeVal: item.ExchType,
        }));
    }
    getMarketStatus(exchange, ExchangeType) {
        for (const item of this.marketStatusValues) {
            if (item.exchangeVal === exchange &&
                item.exchangeTypeVal === ExchangeType &&
                item.marketstatus === "Closed") {
                return "Y"; // Market is closed for the given exchange and type.
            }
        }
        return "N"; // Market is open for the given exchange and type.
    }
    async postOrderRequest(orderBody) {
        const response = await fetch(`${APP_BASE_API_URL}tradeapi/OrderRequest-V3`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${this.jwtToken}`,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
            body: JSON.stringify(orderBody),
        });
        return response.json();
    }
    getOrderBody(preOrder, exchange, ExchangeType, SCode, Market, IsStop, IOC, Product) {
        return {
            head: {
                appName: "5PTRADE",
                appVer: "1.0",
                appSource: this.appSource,
                key: APP_HEAD_KEY,
                osName: "Web",
                requestCode: "5POrdReqV3",
            },
            body: {
                OrderRequesterCode: this.clientCode,
                Exchange: exchange,
                ExchangeType: ExchangeType,
                ClientCode: this.clientCode,
                ScripCode: SCode,
                OrderType: preOrder.side === -1 ? "SELL" : "BUY",
                AtMarket: Market,
                Price: preOrder.limitPrice || 0,
                Qty: preOrder.qty,
                ExchOrderID: "0",
                OrderFor: "P",
                OrderID: `${this._idsCounter++}`,
                TradedQty: 0,
                OrderDateTime: 0,
                RemoteOrderID: "0",
                DisQty: 0,
                IsStopLossOrder: IsStop,
                StopLossPrice: preOrder.stopPrice || 0,
                IsVTD: false,
                IOCOrder: IOC === "IOC",
                IsIntraday: Product === "Intraday",
                PublicIP: "",
                AHPlaced: this.getMarketStatus(exchange, ExchangeType),
                ValidTillDate: 0,
                iOrderValidity: 0,
                AppSource: this.appSource,
                BrokerOrderId: "",
            },
        };
    }
    async openCustomAlert(message) {
        return new Promise((resolve) => {
            const dialog = document.getElementById("custom-alert-dialog-shortcode");
            const alertMessage = document.getElementById("custom-alert-message-shortcode");
            const okButton = document.getElementById("custom-alert-ok-shortcode");
            const noButton = document.getElementById("custom-alert-no-shortcode");
            if (dialog && alertMessage && okButton && noButton) {
                alertMessage.textContent = message;
                okButton.onclick = () => {
                    dialog.close();
                    resolve(true);
                };
                noButton.onclick = () => {
                    dialog.close();
                    resolve(false);
                };
                dialog.showModal();
            }
            else {
                console.error("Elements not found. Make sure your HTML contains the required elements.");
                resolve(false);
            }
        });
    }
    async handleOrderRequest(preOrder, exchange, ExchangeType, SCode, Market, IsStop, IOC, Product, Short_FNO_Data) {
        var _a;
        await this.fetchMarketStatus(); // Fetch market status before processing the order
        const orderBody = this.getOrderBody(preOrder, exchange, ExchangeType, SCode, Market, IsStop, IOC, Product);
        let res;
        if (!Short_FNO_Data.ShortCode) {
            res = await this.postOrderRequest(orderBody);
        }
        else if (ExchangeType === "C" && preOrder.side === -1 && ((_a = preOrder === null || preOrder === void 0 ? void 0 : preOrder.customFields) === null || _a === void 0 ? void 0 : _a["2410"]) === "Delivery") {
            res = await this.postOrderRequest(orderBody);
        }
        else {
            const userResponse = await this.openCustomAlert(Short_FNO_Data.ShortCode);
            if (userResponse) {
                res = await this.postOrderRequest(orderBody);
            }
            else {
                res = { body: { Message: "" } };
            }
        }
        return res;
    }
}
class EDISHandler {
    constructor(preOrder, exchange, exchangeType, scripCode) {
        this.preOrder = preOrder;
        this.exchange = exchange;
        this.exchangeType = exchangeType;
        this.scripCode = scripCode;
        this.clientID = "NTA3MzEzNTI=";
        this.subscriptionKey = "a4af51382266497bb5464d95fbb2017b";
        this.appName = "5PTRADE";
        this.appSource = "9";
        this.appVer = "1.0";
        this.osName = "Web";
        this.key = "8eeee59bdab88bace6189d001f96487e";
    }
    async getClient() {
        var _a;
        try {
            const res = await fetch("https://gateway.5paisa.com/tradeapi/V1/ClientInfo", {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${(_a = localStorage
                        .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
                    clientid: this.clientID,
                    "content-type": "application/json",
                    "ocp-apim-subscription-key": this.subscriptionKey,
                },
                body: JSON.stringify({
                    body: {
                        ClientCode: localStorage.getItem("ClientCode"),
                    },
                }),
            });
            if (!res.ok) {
                throw new Error("Request failed with status " + res.status);
            }
            const data = await res.json();
            return data;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async holdingsData() {
        var _a;
        try {
            const response = await fetch("https://gateway.5paisa.com/tradeapi/GetScripWiseDPHolding", {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${(_a = localStorage
                        .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
                    clientid: this.clientID,
                    "content-type": "application/json",
                    "ocp-apim-subscription-key": this.subscriptionKey,
                },
                body: JSON.stringify({
                    head: {
                        appName: this.appName,
                        appSource: this.appSource,
                        appVer: this.appVer,
                        osName: this.osName,
                        key: this.key,
                        requestCode: "5PGetScripWiseDPHolding",
                    },
                    body: {
                        ClientCode: localStorage.getItem("ClientCode"),
                        Quantity: this.preOrder.qty,
                        Exch: this.exchange,
                        ExchType: this.exchangeType,
                        ScripCode: this.scripCode,
                        IPaddress: "",
                        SourceApp: this.appSource,
                    },
                }),
            });
            if (!response.ok) {
                throw new Error("Request failed with status " + response.status);
            }
            const data = await response.json();
            return data;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    redirectToCDSL(postUrl, dpId, requestId, transDtls, pledgeFlag) {
        try {
            const form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", postUrl);
            const hiddenFields = [
                { name: "DPId", value: dpId },
                { name: "ReqId", value: requestId },
                { name: "Version", value: "1.0" },
                { name: pledgeFlag ? "pledgeDtls" : "TransDtls", value: transDtls },
            ];
            hiddenFields.forEach(field => {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", field.name);
                hiddenField.setAttribute("value", field.value);
                form.appendChild(hiddenField);
            });
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }
        catch (e) {
            console.error(e);
        }
    }
    async handleEDIS() {
        var _a;
        const dialog = document.querySelector("#chart-popup");
        if (dialog) {
            const cdslbtn = dialog.querySelector("#primary-btn");
            const closePopbtn = dialog.querySelector("#close-popup");
            const authDialog = dialog.querySelector("#whyauth");
            const dpHoldingRes = await this.holdingsData();
            const res = await this.getClient();
            if (this.preOrder.side === -1) {
                dialog.showModal();
                const clientData = await this.getClient();
                const accNumber = document.querySelector("#accNumber");
                if (accNumber) {
                    accNumber.innerText = clientData.body.clientInfoResult.CLDP[0].AccountNo;
                    (_a = document.getElementById("copy")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", () => {
                        copyTextToClipboard(accNumber.innerText);
                    });
                }
            }
            const cdslRedirect = () => {
                localStorage.setItem(localStorage.getItem("ClientCode") + "CDSL" || "{}", window.location.search);
                localStorage.setItem(localStorage.getItem("ClientCode") + "-" + "order" || "{}", JSON.stringify(this.preOrder));
                localStorage.setItem("CDSL", "true");
                const failure_popup = document.querySelector("#failed-edis-snackbar");
                if (dpHoldingRes.body.PostURL) {
                    this.redirectToCDSL(dpHoldingRes.body.PostURL, dpHoldingRes.body.DPId, dpHoldingRes.body.ReqId, dpHoldingRes.body.TransDtls, false);
                }
                else if (failure_popup) {
                    failure_popup.showModal();
                    const okb = failure_popup.querySelector("#okb");
                    const cross = failure_popup.querySelector("#cross");
                    okb === null || okb === void 0 ? void 0 : okb.addEventListener("click", () => failure_popup.close());
                    cross === null || cross === void 0 ? void 0 : cross.addEventListener("click", () => failure_popup.close());
                }
            };
            const authorizationpopup = () => {
                const bottomContent = document.querySelector("#bottom-content");
                const authinfocontent = document.querySelector("#info-bottom-content");
                const downarrow = document.querySelector("#down-arrow");
                if (bottomContent && authinfocontent && downarrow) {
                    const isCollapsed = bottomContent.style.display === "block";
                    bottomContent.style.display = isCollapsed ? "none" : "block";
                    authinfocontent.style.display = isCollapsed ? "block" : "none";
                    downarrow.style.display = isCollapsed ? "block" : "none";
                }
            };
            const generatePin = async () => {
                var _a;
                const successPopup = document.querySelector("#successPopup");
                if (successPopup) {
                    try {
                        await fetch("https://gateway.5paisa.com/tradeapi/GenerateBOPin", {
                            method: "POST",
                            credentials: "include",
                            headers: {
                                Authorization: `Bearer ${(_a = localStorage.getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")}`,
                                clientid: this.clientID,
                                "content-type": "application/json",
                                "ocp-apim-subscription-key": this.subscriptionKey,
                            },
                            body: JSON.stringify({
                                head: {
                                    appName: this.appName,
                                    appSource: this.appSource,
                                    appVer: this.appVer,
                                    osName: this.osName,
                                    key: this.key,
                                    requestCode: "5PGenerateBOPin",
                                },
                                body: {
                                    ClientCode: localStorage.getItem("ClientCode"),
                                    ReqFlag: "G",
                                },
                            }),
                        });
                        successPopup.showModal();
                        const okbtn = successPopup.querySelector("#ok-btn");
                        okbtn === null || okbtn === void 0 ? void 0 : okbtn.addEventListener("click", () => successPopup.close());
                    }
                    catch (error) {
                        console.error("PIN generation failed:", error);
                    }
                }
            };
            const generatePinButton = document.getElementById("border-btn");
            let isGeneratePinRunning = false;
            generatePinButton === null || generatePinButton === void 0 ? void 0 : generatePinButton.addEventListener("click", () => {
                if (!isGeneratePinRunning) {
                    isGeneratePinRunning = true;
                    generatePin().finally(() => {
                        isGeneratePinRunning = false;
                    });
                }
            });
            cdslbtn === null || cdslbtn === void 0 ? void 0 : cdslbtn.addEventListener("click", cdslRedirect, { once: false });
            closePopbtn === null || closePopbtn === void 0 ? void 0 : closePopbtn.addEventListener("click", () => dialog.close(), { once: true });
            authDialog === null || authDialog === void 0 ? void 0 : authDialog.addEventListener("click", authorizationpopup, { once: false });
        }
    }
}
export class BrokerSample {
    constructor(host, quotesProvider) {
        this._accountSummaryData = {
            balance: 0,
            lbalance: 0,
            funds_added: 0,
            funds_withdrawn: 0,
            coll: 0,
            muti: 0,
        };
        // private readonly _equityValue: IWatchedValue<number>;
        this._positionById = {};
        this._positions = [];
        this._orderById = {};
        this._executions = [];
        this._idsCounter = 1;
        this.websoc = setInterval(() => {
            if (data_to_return !== undefined || data_to_return !== "undefined") {
                this._updatePosition(data_to_return);
            }
        }, 100);
        this.getTimeAfter5Seconds = () => {
            currentTime = this.getTimeConstraints();
            // return { TimeOrder: TimeOrder, currentTime: currentTime };
            return TimeOrder;
        };
        this.handleVisibilityChange = (isConnected, isVisible) => {
            if (isConnected && isVisible) {
                // console.log("Going into startPolling function");
                this.startPolling();
            }
            else {
                // console.log("Going into stopPolling function");
                this.stopPolling();
            }
        };
        this._quotesProvider = quotesProvider;
        this._host = host;
        this._handleMessageEvent = host;
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
        const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
        if (sellBuyButtonsVisibility !== null) {
            sellBuyButtonsVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this._buttonDropdownItems());
            });
        }
        const domPanelVisibility = this._host.domPanelVisibility();
        if (domPanelVisibility) {
            domPanelVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this._buttonDropdownItems());
            });
        }
        const orderPanelVisibility = this._host.orderPanelVisibility();
        if (orderPanelVisibility) {
            orderPanelVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this._buttonDropdownItems());
            });
        }
        this.startPolling();
        this._amChangeDelegate = this._host.factory.createDelegate();
        this._amChangeHoldingsDelegate = this._host.factory.createDelegate();
        this._amChangeClosedDelegate = this._host.factory.createDelegate();
        this._balanceValue = this._host.factory.createWatchedValue(this._accountSummaryData.balance);
        this._bookedplValue = this._host.factory.createWatchedValue(this._accountSummaryData.balance);
        this._unbookedplValue = this._host.factory.createWatchedValue(this._accountSummaryData.balance);
        this._amChangeDelegate.subscribe(null, (values) => {
            this._balanceValue.setValue(values.balance);
            let sum_posbookedPL = posbookedPL.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            let sum_posunbookedPL = posunbookedPL.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            this._bookedplValue.setValue(sum_posbookedPL);
            this._unbookedplValue.setValue(sum_posunbookedPL);
        });
        this._amChangeHoldingsDelegate.subscribe(null, (values) => {
            // this._balanceValue.setValue(values.balance);
            // this._equityValue.setValue(values.equity);
            // this._host.equityUpdate(this._accountManagerData.equity);
        });
        this._amChangeClosedDelegate.subscribe(null, (values) => {
            // this._balanceValue.setValue(values.balance);
            // this._equityValue.setValue(values.equity);
            // this._host.equityUpdate(this._accountManagerData.equity);
        });
    }
    handleMessageEvent(event, host) {
        if ((event.origin === "https://fno.5paisa.com" || event.origin === "https://fno-pre.5paisa.com") &&
            event.data.actionType === "buysellform") {
            console.log("event.data", event.data);
            const receivedData = event.data;
            let exChange = receivedData.data.exchange;
            const scripCode = receivedData.data.scripCode;
            const myData = {
                Exch: "N",
                ExchType: "D",
                Token: receivedData.data.scripCode,
                LastRate: receivedData.data.lastTradePrice,
                LastQty: 0,
                TotalQty: 0,
                High: receivedData.data.lastTradePrice,
                PClose: receivedData.data.lastTradePrice,
                OpenRate: receivedData.data.lastTradePrice,
                OffRate: 0,
                Low: receivedData.data.lastTradePrice,
                TickDt: "2024-05-08T11:01:33+05:30",
                BidRate: 0,
            };
            sessionStorage.setItem(`${scripCode}-${receivedData.data.exchangeType}`, JSON.stringify(myData));
            exChange = exChange === "M" ? exChange + "CX" : exChange + "SE";
            const symbolWithoutApostrophe = receivedData.data.name.includes("'")
                ? receivedData.data.name.replace(/'/g, " ")
                : receivedData.data.name;
            const tickerSymbol = exChange +
                ":" +
                symbolWithoutApostrophe +
                "/" +
                Number(receivedData.data.scripCode) +
                "-" +
                receivedData.data.exchangeType;
            const order = {
                symbol: tickerSymbol,
                side: receivedData.data.isBuy === true ? 1 : -1,
                type: 1,
                qty: receivedData.data.lotSize,
                customFields: {
                    id: "Delivery",
                },
            };
            (host === null || host === void 0 ? void 0 : host.showOrderDialog) ? host.showOrderDialog(order) : () => { };
        }
    }
    connectionStatus() {
        return 1 /* ConnectionStatus.Connected */;
    }
    chartContextMenuActions(context, options) {
        return this._host.defaultContextMenuActions(context);
    }
    isTradable(symbol) {
        let scriptCode = symbol.includes(":")
            ? Number(symbol.split(":")[1].split("/")[1].split("-")[0])
            : symbol.split("/")[1].split("-")[0];
        let indice_flag = true;
        if (sessionStorage.getItem("IsIndice" + scriptCode) === "Y") {
            indice_flag = false;
        }
        return Promise.resolve(indice_flag);
        // return Promise.resolve(true);
    }
    async getOrderDialogOptions(symbol) {
        const possibleItems = {
            Delivery: { text: "Delivery", value: "Delivery" },
            Intraday: { text: "Intraday", value: "Intraday" },
        };
        captureEvent("Trade Initiate", {
            ClientCode: localStorage.getItem("ClientCode"),
            Action: "Buy/Sell",
            Initiation_Section: "Order Panel",
            Product: localStorage.getItem("productVal"),
        });
        const preferredOption = def_close_position === true
            ? localStorage.getItem("position_product") === "Delivery"
                ? "Delivery"
                : "Intraday"
            : localStorage.getItem("productVal") === "Delivery"
                ? "Delivery"
                : "Intraday";
        def_close_position = false;
        const itemsSet = new Set();
        itemsSet.add(possibleItems[preferredOption]);
        //itemsSet.add(Object.values(possibleItems));
        Object.values(possibleItems).forEach((i) => itemsSet.add(i));
        const items = Array.from(itemsSet);
        return Promise.resolve({
            customFields: [
                {
                    inputType: "ComboBox",
                    id: "2410",
                    title: "Product",
                    items: items,
                },
            ],
        });
        // return {
        //   customFields: [
        //     {
        //       id: "2410",
        //       inputType: "ComboBox",
        //       title: "Product",
        //       items: [
        //         {
        //           text: "Intraday", //`${localStorage.getItem("productVal")}`,
        //           value: "Intraday",
        //         }
        //       ],
        //     },
        //   ],
        // };
    }
    async previewOrder(order) {
        return new Promise(async (resolvePreview) => {
            resolvePreview({
                confirmId: "",
                sections: [
                    {
                        header: "",
                        rows: [
                            {
                                title: "",
                                value: "",
                            },
                        ],
                    },
                ],
            });
        });
    }
    async fetchHoldingsData() {
        var _a;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Ocp-apim-subscription-key", APP_OCP_APIM_KEY);
        const response = await fetch(`${APP_BASE_API_URL}tradeapi/HoldingV7`, {
            method: "POST",
            credentials: "include",
            headers: myHeaders,
            body: JSON.stringify({
                head: {
                    appName: "5PTRADE",
                    appVer: "1.0",
                    key: APP_HEAD_KEY,
                    osName: "WEB",
                    requestCode: "5PHoldingV7",
                },
                body: {
                    ClientCode: `${localStorage.getItem("ClientCode")}`,
                },
            }),
            redirect: "follow",
        });
        const data = await response.json();
        data_hol = (_a = data === null || data === void 0 ? void 0 : data.body) === null || _a === void 0 ? void 0 : _a.Data.map((item, index) => {
            return {
                id: index + 1,
                stockname: item.Symbol,
                quantity_vol: item.TotalQty,
                average_price: item.AvgRate,
                last_traded_price: item.CurrentPrice,
                investment: item.TotalQty * item.AvgRate,
                current_value: item.TotalQty * item.CurrentPrice,
                returns: item.TotalQty * item.CurrentPrice - item.TotalQty * item.AvgRate,
                returns_percent: ((item.TotalQty * item.CurrentPrice - item.TotalQty * item.AvgRate) /
                    (item.TotalQty * item.AvgRate)) *
                    100,
            };
        });
    }
    async fetchAccountSummaryData() {
        var _a;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        ///dev
        // myHeaders.append("ocp-apim-subscription-key", APP_OCP_APIM_KEY);
        // try {
        //   const response = await fetch(`${APP_BASE_API_URL}tradeapi/MarginV10`, {
        //     method: "POST",
        //     credentials: "include",
        //     headers: myHeaders,
        //     body: JSON.stringify({
        //       head: {
        //         appName: "5PTRADE",
        //         appSource: "9",
        //         appVer: "1.0",
        //         osName: "Web",
        //         key: APP_HEAD_KEY,
        //         requestCode: "5PMarginV10",
        //       },
        //       body: {
        //         ClientCode: `${localStorage.getItem("ClientCode")}`,
        //       },
        //     }),
        //     redirect: "follow",
        //   });
        //dev
        ///
        //prod
        myHeaders.append("ocp-apim-subscription-key", "df55f1c3f19646bb9cf803d1dafe316b");
        myHeaders.append("UserID", "ZyT47UW2g56"); //
        myHeaders.append("Password", "H98qlU4Sn2");
        try {
            const response = await fetch(`${APP_BASE_API_URL}Tradestation/V11/Margin`, {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: JSON.stringify({
                    head: {
                        appName: "5PTRADE",
                        appSource: "9",
                        appVer: "1.0",
                        osName: "Web",
                        key: APP_HEAD_KEY,
                        requestCode: "5PMarginV11",
                    },
                    body: {
                        ClientCode: `${localStorage.getItem("ClientCode")}`,
                    },
                }),
                redirect: "follow",
            });
            //prod end
            const data = await response.json();
            const tempdata = (_a = data === null || data === void 0 ? void 0 : data.body) === null || _a === void 0 ? void 0 : _a.EquityMargin[0];
            this._accountSummaryData = {
                balance: tempdata.AvailableAmount,
                lbalance: tempdata.EQLedgerBalance,
                funds_added: tempdata.Receipts,
                funds_withdrawn: tempdata.Payments,
                coll: tempdata.AdjCollateralValue,
                muti: tempdata.CashMargin + tempdata.PendingOrders + tempdata.TodayLoss,
            };
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
        //console.log(this._accountManagerData);
    }
    async placeOrder(preOrder) {
        var _a, _b, _c;
        let Short_FNO_Data;
        let Market = false;
        let IsStop = false;
        let Product = (_a = preOrder === null || preOrder === void 0 ? void 0 : preOrder.customFields) === null || _a === void 0 ? void 0 : _a["2410"];
        if (product_flag && preOrder.customFields) {
            Product = preOrder.customFields.id;
        }
        if ((preOrder === null || preOrder === void 0 ? void 0 : preOrder.type) !== undefined) {
            switch (preOrder.type) {
                case 2:
                    Market = true;
                    break;
                case 3:
                    Market = true;
                    IsStop = true;
                    break;
                case 4:
                    IsStop = true;
                    break;
            }
        }
        const IOC = (_b = preOrder === null || preOrder === void 0 ? void 0 : preOrder.duration) === null || _b === void 0 ? void 0 : _b["type"];
        const ScripCode = preOrder.symbol.split("/");
        const exchangeForCC = ScripCode[0].includes(":") ? ScripCode[0].split(":")[0] : ScripCode[0];
        let SCode = ScripCode[1];
        let ExchangeType = "";
        if (SCode.includes("-")) {
            [SCode, ExchangeType] = SCode.split("-");
        }
        const exchangeMap = {
            "NSE": "N",
            "N": "N",
            "BSE": "B",
            "B": "B",
            "MCX": "M",
            "M": "M"
        };
        const exchange = exchangeMap[exchangeForCC] || "N";
        //call in place order
        if (exchange && ExchangeType && SCode) {
            try {
                const scripDetailsService = ScripDetailsService.getInstance();
                Short_FNO_Data = await scripDetailsService.ScripDetailsFoFOOrder(exchange, ExchangeType, SCode);
            }
            catch (error) {
                console.error(error);
            }
        }
        const jwtToken = ((_c = localStorage.getItem("JWTToken")) === null || _c === void 0 ? void 0 : _c.replace("JWTToken", "")) || "";
        const clientCode = localStorage.getItem("ClientCode") || "";
        const appSource = "09"; // We can adjust this value as needed
        const orderHandler = new OrderHandler(jwtToken, clientCode, appSource);
        const res = await orderHandler.handleOrderRequest(preOrder, exchange, ExchangeType, SCode, Market, IsStop, IOC, Product, Short_FNO_Data);
        if (res.body.Message !== "Success" && res.body.Message !== "")
            this._host.showNotification("Rejection", res.body.Message, 0);
        captureEvent("Trade Successful", {
            ClientCode: localStorage.getItem("ClientCode"),
            Action: preOrder.side == -1 ? "SELL" : "BUY",
            Symbol: sessionStorage.getItem("load_symbols"),
            Initiation_Section: "Place Order Form",
            CT_Product: Product == "Intraday" ? "Intraday" : "Delivery",
            Qty: preOrder.qty,
            Type: preOrder.type == 1
                ? "Limit"
                : preOrder.type == 2
                    ? "Market"
                    : preOrder.type == 3
                        ? "Stop"
                        : "Stop Limit",
            Price: preOrder.limitPrice ? preOrder.limitPrice : 0,
            TriggerPrice: preOrder.stopPrice ? preOrder.stopPrice : 0,
            Validity: IOC == "IOC" ? "IOC" : "Day",
        });
        if (preOrder.side === -1 && res.body.RMSResponseCode === -162) {
            const edisHandler = new EDISHandler(preOrder, exchange, ExchangeType, SCode);
            await edisHandler.handleEDIS();
        }
        const resorder = await this.orders();
        let orders = this._createOrderWithBrackets(preOrder, resorder);
        // if (preOrder.duration) {  /// no use 
        //   // tslint:disable-next-line:no-console
        //   //console.log("Durations are not implemented in this sample.");
        // }
        //this._host.activateBottomWidget();
        if ((preOrder.type === 2 /* OrderType.Market */ || preOrder.type === undefined) &&
            this._getBrackets(preOrder.symbol).length > 0) {
            this._updateOrder(this._createOrder(preOrder, resorder));
            return {};
        }
        orders.forEach((order) => {
            this._updateOrder(order);
        });
        return {};
    }
    async modifyOrder(order) {
        var _a, _b, _c, _d;
        let Product;
        var ExchangeType = "";
        if (order.customFields !== undefined) {
            Product = order === null || order === void 0 ? void 0 : order.customFields["2410"];
        }
        let Market;
        let IsStop;
        let IOC;
        if (order.type !== undefined && order.type == 2) {
            Market = true;
            IsStop = false;
        }
        else if (order.type !== undefined && order.type == 3) {
            IsStop = true;
            Market = true;
        }
        else if (order.type !== undefined && order.type == 4) {
            Market = false;
            IsStop = true;
        }
        else if (order.type !== undefined && order.type == 1) {
            Market = false;
            IsStop = false;
        }
        if (order.duration !== undefined) {
            IOC = order === null || order === void 0 ? void 0 : order.duration["type"];
        }
        var exchange = "";
        let exchangeForCC;
        var ScripCode = order.symbol.split("/");
        exchangeForCC = ScripCode[0].includes(":")
            ? ScripCode[0].split(":")[0]
            : ScripCode[0];
        var SCode;
        if (ScripCode[1].includes("-")) {
            SCode = ScripCode[1].split("-")[0];
            ExchangeType = ScripCode[1].split("-")[1];
        }
        switch (exchangeForCC) {
            case "NSE":
            case "N":
                exchange = "N";
                break;
            case "BSE":
            case "B":
                exchange = "B";
                break;
            case "MCX":
            case "M":
                exchange = "M";
                break;
            default:
                exchange = "N";
                break;
        }
        // market Status API
        let marketResponse = await fetch(`${APP_BASE_API_URL}tradeapi/MarketStatus`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${(_a = localStorage
                    .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
        });
        const res1 = await marketResponse.json();
        let tempdata = (_b = res1.body) === null || _b === void 0 ? void 0 : _b.Data;
        let MarketStatusvalues = tempdata.map((item, index) => {
            return {
                marketstatus: item.MarketStatus,
                exchangeVal: item.Exch,
                exchangeTypeVal: item.ExchType,
            };
        });
        function getMarketStatus(obj) {
            for (let i = 0; i < obj.length; i++) {
                const currentItem = obj[i];
                if (currentItem.exchangeVal === exchange &&
                    currentItem.exchangeTypeVal === ExchangeType &&
                    currentItem.marketstatus === "Closed") {
                    return "Y"; // Found a matching item, no need to continue the loop.
                }
            }
            return "N"; // No matching item found.
        }
        // orderbook API call //
        const resorder = await this.orders();
        let brokerorderid, exchangeorderid;
        resorder.map((item) => {
            if (item.brokerorderid == order.brokerorderid) {
                brokerorderid = item.brokerorderid;
            }
            if (item.exchangeorderid == order.exchangeorderid) {
                exchangeorderid = item.exchangeorderid;
            }
        });
        let currentTimestamp = new Date().getTime();
        // console.log(currentTimestamp);
        let modifymsg = await fetch(`${APP_BASE_API_URL}tradeapi/OrderRequest-V3`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${(_c = localStorage
                    .getItem("JWTToken")) === null || _c === void 0 ? void 0 : _c.replace("JWTToken", "")} `,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
            body: JSON.stringify({
                head: {
                    appName: "5PTRADE",
                    appVer: "1.0",
                    appSource: "9",
                    key: APP_HEAD_KEY,
                    osName: "Web",
                    requestCode: "5POrdReqV3",
                },
                body: {
                    OrderRequesterCode: (_d = localStorage.getItem("ClientCode")) === null || _d === void 0 ? void 0 : _d.trim(),
                    Exchange: exchange,
                    ExchangeType: ExchangeType,
                    ClientCode: localStorage.getItem("ClientCode"),
                    ScripCode: Number(SCode),
                    OrderType: order.side == -1 ? "SELL" : "BUY",
                    AtMarket: Market,
                    Price: order.limitPrice ? order.limitPrice : 0,
                    Qty: order.qty,
                    ExchOrderID: exchangeorderid,
                    OrderFor: "M",
                    OrderID: order.id,
                    TradedQty: 0,
                    OrderDateTime: currentTimestamp,
                    RemoteOrderID: "0",
                    DisQty: 0,
                    IsStopLossOrder: IsStop,
                    StopLossPrice: order.stopPrice ? order.stopPrice : 0,
                    IsVTD: false,
                    IOCOrder: IOC == "IOC" ? true : false,
                    IsIntraday: Product == "Intraday" ? true : false,
                    PublicIP: "",
                    AHPlaced: getMarketStatus(MarketStatusvalues),
                    ValidTillDate: currentTimestamp,
                    iOrderValidity: 0,
                    AppSource: "9",
                    BrokerOrderId: brokerorderid,
                },
            }),
        });
        let orders = await this.orders();
        const res = await modifymsg.json();
        captureEvent("Trade Successful", {
            ClientCode: localStorage.getItem("ClientCode"),
            Action: order.side == -1 ? "SELL" : "BUY",
            Symbol: sessionStorage.getItem("load_symbols"),
            Initiation_Section: "Modify Order",
            CT_Product: Product == "Intraday" ? "Intraday" : "Delivery",
            Qty: order.qty,
            Type: order.type == 1
                ? "Limit"
                : order.type == 2
                    ? "Market"
                    : order.type == 3
                        ? "Stop"
                        : "Stop Limit",
            Price: order.limitPrice ? order.limitPrice : 0,
            Validity: IOC == "IOC" ? "IOC" : "Day",
        });
        if (res.body.Message !== "Success" && res.body.Message !== "") {
            this._host.showNotification("Rejection", res.body.Message, 0);
        }
        else {
            orders.forEach((item) => {
                if (item.exchangeorderid === order.exchangeorderid) {
                    order.durat = order.durat;
                    order.limitPrice = order.limitPrice;
                    order.msg = res.body.Message; //order.msg; //old code
                    order.pendingqty = order.pendingqty;
                    order.product = order.product;
                    order.qty = order.qty;
                    order.status = order.status;
                    order.stopPrice = order.stopPrice;
                    order.time = order.time;
                    order.tradedqty = order.tradedqty;
                    order.type = order.type;
                }
            });
            this._updateOrder(order);
        }
        if (order.parentId !== undefined) {
            return;
        }
        const takeProfitBracket = this._getTakeProfitBracket(order);
        const stopLossBracket = this._getStopLossBracket(order);
        this._updateOrdersBracket({
            parent: order,
            bracket: takeProfitBracket,
            newPrice: order.takeProfit,
            bracketType: 1 /* BracketType.TakeProfit */,
        });
        this._updateOrdersBracket({
            parent: order,
            bracket: stopLossBracket,
            newPrice: order.stopLoss,
            bracketType: 0 /* BracketType.StopLoss */,
        });
    }
    async editPositionBrackets(positionId, modifiedBrackets) {
        var _a, _b;
        const position = this._positionById[positionId];
        const positionBrackets = this._getBrackets(positionId);
        const modifiedPosition = { ...position };
        (_a = modifiedPosition.takeProfit) !== null && _a !== void 0 ? _a : (modifiedPosition.takeProfit = modifiedBrackets.takeProfit);
        (_b = modifiedPosition.stopLoss) !== null && _b !== void 0 ? _b : (modifiedPosition.stopLoss = modifiedBrackets.stopLoss);
        this._updatePosition(modifiedPosition);
        const takeProfitBracket = positionBrackets.find((bracket) => bracket.limitPrice !== undefined);
        const stopLossBracket = positionBrackets.find((bracket) => bracket.stopPrice !== undefined);
        this._updatePositionsBracket({
            parent: modifiedPosition,
            bracket: takeProfitBracket,
            bracketType: 1 /* BracketType.TakeProfit */,
            newPrice: modifiedBrackets.takeProfit,
        });
        this._updatePositionsBracket({
            parent: modifiedPosition,
            bracket: stopLossBracket,
            bracketType: 0 /* BracketType.StopLoss */,
            newPrice: modifiedBrackets.stopLoss,
        });
    }
    async closePosition(positionId) {
        var _a;
        try {
            if (localStorage.getItem("defaultClose") === "Market" ||
                localStorage.getItem("defaultClose") === null) {
                product_flag = true;
                const position = this._positionById[positionId];
                this.placeOrder({
                    symbol: position.symbol,
                    side: position.side === -1 /* Side.Sell */ ? 1 /* Side.Buy */ : -1 /* Side.Sell */,
                    type: 2 /* OrderType.Market */,
                    qty: Math.abs(position.qty),
                    customFields: {
                        id: position.product,
                    },
                }).finally(() => {
                    this._updatePosition(position);
                });
            }
            else {
                def_close_position = true;
                product_flag = true;
                const position = this._positionById[positionId];
                localStorage.setItem("position_product", position.product);
                let order = {
                    symbol: position.symbol,
                    side: position.side === -1 /* Side.Sell */ ? 1 /* Side.Buy */ : -1 /* Side.Sell */,
                    type: 1 /* OrderType.Limit */,
                    qty: Math.abs(position.qty),
                    customFields: {
                        id: position.product,
                    },
                };
                ((_a = this === null || this === void 0 ? void 0 : this._host) === null || _a === void 0 ? void 0 : _a.showOrderDialog)
                    ? this._host.showOrderDialog(order)
                    : () => { };
            }
            product_flag = false;
        }
        catch (err) {
            console.log(err);
        }
    }
    async reversePosition(positionId) {
        var _a;
        try {
            if (localStorage.getItem("defaultClose") === "Market" ||
                localStorage.getItem("defaultClose") === null) {
                product_flag = true;
                const position = this._positionById[positionId];
                this.placeOrder({
                    symbol: position.symbol,
                    side: position.side === -1 /* Side.Sell */ ? 1 /* Side.Buy */ : -1 /* Side.Sell */,
                    type: 2 /* OrderType.Market */,
                    qty: Math.abs(position.qty) * 2,
                    customFields: {
                        id: position.product,
                    },
                }).finally(() => {
                    this._updatePosition(position);
                });
            }
            else {
                def_close_position = true;
                product_flag = true;
                const position = this._positionById[positionId];
                localStorage.setItem("position_product", position.product);
                let order = {
                    symbol: position.symbol,
                    side: position.side === -1 /* Side.Sell */ ? 1 /* Side.Buy */ : -1 /* Side.Sell */,
                    type: 1 /* OrderType.Limit */,
                    qty: Math.abs(position.qty) * 2,
                    customFields: {
                        id: position.product,
                    },
                };
                ((_a = this === null || this === void 0 ? void 0 : this._host) === null || _a === void 0 ? void 0 : _a.showOrderDialog)
                    ? this._host.showOrderDialog(order)
                    : () => { };
            }
            product_flag = false;
        }
        catch (err) {
            console.log(err);
        }
    }
    async orders() {
        return new Promise((resolve, reject) => {
            var _a;
            // pos_time = this.getTimeConstraints();
            fetch(`${APP_BASE_API_URL}tradeapi/V3/OrderBook`, {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${(_a = localStorage
                        .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
                    ClientId: "NTk5NDkwMDQ=",
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
                },
                body: JSON.stringify({
                    head: {
                        appName: "5PTRADE",
                        appVer: "1.0",
                        key: APP_HEAD_KEY,
                        osName: "Web",
                        requestCode: "5POrdBkV3",
                    },
                    body: {
                        ClientCode: localStorage.getItem("ClientCode"),
                    },
                }),
            })
                .then((response) => response.json())
                .then((orderBookData) => {
                let order_book_data = [];
                // dev
                //fetch(`${APP_BASE_API_URL}tradeapi/V2/TradeBook`, {
                fetch(`${APP_BASE_API_URL}tradeapi/TradeBookV2`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
                    },
                    body: JSON.stringify({
                        head: {
                            appName: "5PTRADE",
                            appVer: "09",
                            key: APP_HEAD_KEY,
                            osName: "Web",
                            requestCode: "5PTrdBkV2",
                        },
                        body: {
                            ClientCode: localStorage.getItem("ClientCode"),
                        },
                    }),
                })
                    .then((response) => response.json())
                    .then((tradeBookData) => {
                    var _a;
                    const trades = (_a = tradeBookData === null || tradeBookData === void 0 ? void 0 : tradeBookData.body) === null || _a === void 0 ? void 0 : _a.TradeBookDetail;
                    orderBookData.body.OrderBookDetail.forEach((orderItem, index) => {
                        if (orderItem.DelvIntra === "S" || orderItem.DelvIntra === "C") {
                            return;
                        }
                        let orderstatus;
                        switch (orderItem.OrderStatus.trim()) {
                            case "Cancelled":
                            case "Cancelled           ":
                            case "AH Cancelled        ":
                            case "AH Cancelled":
                                orderstatus = 1;
                                break;
                            case "Rejected By 5P":
                            case "Rejected by Exch":
                            case "Rejected by Exch    ":
                                orderstatus = 5;
                                break;
                            case "Fully Executed":
                                orderstatus = 2;
                                break;
                            case "Pending":
                            case "SL Triggered        ":
                            case "SL Triggered":
                            case "Modified":
                            case "AH Placed":
                            case "AH Placed           ":
                            case "AH Modified":
                            case "AH Modified         ":
                                orderstatus = 6;
                                break;
                            case "Waiting in Q on Exch Link":
                            case "Sent to Exch":
                                orderstatus = 3;
                                break;
                            default:
                                orderstatus = 0;
                        }
                        let ordertype;
                        if (orderItem.AtMarket === "N" && orderItem.WithSL === "N") {
                            ordertype = 1;
                        }
                        else if (orderItem.AtMarket === "Y" &&
                            orderItem.WithSL === "N") {
                            ordertype = 2;
                        }
                        else if (orderItem.AtMarket === "Y" &&
                            orderItem.WithSL === "Y") {
                            ordertype = 3;
                        }
                        else {
                            ordertype = 4;
                        }
                        let exchtype;
                        switch (orderItem.Exch) {
                            case "N":
                                exchtype = "NSE";
                                break;
                            case "B":
                                exchtype = "BSE";
                                break;
                            default:
                                exchtype = "MCX";
                                break;
                        }
                        let ordertime;
                        const match = orderItem.BrokerOrderTime.match(/\d+/);
                        if (match) {
                            const timestamp = parseInt(match[0]);
                            const date = new Date(timestamp);
                            ordertime = date.toLocaleDateString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                            });
                        }
                        let orderval;
                        switch (orderItem.OrderValidity) {
                            case 0:
                                orderval = "DAY";
                                break;
                            case 1:
                                orderval = "VTD";
                                break;
                            default:
                                orderval = "IOC";
                                break;
                        }
                        let TradePrice;
                        if (orderItem.AtMarket === "Y") {
                            const matchingTrade = trades.find((tradeItem) => tradeItem.ExchOrderID === orderItem.ExchOrderID);
                            if (matchingTrade && matchingTrade.ExchOrderID) {
                                const matchingExchOrderID = matchingTrade.ExchOrderID;
                                const matchingTradesCount = trades.filter((trade) => trade.ExchOrderID === matchingExchOrderID);
                                if (matchingTradesCount.length > 1) {
                                    // Calculate weighted price
                                    let totalValue = 0;
                                    matchingTradesCount.forEach((tradesindex) => {
                                        const value = tradesindex.Rate *
                                            tradesindex.Qty *
                                            tradesindex.Multiplier;
                                        totalValue += value;
                                    });
                                    TradePrice = totalValue / matchingTradesCount[0].OrgQty;
                                }
                                else {
                                    TradePrice = matchingTrade.Rate;
                                }
                            }
                            else {
                                TradePrice =
                                    orderItem.Rate == 0
                                        ? undefined
                                        : Number(orderItem.Rate);
                            }
                        }
                        else {
                            TradePrice =
                                orderItem.Rate == 0 ? undefined : Number(orderItem.Rate);
                        }
                        const order = {
                            id: (index + 1).toString(),
                            sym: orderItem.ScripName,
                            symbol: `${exchtype}:${orderItem.ScripName}/${orderItem.ScripCode}-${orderItem.ExchType}`,
                            type: ordertype,
                            side: orderItem.BuySell == "S" ? -1 : 1,
                            qty: Number(orderItem.Qty),
                            status: orderstatus,
                            limitPrice: TradePrice,
                            stopPrice: orderItem.SLTriggerRate == 0
                                ? undefined
                                : Number(orderItem.SLTriggerRate),
                            exchange: exchtype,
                            product: orderItem.DelvIntra == "D" ? "Delivery" : "Intraday",
                            customFields: {
                                "2410": orderItem.DelvIntra == "D" ? "Delivery" : "Intraday",
                            },
                            exchangeorderid: orderItem.ExchOrderID,
                            brokerorderid: orderItem.BrokerOrderId,
                            time: ordertime,
                            msg: orderItem.Reason,
                            tradedqty: orderItem.TradedQty,
                            pendingqty: orderItem.PendingQty,
                            durat: orderval,
                        };
                        order_book_data.push(order);
                    });
                    resolve(order_book_data);
                })
                    .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error.message);
                    reject(error);
                });
            })
                .catch((error) => {
                console.error("There was a problem with the fetch operation:", error.message);
                reject(error);
            });
        });
    }
    positions() {
        return new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "application/json");
            myHeaders.append("ocp-apim-subscription-key", APP_OCP_APIM_KEY);
            pos_time = this.getTimeConstraints();
            fetch(`${APP_BASE_API_URL}tradeapi/NetPositionNetWise`, {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: JSON.stringify({
                    head: {
                        appName: "5PTRADE",
                        appSource: "9",
                        appVer: "1.0",
                        osName: "Web",
                        key: APP_HEAD_KEY,
                        requestCode: APP_HEAD_REQUEST_CODE,
                    },
                    body: {
                        ClientCode: `${localStorage.getItem("ClientCode")}`,
                    },
                }),
                redirect: "follow",
            })
                .then((response) => response.text())
                .then((text) => {
                // Log the response text Manually parse the text to JSON for one client
                return JSON.parse(text); // Manually parse the text to JSON
            })
                .then((data) => {
                var _a;
                let tempdata = (_a = data === null || data === void 0 ? void 0 : data.body) === null || _a === void 0 ? void 0 : _a.NetPositionDetail;
                tempdata = tempdata.filter((item) => item.OrderFor !== "S" && item.OrderFor !== "C");
                const closed_pos = tempdata.filter((item) => Number(item.NetQty) === 0);
                data_close = closed_pos.map((item, index) => {
                    const exch = item.Exch === "N"
                        ? "NSE"
                        : item.Exch === "B"
                            ? "BSE"
                            : item.Exch === "M"
                                ? "MCX"
                                : "U";
                    let BuyavgRateOne = item.BuyAvgRate ? item.BuyAvgRate : 0;
                    let SellavgRateOne = item.SellAvgRate ? item.SellAvgRate : 0;
                    let buyQty = item.BuyQty ? Math.abs(item.BuyQty) : 0;
                    let sellQty = item.SellQty ? Math.abs(item.SellQty) : 0;
                    if (item.CFQty > 0) {
                        const holdingPrice = Math.abs(item.CFQty) * item.AvgCFQty;
                        const buyValue = Math.abs(item.BuyQty) * item.BuyAvgRate;
                        buyQty = Math.abs(item.CFQty) + Math.abs(item.BuyQty);
                        sellQty = Math.abs(item.SellQty);
                        BuyavgRateOne =
                            (buyValue + holdingPrice) /
                                (Math.abs(item.BuyQty) + Math.abs(item.CFQty));
                        SellavgRateOne = item.SellAvgRate;
                    }
                    else if (item.CFQty < 0) {
                        const holdingPrice = Math.abs(item.CFQty) * item.AvgCFQty;
                        const sellValue = Math.abs(item.SellQty) * item.SellAvgRate;
                        buyQty = Math.abs(item.BuyQty);
                        sellQty = Math.abs(item.CFQty) + Math.abs(item.SellQty);
                        SellavgRateOne =
                            (sellValue + holdingPrice) /
                                (Math.abs(item.SellQty) + Math.abs(item.CFQty));
                        BuyavgRateOne = item.BuyAvgRate;
                    }
                    return {
                        id: (index + 1).toString(),
                        // symbol: `${exch}:${
                        //   item.ScripName.includes(" ")
                        //     ? item.ScripName.substring(0, item.ScripName.indexOf(" "))
                        //     : item.ScripName
                        // }/${item.ScripCode}-${item.ExchType}`,
                        symbol: `${exch}:${item.ScripName}/${item.ScripCode}-${item.ExchType}`,
                        possym: item.ScripName,
                        exchange: exch,
                        side: "Closed",
                        bqty: buyQty,
                        bap: BuyavgRateOne,
                        sqty: sellQty,
                        sap: SellavgRateOne,
                        product: item.OrderFor === "I" ? "Intraday" : "Delivery",
                        rpl: item.BookedPL,
                        mul: item.Multiplier,
                    };
                });
                data_to_return = tempdata.map((item, index) => {
                    const exch = item.Exch === "N"
                        ? "NSE"
                        : item.Exch === "B"
                            ? "BSE"
                            : item.Exch === "M"
                                ? "MCX"
                                : "U";
                    let avgRateOne = item.AvgRate ? item.AvgRate : 0;
                    if (item.NetQty > 0) {
                        if (item.CFQty > 0) {
                            const holdingPrice = Math.abs(item.CFQty) * item.AvgCFQty;
                            const buyValue = Math.abs(item.BuyQty) * item.BuyAvgRate;
                            avgRateOne =
                                (buyValue + holdingPrice) /
                                    (Math.abs(item.BuyQty) + Math.abs(item.CFQty));
                        }
                        else {
                            avgRateOne = item.BuyAvgRate;
                        }
                    }
                    else if (item.NetQty < 0) {
                        if (item.CFQty < 0) {
                            const holdingPrice = Math.abs(item.CFQty) * item.AvgCFQty;
                            const sellValue = Math.abs(item.SellQty) * item.SellAvgRate;
                            avgRateOne =
                                (sellValue + holdingPrice) /
                                    (Math.abs(item.SellQty) + Math.abs(item.CFQty));
                        }
                        else {
                            avgRateOne = item.SellAvgRate;
                        }
                    }
                    subscribeToData({
                        Method: OPT_FOR_CHART_SOCKET,
                        Operation: METHOD_FOR_CHART_SOCKET,
                        MarketFeedData: [
                            {
                                Exch: item.Exch,
                                ExchType: item.ExchType,
                                ScripCode: item.ScripCode,
                            },
                        ],
                    }, "BrokerPositions", callBackForWebsocketPositions);
                    return {
                        id: (index + 1).toString(),
                        symbol: `${exch}:${item.ScripName}/${item.ScripCode}-${item.ExchType}`,
                        possym: item.ScripName,
                        exchange: exch,
                        side: Number(item.NetQty) > 0 ? 1 : -1,
                        qty: Number(item.NetQty),
                        product: `${item.OrderFor}` === "I" ? "Intraday" : "Delivery",
                        avgPrice: avgRateOne,
                        upl: Number(item.MTOM),
                        rpl: Number(item.BookedPL),
                        last: Number(item.LTP),
                        mul: item.Multiplier,
                    };
                });
                resolve(data_to_return);
            })
                .catch((error) => {
                console.error("There was a problem with the fetch operation:", error.message);
                reject(error);
            });
        });
    }
    executions(symbol) {
        return Promise.resolve(this._executions.filter((data) => {
            return data.symbol === symbol;
        }));
    }
    async cancelOrder(orderId) {
        var _a, _b, _c, _d;
        let orders = await this.orders();
        let order;
        orders.forEach((item) => {
            if (item.id === orderId) {
                order = item;
            }
        });
        let Product;
        var ExchangeType = "";
        if (order.customFields !== undefined) {
            Product = order === null || order === void 0 ? void 0 : order.customFields["2410"];
        }
        let Market;
        let IsStop;
        let IOC;
        if (order.type !== undefined && order.type == 2) {
            Market = true;
            IsStop = false;
        }
        else if (order.type !== undefined && order.type == 3) {
            IsStop = true;
            Market = true;
        }
        else if (order.type !== undefined && order.type == 4) {
            Market = false;
            IsStop = true;
        }
        else if (order.type !== undefined && order.type == 1) {
            Market = false;
            IsStop = false;
        }
        if (order.duration !== undefined) {
            IOC = order === null || order === void 0 ? void 0 : order.duration["type"];
        }
        var exchange = "";
        let exchangeForCC;
        var ScripCode = order.symbol.split("/");
        exchangeForCC = ScripCode[0].includes(":")
            ? ScripCode[0].split(":")[0]
            : ScripCode[0];
        var SCode;
        if (ScripCode[1].includes("-")) {
            SCode = ScripCode[1].split("-")[0];
            ExchangeType = ScripCode[1].split("-")[1];
        }
        if (exchangeForCC == "BSE") {
            exchange = "B";
        }
        else if (exchangeForCC == "NSE") {
            exchange = "N";
        }
        else if (exchangeForCC == "M") {
            exchange = "M";
        }
        else if (exchangeForCC == "U") {
            exchange = "U";
        }
        if (exchangeForCC == "MCX") {
            exchange = "M";
        }
        // market Status API
        let marketResponse = await fetch(`${APP_BASE_API_URL}tradeapi/MarketStatus`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${(_a = localStorage
                    .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
        });
        const res1 = await marketResponse.json();
        let tempdata = (_b = res1.body) === null || _b === void 0 ? void 0 : _b.Data;
        let MarketStatusvalues = tempdata.map((item, index) => {
            return {
                marketstatus: item.MarketStatus,
                exchangeVal: item.Exch,
                exchangeTypeVal: item.ExchType,
            };
        });
        function getMarketStatus(obj) {
            for (let i = 0; i < obj.length; i++) {
                const currentItem = obj[i];
                if (currentItem.exchangeVal === exchange &&
                    currentItem.exchangeTypeVal === ExchangeType &&
                    currentItem.marketstatus === "Closed") {
                    return "Y"; // Found a matching item, no need to continue the loop.
                }
            }
            return "N"; // No matching item found.
        }
        let currentTimestamp = new Date().getTime();
        let cdata = await fetch(`${APP_BASE_API_URL}tradeapi/OrderRequest-V3`, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${(_c = localStorage
                    .getItem("JWTToken")) === null || _c === void 0 ? void 0 : _c.replace("JWTToken", "")} `,
                ClientId: "NTk5NDkwMDQ=",
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
            },
            body: JSON.stringify({
                head: {
                    appName: "5PTRADE",
                    appVer: "1.0",
                    appSource: "9",
                    key: APP_HEAD_KEY,
                    osName: "Web",
                    requestCode: "5POrdReqV3",
                },
                body: {
                    OrderRequesterCode: (_d = localStorage.getItem("ClientCode")) === null || _d === void 0 ? void 0 : _d.trim(),
                    Exchange: exchange,
                    ExchangeType: ExchangeType,
                    ClientCode: localStorage.getItem("ClientCode"),
                    ScripCode: SCode,
                    OrderType: order.side == -1 ? "SELL" : "BUY",
                    AtMarket: Market,
                    Price: order.limitPrice ? order.limitPrice : 0,
                    Qty: order.qty,
                    ExchOrderID: order.exchangeorderid,
                    OrderFor: "C",
                    OrderID: order.id,
                    TradedQty: 0,
                    OrderDateTime: currentTimestamp,
                    RemoteOrderID: "0",
                    DisQty: 0,
                    IsStopLossOrder: IsStop,
                    StopLossPrice: order.stopPrice ? order.stopPrice : 0,
                    IsVTD: false,
                    IOCOrder: IOC == "IOC" ? true : false,
                    IsIntraday: Product == "Intraday" ? true : false,
                    PublicIP: "",
                    AHPlaced: getMarketStatus(MarketStatusvalues),
                    ValidTillDate: currentTimestamp,
                    iOrderValidity: 0,
                    AppSource: "9",
                    BrokerOrderId: orderId,
                },
            }),
        });
        const cjd = await cdata.json();
        captureEvent("Trade Successful", {
            ClientCode: localStorage.getItem("ClientCode"),
            Action: order.side == -1 ? "SELL" : "BUY",
            Symbol: sessionStorage.getItem("load_symbols"),
            Initiation_Section: "Cancel Order",
            CT_Product: Product == "Intraday" ? "Intraday" : "Delivery",
            Qty: order.qty,
            Type: order.type == 1
                ? "Limit"
                : order.type == 2
                    ? "Market"
                    : order.type == 3
                        ? "Stop"
                        : "Stop Limit",
            Price: order.limitPrice ? order.limitPrice : 0,
            Validity: IOC == "IOC" ? "IOC" : "Day",
        });
        if (cjd.body.Message !== "Success" && cjd.body.Message !== "")
            this._host.showNotification("Rejection", cjd.body.Message, 0);
        const handler = () => {
            order.status = 1 /* OrderStatus.Canceled */;
            this._updateOrder(order);
            this._getBrackets(order.id).forEach((bracket) => this.cancelOrder(bracket.id));
            return Promise.resolve();
        };
        return handler();
    }
    cancelOrders(symbol, side, ordersIds) {
        const closeHandler = () => {
            return Promise.all(ordersIds.map((orderId) => {
                return this.cancelOrder(orderId);
            })).then(() => { }); // tslint:disable-line:no-empty
        };
        return closeHandler();
    }
    domUpdate() {
        return {
            asks: [
                { price: 40, volume: 20 },
                { price: 50, volume: 10 },
            ],
            bids: [
                { price: 60, volume: 20 },
                { price: 70, volume: 10 },
            ],
            snapshot: true,
        };
    }
    accountManagerInfo() {
        const summaryProps = [
            {
                text: " Today's Unbooked P/L",
                wValue: this._unbookedplValue,
                formatter: "fixed" /* StandardFormatterName.Fixed */,
                isDefault: true,
            },
            {
                text: "Today's Booked P/L ",
                wValue: this._bookedplValue,
                formatter: "fixed" /* StandardFormatterName.Fixed */,
                isDefault: true,
            },
            {
                text: "Margin",
                wValue: this._balanceValue,
                formatter: "fixed" /* StandardFormatterName.Fixed */,
                isDefault: true,
            },
        ];
        return {
            accountTitle: `${localStorage.getItem("ClientName")}`,
            summary: summaryProps,
            orderColumns: ordersPageColumns,
            positionColumns: positionsPageColumns,
            pages: [
                {
                    id: "accountsummary",
                    title: "Account Summary",
                    tables: [
                        {
                            id: "accountsummary",
                            columns: accountSummaryColumns,
                            getData: () => {
                                return Promise.resolve([this._accountSummaryData]);
                            },
                            initialSorting: {
                                property: "balance",
                                asc: false,
                            },
                            changeDelegate: this._amChangeDelegate,
                        },
                    ],
                },
                {
                    id: "holdings",
                    title: "Holdings",
                    tables: [
                        {
                            id: "holdings",
                            columns: holdingsColumns,
                            getData: () => {
                                // return Promise.resolve([this._HoldingsData]);
                                return Promise.resolve(data_hol);
                            },
                            // initialSorting: {
                            //   property: "holdings",
                            //   asc: false,
                            // },
                            changeDelegate: this._amChangeHoldingsDelegate,
                        },
                    ],
                },
                {
                    id: "closed_positions",
                    title: "Closed Positions",
                    tables: [
                        {
                            id: "closed_positions",
                            columns: closed_positionsPageColumns,
                            getData: () => {
                                // return Promise.resolve([this._HoldingsData]);
                                return Promise.resolve(data_close);
                            },
                            // initialSorting: {
                            //   property: "holdings",
                            //   asc: false,
                            // },
                            changeDelegate: this._amChangeClosedDelegate,
                        },
                    ],
                },
            ],
            contextMenuActions: (contextMenuEvent, activePageActions) => {
                return Promise.resolve(this._bottomContextMenuItems(activePageActions));
            },
        };
    }
    getTimeConstraints() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    async GetOrderUpdates() {
        try {
            const order_response = await fetch(`${APP_BASE_API_URL}tradeapi/GetOrderUpdates`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": APP_OCP_APIM_KEY,
                },
                body: JSON.stringify({
                    head: {
                        appName: "5PTRADE",
                        appVer: "1.0",
                        key: APP_HEAD_KEY,
                        osName: "WEB",
                        requestCode: "5PGOU",
                    },
                    body: {
                        ClientCode: localStorage.getItem("ClientCode"),
                        LastOrderTime: pos_time,
                    },
                }),
            });
            if (!order_response.ok) {
                throw new Error("Request failed with status " + order_response.status);
            }
            const order_data = await order_response.json();
            return order_data; // Resolve the promise with the API response data
        }
        catch (error) {
            return Promise.reject(error); // Reject the promise if there's an error
        }
    }
    async symbolInfo(symbol) {
        var Mklot = 0;
        let exch;
        let FNO_Data;
        let symInfo = symbol.split(":");
        let exchange = symInfo[0];
        let symbolnames = symInfo[1].includes("/")
            ? symInfo[1].split("/")[1]
            : symInfo[1];
        let SCode;
        if (symbolnames.includes("-")) {
            SCode = symbolnames.split("-")[0];
        }
        let ExchType = symbolnames.split("-")[1];
        if (exchange == "BSE" || exchange == "B") {
            exch = "B";
        }
        else if (exchange == "NSE" || exchange == "N") {
            exch = "N";
        }
        else if (exchange == "M" || exchange == "MCX") {
            exch = "M";
        }
        else if (exchange == "U") {
            exch = "U";
        }
        const scripDetailsService = ScripDetailsService.getInstance();
        // First call
        if (exch && ExchType && SCode) {
            try {
                FNO_Data = await scripDetailsService.ScripDetailsFoFOOrder(exch, ExchType, SCode);
            }
            catch (error) {
                console.error(error);
            }
        }
        //const mintick = await this._host.getSymbolMinTick(symbol);
        Mklot =
            typeof FNO_Data !== "undefined"
                ? FNO_Data.MktLot == 0
                    ? 1
                    : FNO_Data.MktLot
                : 1;
        const mintick = typeof FNO_Data !== "undefined"
            ? FNO_Data.TickSize == 0
                ? 0.01
                : FNO_Data.TickSize
            : 0.01;
        const pipSize = mintick; // pip size can differ from minTick
        const accountCurrencyRate = 1; // account currency rate
        const pointValue = 1; // USD value of 1 point of price
        if (exchange == "MCX" || exchange == "M") {
            return {
                qty: {
                    min: 1,
                    max: 1e12,
                    step: 1,
                },
                lotSize: Mklot,
                pipValue: pipSize * pointValue * accountCurrencyRate || 1,
                pipSize: pipSize,
                minTick: mintick,
                description: "",
            };
        }
        else {
            return {
                qty: {
                    min: Mklot,
                    max: 1e12,
                    step: Mklot,
                },
                // lotSize:lotflag==true?Mklot:0,
                pipValue: pipSize * pointValue * accountCurrencyRate || 1,
                pipSize: pipSize,
                minTick: mintick,
                description: "",
            };
        }
    }
    currentAccount() {
        return "1";
    }
    async accountsMetainfo() {
        return [
            {
                id: "1",
                name: `${localStorage.getItem("ClientName")}`,
            },
        ];
    }
    _bottomContextMenuItems(activePageActions) {
        const separator = { separator: true };
        const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
        if (activePageActions.length) {
            activePageActions.push(separator);
        }
        return activePageActions.concat([
            {
                text: "Show Buy/Sell Buttons",
                action: () => {
                    if (sellBuyButtonsVisibility) {
                        sellBuyButtonsVisibility.setValue(!sellBuyButtonsVisibility.value());
                    }
                },
                checkable: true,
                checked: sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value(),
            },
            {
                text: "Trading Settings...",
                action: () => {
                    this._host.showTradingProperties();
                },
            },
        ]);
    }
    _buttonDropdownItems() {
        const defaultActions = this._host.defaultDropdownMenuActions();
        return defaultActions.concat([
            {
                text: "Trading Settings...",
                action: () => {
                    this._host.showTradingProperties();
                },
            },
        ]);
    }
    _createPositionForOrder(order) {
        const positionId = order.symbol;
        let position = this._positionById[positionId];
        const orderSide = order.side;
        const orderQty = order.qty;
        order.avgPrice = order.price;
        if (position) {
            const sign = order.side === position.side ? 1 : -1;
            if (sign > 0) {
                position.avgPrice =
                    (position.qty * position.avgPrice + order.qty * order.price) /
                        (position.qty + order.qty);
            }
            else {
                position.avgPrice = position.avgPrice;
                const amountToClose = Math.min(orderQty, position.qty);
                this._accountSummaryData.balance +=
                    (order.price - position.avgPrice) *
                        amountToClose *
                        (position.side === -1 /* Side.Sell */ ? -1 : 1);
            }
            position.qty = position.qty + order.qty * sign;
            const brackets = this._getBrackets(position.id);
            if (position.qty <= 0) {
                brackets.forEach((bracket) => {
                    this.cancelOrder(bracket.id);
                });
                position.side = changeSide(position.side);
                position.qty *= -1;
            }
            else {
                brackets.forEach((bracket) => {
                    bracket.side = changeSide(position.side);
                    bracket.qty = position.qty;
                    this._updateOrder(bracket);
                });
            }
        }
        else {
            position = {
                ...order,
                id: positionId,
                avgPrice: order.price,
            };
        }
        const execution = {
            id: `${this._idsCounter++}`,
            brokerSymbol: order.brokerSymbol,
            price: order.price,
            qty: orderQty,
            side: orderSide,
            symbol: order.symbol,
            time: Date.now(),
        };
        this._executions.push(execution);
        this._host.executionUpdate(execution);
        this._updatePosition(position);
        this._recalculateAMData();
        this._host.plUpdate(position.symbol, position.profit);
        this._host.positionPartialUpdate(position.id, position);
        this._recalculateAMData();
        return position;
    }
    _updateOrderLast(order) {
        this._host.orderPartialUpdate(order.id, { last: order.last });
    }
    _orders() {
        return Object.values(this._orderById);
    }
    _updateOrder(order) {
        const executionChecks = {
            [-1 /* Side.Sell */]: {
                [2 /* OrderType.Market */]: () => !!order.price,
                [1 /* OrderType.Limit */]: () => order.limitPrice !== undefined && order.last >= order.limitPrice,
                [3 /* OrderType.Stop */]: () => order.stopPrice !== undefined && order.last <= order.stopPrice,
                [4 /* OrderType.StopLimit */]: () => false,
            },
            [1 /* Side.Buy */]: {
                [2 /* OrderType.Market */]: () => !!order.price,
                [1 /* OrderType.Limit */]: () => order.limitPrice !== undefined && order.last <= order.limitPrice,
                [3 /* OrderType.Stop */]: () => order.stopPrice !== undefined && order.last >= order.stopPrice,
                [4 /* OrderType.StopLimit */]: () => false,
            },
        };
        const hasOrderAlready = Boolean(this._orderById[order.id]);
        this._orderById[order.id] = order;
        Object.assign(this._orderById[order.id], order);
        if (!hasOrderAlready) {
            this._subscribeData(order.symbol, order.id, (last) => {
                if (order.last === last) {
                    return;
                }
                order.last = last;
                if (order.price == null) {
                    order.price = order.last;
                }
                if (order.status === 6 /* OrderStatus.Working */ &&
                    executionChecks[order.side][order.type]()) {
                    const positionData = { ...order };
                    order.price = order.last;
                    order.avgPrice = order.last;
                    const position = this._createPositionForOrder(positionData);
                    order.status = 2 /* OrderStatus.Filled */;
                    this._updateOrder(order);
                    this._getBrackets(order.id).forEach((bracket) => {
                        bracket.status = 6 /* OrderStatus.Working */;
                        bracket.parentId = position.id;
                        bracket.parentType = 2 /* ParentType.Position */;
                        this._updateOrder(bracket);
                    });
                }
                this._updateOrderLast(order);
            });
        }
        this._host.orderUpdate(order);
        if (order.parentId !== undefined) {
            const entity = order.parentType === 2 /* ParentType.Position */
                ? this._positionById[order.parentId]
                : this._orderById[order.parentId];
            if (order.limitPrice !== undefined) {
                entity.takeProfit =
                    order.status !== 1 /* OrderStatus.Canceled */ ? order.limitPrice : undefined;
            }
            if (order.stopPrice !== undefined) {
                entity.stopLoss =
                    order.status !== 1 /* OrderStatus.Canceled */ ? order.stopPrice : undefined;
            }
            if (order.parentType === 2 /* ParentType.Position */) {
                return this._updatePosition(entity);
            }
            this._updateOrder(entity);
        }
    }
    _updatePosition(position) {
        if (Array.isArray(position)) {
            position.forEach(async (ele, currentIndex) => {
                const hasPositionAlready = Boolean(this._positionById[ele.id]);
                if (hasPositionAlready && ele.qty === 0) {
                    this._unsubscribeData(ele.id);
                    const index = this._positions.indexOf(ele);
                    if (index !== -1) {
                        this._positions.splice(index, 1);
                    }
                    delete this._positionById[ele.id];
                    let k = ele.symbol.split("/");
                    updateLastRateInPositionElement(ele, k[1]);
                    ele.upl =
                        (ele.last - ele.avgPrice) *
                            (Math.abs(ele.qty) * ele.mul) *
                            (ele.side === -1 /* Side.Sell */ ? -1 : 1);
                    if (ele.qty == 0) {
                        posbookedPL[currentIndex] = ele.rpl;
                        posunbookedPL[currentIndex] = ele.upl;
                    }
                    else {
                        posbookedPL[currentIndex] = ele.rpl;
                        posunbookedPL[currentIndex] = ele.upl;
                    }
                    this._host.positionUpdate(ele);
                    return;
                }
                if (!hasPositionAlready) {
                    this._positions.push(ele);
                    this._subscribeData(ele.symbol, ele.id, (last) => {
                        if (ele.last === last) {
                            return;
                        }
                        let k = ele.symbol.split("/");
                        updateLastRateInPositionElement(ele, k[1]);
                        ele.upl =
                            (ele.last - ele.avgPrice) *
                                (Math.abs(ele.qty) * ele.mul) *
                                (ele.side === -1 /* Side.Sell */ ? -1 : 1);
                        if (ele.qty == 0) {
                            posbookedPL[currentIndex] = ele.rpl;
                            posunbookedPL[currentIndex] = ele.upl;
                        }
                        else {
                            posbookedPL[currentIndex] = ele.rpl;
                            posunbookedPL[currentIndex] = ele.upl;
                        }
                        this._host.plUpdate(ele.id, ele.upl);
                        this._host.positionPartialUpdate(ele.id, ele);
                        this._recalculateAMData();
                    });
                }
                this._positionById[ele.id] = ele;
                let k = ele.symbol.split("/");
                updateLastRateInPositionElement(ele, k[1]);
                ele.upl =
                    (ele.last - ele.avgPrice) *
                        (Math.abs(ele.qty) * ele.mul) *
                        (ele.side === -1 /* Side.Sell */ ? -1 : 1);
                if (Number(ele.qty) === 0) {
                    posbookedPL[currentIndex] = ele.rpl;
                    posunbookedPL[currentIndex] = ele.upl;
                }
                else {
                    posbookedPL[currentIndex] = ele.rpl;
                    posunbookedPL[currentIndex] = ele.upl;
                }
                this._host.positionUpdate(ele);
                this._host.plUpdate(ele.id, ele.upl);
            });
        }
        else {
            return;
        }
    }
    _subscribeData(symbol, id, updateFunction) {
        this._quotesProvider.subscribeQuotes([], [symbol], (symbols) => {
            const deltaData = symbols[0];
            if (deltaData.s !== "ok") {
                return;
            }
            // if (typeof deltaData.v.lp === 'number') {
            // 	updateFunction(deltaData.v.lp);
            // }
        }, getDatafeedSubscriptionId(id));
    }
    _unsubscribeData(id) {
        this._quotesProvider.unsubscribeQuotes(getDatafeedSubscriptionId(id));
    }
    async _recalculateAMData() {
        var _a, _b, _c;
        await this.fetchAccountSummaryData();
        await this.fetchHoldingsData();
        this._amChangeDelegate.fire(this._accountSummaryData);
        if (data_hol !== undefined && data_hol !== "undefined") {
            data_hol.forEach((item) => {
                this._amChangeHoldingsDelegate.fire(item);
            });
        }
        if (data_close !== undefined && data_close !== "undefined") {
            data_close.forEach((item) => {
                this._amChangeClosedDelegate.fire(item);
            });
        }
        try {
            let GetOrder = await this.GetOrderUpdates();
            if (((_a = GetOrder === null || GetOrder === void 0 ? void 0 : GetOrder.body) === null || _a === void 0 ? void 0 : _a.ChangeOnOrderBook) === "Y" ||
                ((_b = GetOrder === null || GetOrder === void 0 ? void 0 : GetOrder.body) === null || _b === void 0 ? void 0 : _b.ChangeOnTrade) === "Y") {
                order_book_data_five_sec = await this.orders();
                order_book_data_five_sec.forEach((ord) => {
                    this._updateOrder(ord);
                });
                pos_book_data_five_sec = await this.positions();
                pos_book_data_five_sec.forEach((pos) => {
                    this._updatePosition(pos);
                });
            }
            if (((_c = GetOrder === null || GetOrder === void 0 ? void 0 : GetOrder.body) === null || _c === void 0 ? void 0 : _c.Status) === 9) {
                window.location.replace("https://Invest.5paisa.com/home/logout");
            }
        }
        catch (error) {
            // Handle errors here
            console.error(error);
        }
    }
    _createOrderWithBrackets(preOrder, last_order_book_data) {
        const orders = [];
        const order = this._createOrder(preOrder, last_order_book_data);
        orders.push(order);
        if (order.takeProfit !== undefined) {
            const takeProfit = this._createTakeProfitBracket(order);
            orders.push(takeProfit);
        }
        if (order.stopLoss !== undefined) {
            const stopLoss = this._createStopLossBracket(order);
            orders.push(stopLoss);
        }
        return orders;
    }
    _getBrackets(parentId) {
        return this._orders().filter((order) => order.parentId === parentId &&
            activeOrderStatuses.includes(order.status));
    }
    _createOrder(preOrder, last_order_book_data) {
        let len = last_order_book_data.length;
        let lastdata = last_order_book_data.splice(-1);
        return {
            id: len.toString(),
            // duration: preOrder.duration, // duration is not used in this sample
            limitPrice: preOrder.limitPrice,
            //profit: 0,
            product: lastdata[0].product,
            msg: lastdata[0].msg,
            exchangeorderid: lastdata[0].exchangeorderid,
            brokerorderid: lastdata[0].brokerorderid,
            qty: preOrder.qty,
            time: lastdata[0].time,
            tradedqty: lastdata[0].tradedqty,
            pendingqty: lastdata[0].pendingqty,
            durat: lastdata[0].durat,
            // duration: preOrder.duration,
            exchange: lastdata[0].exchange,
            side: preOrder.side,
            status: lastdata[0].status,
            stopPrice: preOrder.stopPrice,
            symbol: lastdata[0].symbol,
            sym: lastdata[0].sym,
            type: preOrder.type,
            // takeProfit: preOrder.takeProfit,
            stopLoss: preOrder.stopLoss,
        };
    }
    _createTakeProfitBracket(entity) {
        return {
            symbol: entity.symbol,
            qty: entity.qty,
            id: `${this._idsCounter++}`,
            parentId: entity.id,
            parentType: 1 /* ParentType.Order */,
            limitPrice: entity.takeProfit,
            side: changeSide(entity.side),
            status: 3 /* OrderStatus.Inactive */,
            type: 1 /* OrderType.Limit */,
        };
    }
    _createStopLossBracket(entity) {
        return {
            symbol: entity.symbol,
            qty: entity.qty,
            id: `${this._idsCounter++}`,
            parentId: entity.id,
            parentType: 1 /* ParentType.Order */,
            stopPrice: entity.stopLoss,
            price: entity.stopPrice,
            side: changeSide(entity.side),
            status: 3 /* OrderStatus.Inactive */,
            type: 3 /* OrderType.Stop */,
        };
    }
    _getTakeProfitBracket(entity) {
        return this._getBrackets(entity.id).find((bracket) => bracket.limitPrice !== undefined);
    }
    _getStopLossBracket(entity) {
        return this._getBrackets(entity.id).find((bracket) => bracket.stopPrice !== undefined);
    }
    _updateOrdersBracket(params) {
        const { parent, bracket, bracketType, newPrice } = params;
        const shouldCancelBracket = bracket !== undefined && newPrice === undefined;
        if (shouldCancelBracket) {
            this._setCanceledStatusAndUpdate(bracket);
            return;
        }
        if (newPrice === undefined) {
            return;
        }
        const shouldCreateNewBracket = bracket === undefined;
        if (bracketType === 1 /* BracketType.TakeProfit */) {
            const takeProfitBracket = shouldCreateNewBracket
                ? this._createTakeProfitBracket(parent)
                : { ...bracket, limitPrice: newPrice };
            this._updateOrder(takeProfitBracket);
            return;
        }
        if (bracketType === 0 /* BracketType.StopLoss */) {
            const stopLossBracket = shouldCreateNewBracket
                ? this._createStopLossBracket(parent)
                : { ...bracket, stopPrice: newPrice };
            this._updateOrder(stopLossBracket);
            return;
        }
    }
    focussedPolling() {
        const setIsWindowVisible = (isVisible) => {
            this.isVisible = isVisible;
            this.handleVisibilityChange(this.isConnected, this.isVisible);
        };
        const setIsInternetConnected = (isConnected) => {
            this.isConnected = isConnected;
            this.handleVisibilityChange(this.isConnected, this.isVisible);
        };
        getPageVisibility({ setIsWindowVisible });
        getInternetStatus({ setIsInternetConnected });
    }
    async startPolling() {
        if (intervalId === undefined) {
            const poll = async () => {
                if (intervalId)
                    clearTimeout(intervalId);
                await this._recalculateAMData();
                //if customer comes back to the tab before api call is complete - very rare condition
                // if (intervalId) clearTimeout(intervalId);
                intervalId = window.setTimeout(poll, 3000);
            };
            poll();
            // console.log("Polling started");
        }
    }
    stopPolling() {
        if (intervalId !== undefined) {
            clearTimeout(intervalId);
            intervalId = undefined;
            // console.log("Polling stopped");
        }
    }
    _updatePositionsBracket(params) {
        const { parent, bracket, bracketType, newPrice } = params;
        const shouldCancelBracket = bracket !== undefined && newPrice === undefined;
        if (shouldCancelBracket) {
            this._setCanceledStatusAndUpdate(bracket);
            return;
        }
        if (newPrice === undefined) {
            return;
        }
        const shouldCreateNewBracket = bracket === undefined;
        if (bracketType === 1 /* BracketType.TakeProfit */) {
            if (shouldCreateNewBracket) {
                const takeProfitBracket = this._createTakeProfitBracket(parent);
                takeProfitBracket.status = 6 /* OrderStatus.Working */;
                takeProfitBracket.parentType = 2 /* ParentType.Position */;
                this._updateOrder(takeProfitBracket);
                return;
            }
            bracket.limitPrice = newPrice;
            bracket.takeProfit = newPrice;
            this._updateOrder(bracket);
            return;
        }
        if (bracketType === 0 /* BracketType.StopLoss */) {
            if (shouldCreateNewBracket) {
                const stopLossBracket = this._createStopLossBracket(parent);
                stopLossBracket.status = 6 /* OrderStatus.Working */;
                stopLossBracket.parentType = 2 /* ParentType.Position */;
                this._updateOrder(stopLossBracket);
                return;
            }
            bracket.stopPrice = newPrice;
            bracket.stopLoss = newPrice;
            this._updateOrder(bracket);
            return;
        }
    }
    _setCanceledStatusAndUpdate(order) {
        order.status = 1 /* OrderStatus.Canceled */;
        this._updateOrder(order);
    }
}
const positionMap = new Map();
const updateLastRateInPositionElement = (element, partialKey) => {
    var _a;
    if (element.scripKey == undefined) {
        let keyList = partialKey.split("-");
        const key = element.exchange[0] + keyList[1] + keyList[0];
        element.scripKey = key;
    }
    else {
        // console.log("Key already exists GD");
    }
    element.last = positionMap.has(element.scripKey)
        ? (_a = positionMap.get(element.scripKey)) === null || _a === void 0 ? void 0 : _a.LastRate
        : 0;
};
function changeSide(side) {
    return side === 1 /* Side.Buy */ ? -1 /* Side.Sell */ : 1 /* Side.Buy */;
}
function getDatafeedSubscriptionId(id) {
    return `SampleBroker-${id}`;
}
// public const positionMap: Map<string, ScriptDataType> = new Map();
const callBackForWebsocketPositions = (data) => {
    positionMap.set(data.Exch + data.ExchType + data.Token, data);
    // console.log("Inside position callback");
};
