//@ts-nocheck
export async function handleMessageEvent(event, host) {
    if ((event.origin === "https://fno.5paisa.com" || event.origin === "https://fno-pre.5paisa.com") &&
        event.data.actionType === "buysellform") {
        console.log("event.data", event.data);
        const receivedData = event.data;
        let exChange = receivedData.data.exchange;
        const scripCode = receivedData.data.scripCode;
        const myData = {
            Exch: "N",
            ExchType: "D",
            Token: receivedData.data.scripCode,
            LastRate: receivedData.data.lastTradePrice,
            LastQty: 0,
            TotalQty: 0,
            High: receivedData.data.lastTradePrice,
            PClose: receivedData.data.lastTradePrice,
            OpenRate: receivedData.data.lastTradePrice,
            OffRate: 0,
            Low: receivedData.data.lastTradePrice,
            TickDt: "2024-05-08T11:01:33+05:30",
            BidRate: 0,
        };
        sessionStorage.setItem(`${scripCode}-${receivedData.data.exchangeType}`, JSON.stringify(myData));
        exChange = exChange === "M" ? exChange + "CX" : exChange + "SE";
        const symbolWithoutApostrophe = receivedData.data.name.includes("'")
            ? receivedData.data.name.replace(/'/g, " ")
            : receivedData.data.name;
        const tickerSymbol = exChange +
            ":" +
            symbolWithoutApostrophe +
            "/" +
            Number(receivedData.data.scripCode) +
            "-" +
            receivedData.data.exchangeType;
        const order = {
            symbol: tickerSymbol,
            side: receivedData.data.isBuy === true ? 1 : -1,
            type: 1,
            qty: receivedData.data.lotSize,
            customFields: {
                id: "Delivery",
            },
        };
        (host === null || host === void 0 ? void 0 : host.showOrderDialog) ? host.showOrderDialog(order) : () => { };
    }
}
