import { BrokerSample } from "./broker";
import { getConfig } from "./config/config";
import Datafeed from "./datafeed";
import { AlterUrlParam, captureEvent, changeURLparam, checkingLayoutType, getExch_Exchtype_Scripcode, getPreferenceApi, getSellAuthStatus, getToken, getUrlParameters, setPreferenceApi, get_New_Symbol, observeTabChanges, orderObjectUtilityFunction, objectForOrderForm } from "./helpers";
import { checkLoginTradestation } from "./singlelogin/loginUtil";
import { widget } from "/charting_library_clonned_data/charting_library";
import { handleMessageEvent } from './orderDialogue';
import util from "./util";
await checkLoginTradestation();
const browserName = util.getBrowserName();
let productVal, themeVal, defaultClose, preferences, preferenceData, brokerFac;
let prefChanged = false;
let defSet = false;
let user = localStorage.getItem("ClientCode");
let chartSetting_Tradechart;
let chartSetting_noTradechart;
window.clevertap.onUserLogin.push({
    Site: {
        Name: localStorage.getItem("ClientName"),
        Identity: user,
    },
});
captureEvent("Screen_Open", {
    ClientCode: localStorage.getItem("ClientCode"),
    Initiation_Sreen: "Tradestation",
    Script_Name: sessionStorage.getItem("load_symbols_Tradechart"),
    Action: "Onboarding_Chart_Screen",
});
(() => {
    const urlObject = new URL(window.location.href);
    const searchParams = urlObject.searchParams;
    if (searchParams.has("TrasactionStatus")) {
        return;
    }
    else {
        if (window.location.href.includes("failure") ||
            window.location.href.includes("success")) {
            const newurl = localStorage.getItem(localStorage.getItem("ClientCode") + "CDSL");
            const url = new URL(window.location.href);
            const params = new URLSearchParams(newurl);
            url.search = params.toString();
            const finalUrl = url.toString();
            window.location.href = finalUrl;
        }
    }
})();
try {
    preferenceData = await getPreferenceApi(user);
}
catch (error) {
    console.error("An error occurred while fetching preference data:", error);
}
const reqUrl = new URL(window.location);
let reqExch = "";
if (reqUrl.searchParams.has("exchange") || reqUrl.searchParams.has("exch")) {
    switch (reqUrl.searchParams.get("exchange") ||
        reqUrl.searchParams.get("exch")) {
        case "NSE":
        case "nse":
        case "Nse":
        case "n":
        case "N":
            reqExch = "NSE";
            break;
        case "BSE":
        case "bse":
        case "Bse":
        case "B":
            reqExch = "BSE";
            break;
        case "MCX":
        case "Mcx":
        case "mcx":
        case "M":
        case "m":
            reqExch = "MCX";
            break;
    }
}
let reqSymbol = reqExch +
    ":" +
    reqUrl.searchParams.get("symbol").trim() +
    "/" +
    reqUrl.searchParams.get("scripCode") +
    "-" +
    Datafeed.urlParameter.exchType;
let checkTheme = () => {
    const urlObject = new URL(window.location.href);
    const searchParams = urlObject.searchParams;
    let themeParam = searchParams.get("theme");
    if (themeParam) {
        defSet = true;
        if (themeParam === "light" || themeParam === "Light") {
            localStorage.setItem("tradingview.current_theme.name", "light");
            document.body.classList.remove("dark-theme");
        }
        else if (themeParam === "dark" || themeParam === "Dark") {
            localStorage.setItem("tradingview.current_theme.name", "dark");
            document.body.classList.add("dark-theme");
        }
        themeVal = localStorage.getItem("tradingview.current_theme.name");
    }
    else if (localStorage.getItem("tradingview.current_theme.name") &&
        localStorage.getItem("tradingview.current_theme.name") !== "undefined" &&
        localStorage.getItem("tradingview.current_theme.name") !== undefined) {
        if (localStorage.getItem(`tradingview.current_theme.name`) === "dark" ||
            `tradingview.current_theme.name` === "") {
            document.body.classList.add("dark-theme");
        }
        else {
            document.body.classList.remove("dark-theme");
        }
        themeVal = localStorage.getItem("tradingview.current_theme.name");
    }
    else {
        if (!preferenceData.ERR_MSG) {
            localStorage.setItem("tradingview.current_theme.name", preferenceData.themeVal);
            if (preferenceData.themeVal === "dark" ||
                preferenceData.themeVal === "Dark") {
                document.body.classList.add("dark-theme");
            }
            else {
                document.body.classList.remove("dark-theme");
            }
            themeVal = localStorage.getItem("tradingview.current_theme.name");
        }
        else {
            defSet = true;
            localStorage.setItem("tradingview.current_theme.name", "light");
            themeVal = localStorage.getItem("tradingview.current_theme.name");
        }
    }
};
let checkProduct = () => {
    if (localStorage.getItem("productVal") &&
        localStorage.getItem("productVal") !== "undefined" &&
        localStorage.getItem("productVal") !== undefined) {
        productVal = localStorage.getItem("productVal");
    }
    else {
        if (!preferenceData.ERR_MSG) {
            localStorage.setItem("productVal", preferenceData.productVal);
            productVal = localStorage.getItem("productVal");
        }
        else {
            defSet = true;
            localStorage.setItem("productVal", "Delivery");
            productVal = localStorage.getItem("productVal");
        }
    }
};
let checkClose = () => {
    if (localStorage.getItem("defaultClose") &&
        localStorage.getItem("defaultClose") !== "undefined" &&
        localStorage.getItem("defaultClose") !== undefined) {
        defaultClose = localStorage.getItem("defaultClose");
    }
    else {
        if (!preferenceData.ERR_MSG) {
            localStorage.setItem("defaultClose", preferenceData.defaultClose);
            defaultClose = localStorage.getItem("defaultClose");
        }
        else {
            defSet = true;
            localStorage.setItem("defaultClose", "Market");
            defaultClose = localStorage.getItem("defaultClose");
        }
    }
};
preferences = {
    themeVal,
    productVal,
    defaultClose,
};
let checkPreferences = async () => {
    checkTheme();
    checkProduct();
    checkClose();
    if (defSet === true) {
        setPreferenceApi(preferences, user);
        defSet = false;
    }
};
if (reqUrl.searchParams.get("isTradeChart") == 'true') {
    checkPreferences();
}
else {
    if (reqUrl.searchParams.get("theme") === "light" || reqUrl.searchParams.get("theme") === "day") {
        localStorage.setItem("tradingview.current_theme.name", "light");
        document.body.classList.remove("dark-theme");
    }
    else if (reqUrl.searchParams.get("theme") === "dark" || reqUrl.searchParams.get("theme") === "night") {
        localStorage.setItem("tradingview.current_theme.name", "dark");
        document.body.classList.add("dark-theme");
    }
    themeVal = localStorage.getItem("tradingview.current_theme.name");
}
const prefix = "tradingview.savedwatch";
for (let key in localStorage) {
    if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
    }
}
if (!reqUrl.searchParams.has("ispopOut")) {
    if (sessionStorage.getItem("WatchList_set") != null) {
        sessionStorage.removeItem("WatchList_set");
    }
}
console.log("TVNewChart TS-Version - V24.09.18");
const { APP_BASE_API_URL, APP_OCP_APIM_KEY, APP_HEAD_KEY } = getConfig();
const timeFrameArr = [
    { text: "1D", resolution: "1", description: "1D", title: "1D" },
    { text: "5D", resolution: "15", description: "5D", title: "5D" },
    { text: "1M", resolution: "D", description: "1M ", title: "1M" },
    { text: "2M", resolution: "D", description: "2M ", title: "2M" },
    { text: "3M", resolution: "D", description: "3M ", title: "3M" },
    { text: "1Y", resolution: "D", description: "1Y", title: "1Y" },
    { text: "5Y", resolution: "1W", description: "5Y", title: "5Y" },
    { text: "22y", resolution: "1M", description: "Max", title: "MAX" },
];
let disabledfeatures = [""];
let enabled_features = [
    "charting_library_debug_mode",
    "show_zoom_and_move_buttons_on_touch",
    "save_chart_properties_to_local_storage",
    "volume_force_overlay",
    "study_templates",
    "display_legend_on_all_charts",
    "dom_widget",
];
let data_window = true;
var interval = "1";
var content, c;
var chartContentFlag_Tradechart = false;
var chartContentFlag_noTradechart = false;
var userId = "Priti";
let iframe2;
localStorage.setItem("clientID", user);
let sessionScripCode = reqUrl.searchParams.get("scripCode");
if (reqUrl.searchParams.get("isTradeChart") === "false") {
    const osName = reqUrl.searchParams.get("osName");
    const excludedOS = ["Android", "iOS", "flutter"];
    if (!excludedOS.includes(osName)) {
        console.log("Not  a mobile device", osName);
        disabledfeatures = [
            "add_to_watchlist",
            "open_account_manager",
            "multiple_watchlists",
            "show_trading_notifications_history",
            "trading_account_manager",
            "order_info",
            "watchlist_import_export",
            "watchlist_context_menu",
            "show_object_tree",
            "buy_sell_buttons",
            "header_fullscreen_button",
            "header_symbol_search",
            "header_compare",
            "header_layouttoggle",
            "header_saveload",
            "chart_crosshair_menu",
            "chart_property_page_trading"
        ];
        enabled_features.push("hide_right_toolbar");
        data_window = false;
    }
    else {
        disabledfeatures = [
            "add_to_watchlist",
            "open_account_manager",
            "multiple_watchlists",
            "show_trading_notifications_history",
            "trading_account_manager",
            "order_info",
            "watchlist_import_export",
            "watchlist_context_menu",
            "show_object_tree",
            "buy_sell_buttons",
            "header_fullscreen_button",
            "header_symbol_search",
            "header_compare",
            "header_layouttoggle",
            "header_saveload",
            "chart_crosshair_menu",
            "chart_property_page_trading"
        ];
        enabled_features.push("hide_right_toolbar");
        data_window = false;
    }
}
if (reqUrl.searchParams.has("redirectionTv") &&
    reqUrl.searchParams.get("redirectionTv") == "true") {
    localStorage.removeItem("autoSaveStorage_Tradechart");
    var url = new URL(window.location);
    url.searchParams.delete("redirectionTv");
    window.history.pushState({}, "", url);
}
;
if (reqUrl.searchParams.has("ispopOut")) {
    disabledfeatures = [
        "add_to_watchlist",
        "open_account_manager",
        "multiple_watchlists",
        "show_trading_notifications_history",
        "trading_account_manager",
        "order_info",
        "watchlist_import_export",
        "watchlist_context_menu",
    ];
    data_window = false;
}
if (reqUrl.searchParams.get("isTradeChart") === "false") {
    sessionStorage.setItem("LayoutType_noTradechart", "s");
    sessionStorage.setItem("activeChartChanged_noTradechart", "0");
    sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify([reqSymbol]));
    sessionStorage.setItem("reqSymbols_noTradechart", JSON.stringify([symbolAfterLoad]));
    if (localStorage.getItem("lastChartSaved_noTradechart")) {
        content = JSON.parse(JSON.parse(localStorage.getItem("lastChartSaved_Tradechart")).content);
        var content1 = JSON.parse(localStorage.getItem("lastChartSaved_Tradechart"));
        content = { ...content, extendedData: content1 };
    }
}
else {
    sessionStorage.setItem("LayoutType_Tradechart", "s");
    sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify([reqSymbol]));
    sessionStorage.setItem("activeChartChanged_Tradechart", "0");
}
if (reqUrl.searchParams.get("isTradeChart") === "false") {
    if (Datafeed.urlParameter.searchFlag === "true") {
        const autoSaveKey = "autoSaveStorage_noTradechart" + sessionScripCode;
        const autoSaveKeyreso = "reso" + sessionScripCode;
        if (!sessionStorage.getItem(autoSaveKey)) {
            sessionStorage.setItem(autoSaveKey, localStorage.getItem("autoSaveStorage_noTradechart"));
            sessionStorage.setItem(autoSaveKeyreso, localStorage.getItem("reso"));
        }
        chartSetting_noTradechart = sessionStorage.getItem("autoSaveStorage_noTradechart" + sessionScripCode);
    }
    else {
        chartSetting_noTradechart = JSON.parse(localStorage.getItem("autoSaveStorage_noTradechart"));
        if (chartSetting_noTradechart) {
            chartSetting_noTradechart.charts[0].panes.forEach(pane => {
                if (pane.sources[0].type === "MainSeries") {
                    pane.sources[0].state.symbol = reqSymbol;
                    pane.sources[0].state.shortName = (reqUrl.searchParams.get("symbol")).trim();
                }
            });
            localStorage.setItem("autoSaveStorage_noTradechart", JSON.stringify(chartSetting_noTradechart));
        }
    }
}
var symbolAfterLoad = Datafeed.urlParameter.fullname +
    "/" +
    Datafeed.urlParameter.scripCode +
    "-" +
    Datafeed.urlParameter.exchType;
let firstLoad = true;
if (localStorage.getItem("TokenResponseTv")) {
    localStorage.removeItem("TokenResponseTv");
    await getToken();
}
if (reqUrl.searchParams.get("isTradeChart") == true) {
    if (sessionStorage.getItem("isMultiLayOut_Tradechart") != null) {
        sessionStorage.removeItem("isMultiLayOut_Tradechart");
    }
    if (sessionStorage.getItem("chartContentFlag_Tradechart") == "true") {
        sessionStorage.setItem("chartContentFlag_Tradechart", "false");
    }
}
else {
    if (sessionStorage.getItem("isMultiLayOut_noTradechart") != null) {
        sessionStorage.removeItem("isMultiLayOut_noTradechart");
    }
    if (sessionStorage.getItem("chartContentFlag_noTradechart") == "true") {
        sessionStorage.setItem("chartContentFlag_noTradechart", "false");
    }
}
localStorage.setItem("reso", interval);
//function for option chain opening
function openingOptionChain() {
    //let iframe2;
    function Ifram(newDiv, dragstart) {
        const targetOrigin = reqUrl.hostname === "tv-pre.5paisa.com"
            ? "https://fno-pre.5paisa.com/oc-iframe?"
            : "https://fno.5paisa.com/oc-iframe?";
        var iframe = document.createElement("iframe");
        iframe.id = "myFrame";
        iframe.draggable = true;
        iframe.style.width = "100%";
        iframe.style.height = "95%";
        if (localStorage.getItem("tradingview.current_theme.name") == "light") {
            iframe.style.border = "1px solid #6C94B836";
        }
        else {
            iframe.style.border = "1px solid #667085";
        }
        iframe.style.marginTop = "1.2%";
        // iframe.style.marginLeft = "-0.8%";
        iframe.style.borderRadius = "4px";
        newDiv.appendChild(iframe);
        iframe.addEventListener("dragstart", dragstart);
        var symbolRequired;
        var splitSymbol = window.tvWidget
            .activeChart()
            .symbol()
            .split(":")[1]
            .split("/")[0];
        //if (splitSymbol.includes(" ")) {
        //   symbolRequired = splitSymbol.split(" ")[0];
        // } else {
        //   symbolRequired = splitSymbol;
        // }
        const splitSymbolArr = splitSymbol.split(" ");
        if (splitSymbol.includes("BSE BANKEX")) {
            symbolRequired = splitSymbolArr[1];
        }
        else {
            symbolRequired = splitSymbolArr[0] || splitSymbol;
        }
        const FnoData = getExch_Exchtype_Scripcode(window.tvWidget.activeChart().symbol());
        const params = {
            theme: localStorage.getItem("tradingview.current_theme.name"),
            symbol: symbolRequired,
            metrics: sessionStorage.getItem("PriceGreek") === null
                ? "Price"
                : sessionStorage.getItem("PriceGreek"),
            exch: FnoData.Exch,
            exchtype: FnoData.ExchType,
        };
        var queryString = Object.keys(params)
            .map(function (key) {
            return key + "=" + encodeURIComponent(params[key]);
        })
            .join("&");
        var iframeSrc = targetOrigin + queryString;
        iframe.src = iframeSrc;
        return iframe;
    }
    {
        // console.log("option chain clicked");
        //icon for close button
        var icon = document.createElement("img");
        icon.src = "https://images.5paisa.com/SPOT/chart/deleteIconOptionChain.svg";
        icon.alt = "Icon";
        icon.style.marginRight = "-1px";
        var buttonContainer = document.createElement("div");
        var optionchainTextContainer = document.createElement("div");
        var dropDownContainer = document.createElement("div");
        var dropDownSubContainer = document.createElement("div");
        dropDownContainer.appendChild(dropDownSubContainer);
        var dropDownOptionsContainer = document.createElement("div");
        dropDownOptionsContainer.style.border = "1px solid #E0E1EB";
        dropDownOptionsContainer.style.borderRadius = "4px";
        dropDownSubContainer.appendChild(dropDownOptionsContainer);
        dropDownOptionsContainer.style.position = "absolute";
        dropDownOptionsContainer.style.overflowY = "auto";
        dropDownOptionsContainer.style.backgroundColor = "#fff";
        dropDownOptionsContainer.style.boxShadow = "0 1px 4px #6c94b84d";
        dropDownOptionsContainer.style.marginTop = "30px";
        dropDownOptionsContainer.style.maxHeight = "200px";
        dropDownOptionsContainer.style.width = "82px";
        //dropDownOptionsContainer.style.marginLeft = "-5px";
        dropDownOptionsContainer.style.zIndex = "1";
        dropDownOptionsContainer.style.display = "none";
        dropDownContainer.addEventListener("click", (event) => {
            event.stopPropagation();
            if (dropDownOptionsContainer.style.display == "none") {
                dropDownOptionsContainer.style.display = "block";
            }
            else {
                dropDownOptionsContainer.style.display = "none";
            }
        });
        var span_price_div = document.createElement("div");
        span_price_div.id = "span_price_div";
        var span_greek_div = document.createElement("div");
        span_greek_div.id = "span_greek_div";
        span_price_div.style.padding = "5px 10px";
        span_greek_div.style.padding = "5px 10px";
        var span_price = document.createElement("SPAN");
        span_price.innerHTML = "Price";
        span_price.style.color = " #55586A";
        span_price.style.fontSize = "12px";
        span_price.style.fontWeight = "300";
        span_price.style.fontFamily = "Roboto";
        span_price_div.appendChild(span_price);
        var span_greek = document.createElement("SPAN");
        span_greek.innerHTML = "Greeks";
        span_greek.style.color = " #55586A";
        span_greek.style.fontSize = "12px";
        span_greek.style.fontWeight = "300";
        span_greek.style.fontFamily = "Roboto";
        span_greek_div.appendChild(span_greek);
        dropDownOptionsContainer.appendChild(span_price_div);
        var myUrlParameterss = getUrlParameters(window.location);
        const symbolsToCheck = ["SENSEX", "sensex", "SENSEX50", "sensex50", "BANKEX", "bankex"];
        const exchangeToCheck = "m";
        if (!(symbolsToCheck.includes(myUrlParameterss.symbol.trim()) || (myUrlParameterss === null || myUrlParameterss === void 0 ? void 0 : myUrlParameterss.exchange.toLowerCase()) === exchangeToCheck)) {
            dropDownOptionsContainer.appendChild(span_greek_div);
        }
        var span_tag = document.createElement("span");
        span_tag.id = "span_tag_PG";
        dropDownSubContainer.appendChild(span_tag);
        span_tag.style.color = " #55586A";
        span_tag.style.fontWeight = "300";
        span_tag.style.fontSize = "12px";
        span_tag.style.marginLeft = "8px";
        span_tag.style.fontFamily = "Roboto";
        var imgtag = document.createElement("img");
        imgtag.src =
            "	https://commondatastorage.googleapis.com/images.5paisa.com/fno/assets/svgs/dropdown.svg";
        dropDownSubContainer.appendChild(imgtag);
        span_price_div.addEventListener("mouseover", function () {
            if (localStorage.getItem("tradingview.current_theme.name") == "light") {
                span_price_div.style.backgroundColor = "#e1eaf6";
            }
            else {
                span_price_div.style.backgroundColor = "#344054";
            }
        });
        span_price_div.addEventListener("mouseout", function () {
            span_price_div.style.backgroundColor = "";
        });
        span_greek_div.addEventListener("mouseover", function () {
            if (localStorage.getItem("tradingview.current_theme.name") == "light") {
                span_greek_div.style.backgroundColor = "#e1eaf6";
            }
            else {
                span_greek_div.style.backgroundColor = "#344054";
            }
        });
        span_greek_div.addEventListener("mouseout", function () {
            span_greek_div.style.backgroundColor = "";
        });
        span_price_div.addEventListener("click", function () {
            if (span_tag.innerHTML != "Price") {
                span_tag.innerHTML = "Price";
                imgtag.style.marginLeft = "25px";
                sessionStorage.setItem("PriceGreek", "Price");
            }
            let iframeForPostMsg = document.getElementById("myFrame");
            var iframeOrigin = new URL(iframeForPostMsg.src).origin;
            console.table(iframeOrigin, "Price");
            iframeForPostMsg.contentWindow.postMessage({ type: "Price" }, iframeOrigin);
        });
        span_greek_div.addEventListener("click", () => {
            if (span_tag.innerHTML != "Greeks") {
                span_tag.innerHTML = "Greeks";
                imgtag.style.marginLeft = "20px";
                sessionStorage.setItem("PriceGreek", "Greeks");
            }
            let iframeForPostMsg = document.getElementById("myFrame");
            var iframeOrigin = new URL(iframeForPostMsg.src).origin;
            console.table(iframeOrigin, "Greeks");
            iframeForPostMsg.contentWindow.postMessage({ type: "Greeks" }, iframeOrigin);
        });
        dropDownContainer.style.marginRight = "30px";
        dropDownContainer.style.marginLeft = "auto";
        dropDownContainer.style.marginTop = "-25px";
        if (localStorage.getItem("tradingview.current_theme.name") == "light") {
            dropDownContainer.style.border = "1px solid #E0E1EB";
        }
        else {
            dropDownContainer.style.border = "1px solid #475467";
            span_tag.style.color = "#F9FAFB";
        }
        dropDownContainer.style.borderRadius = "4px";
        dropDownContainer.style.height = "24px";
        dropDownContainer.style.width = "82px";
        dropDownSubContainer.style.height = "13px";
        dropDownSubContainer.style.width = "82px";
        dropDownSubContainer.style.marginTop = "4px";
        dropDownSubContainer.style.alignContent = "center";
        imgtag.style.marginLeft = "20px";
        var myDiv = document.createElement("div");
        myDiv.style.position = "absolute";
        myDiv.style.display = "none";
        myDiv.style.width = "95%";
        myDiv.style.height = "98%";
        myDiv.style.marginLeft = "2%";
        myDiv.style.zIndex = "1";
        //myDiv.style.border = "1px solid black";
        var newDiv = document.createElement("ul");
        newDiv.id = "myFrameDiv";
        newDiv.style.position = "absolute";
        newDiv.style.width = "917px";
        newDiv.style.height = `${window.innerHeight - 200}px`;
        newDiv.style.top = "15%";
        newDiv.style.left = "15%";
        newDiv.style.zIndex = "999";
        newDiv.draggable = true;
        newDiv.style.padding = "20px";
        newDiv.style.border = "1px solid #6C94B836";
        newDiv.style.borderRadius = "4px";
        newDiv.style.boxShadow = " 0px -2px 6px 0px #6C94B81F";
        if (localStorage.getItem("tradingview.current_theme.name") == "light") {
            newDiv.style.backgroundColor = "#FFFFFF";
            icon.src =
                "https://images.5paisa.com/SPOT/chart/deleteIconOptionChain.svg";
            icon.style.width = "20px"; //"#475467";
        }
        else {
            newDiv.style.backgroundColor = "#101828";
            icon.src =
                "https://images.5paisa.com/SPOT/chart/dark-deleteIconOptionChain.svg";
            icon.style.width = "20px";
            dropDownOptionsContainer.style.backgroundColor = "#1e2631";
            dropDownOptionsContainer.style.border = "1px solid #344054";
            span_price.style.color = "#dbdfe7";
            span_greek.style.color = "#dbdfe7";
        }
        newDiv.addEventListener("dragstart", drag_start);
        newDiv.appendChild(buttonContainer);
        newDiv.appendChild(optionchainTextContainer);
        newDiv.appendChild(dropDownContainer);
        iframe2 = Ifram(newDiv, drag_start);
        var parentDiv = document.getElementById("tv_chart_container");
        var existingIframe = parentDiv.childNodes[0];
        parentDiv.insertBefore(newDiv, existingIframe);
        parentDiv.insertBefore(myDiv, existingIframe);
        //option chain text
        var optionChainHeadingSpan = document.createElement("SPAN");
        optionchainTextContainer.appendChild(optionChainHeadingSpan);
        optionChainHeadingSpan.innerHTML = "OPTION CHAIN";
        optionChainHeadingSpan.style.color = "#0080FF";
        optionChainHeadingSpan.style.fontWeight = "300";
        optionChainHeadingSpan.style.fontFamily = "Roboto";
        optionchainTextContainer.style.width = "150px";
        optionchainTextContainer.style.height = "22px";
        optionchainTextContainer.style.zIndex = "999";
        optionchainTextContainer.style.left = "-2px";
        if (!(symbolsToCheck.includes(myUrlParameterss.symbol.trim()) || (myUrlParameterss === null || myUrlParameterss === void 0 ? void 0 : myUrlParameterss.exchange.toLowerCase()) === exchangeToCheck)) {
            if (sessionStorage.getItem("PriceGreek") == null) {
                span_tag.innerHTML = "Price";
                sessionStorage.setItem("PriceGreek", "Price");
            }
            else {
                sessionStorage.getItem("PriceGreek") == "Price"
                    ? (span_tag.innerHTML = "Price")
                    : (span_tag.innerHTML = "Greeks");
            }
        }
        else {
            span_tag.innerHTML = "Price";
            sessionStorage.setItem("PriceGreek", "Price");
        }
        //close option chain button
        buttonContainer.style.border = "none";
        buttonContainer.style.display = "flex";
        buttonContainer.style.padding = "0px";
        buttonContainer.style.justifyContent = "center";
        buttonContainer.style.alignItems = "center";
        buttonContainer.style.position = "absolute";
        buttonContainer.style.top = "18px";
        buttonContainer.style.right = "10px";
        buttonContainer.style.width = "24px";
        buttonContainer.style.height = "24px";
        buttonContainer.style.zIndex = "999";
        buttonContainer.addEventListener("mousemove", function (event) {
            event.stopPropagation(); // Stop event propagation to parent elements
        });
        var newBtn = document.createElement("button");
        newBtn.style.backgroundColor = "transparent";
        newBtn.style.border = "none";
        newBtn.style.alignItems = "center";
        newBtn.style.justifyContent = "center";
        newBtn.style.padding = "10px 20px";
        newBtn.style.display = "flex";
        newBtn.style.cursor = "pointer";
        newBtn.appendChild(icon);
        buttonContainer.appendChild(newBtn);
        newBtn.addEventListener("mouseenter", function () {
            var closeText = document.createElement("span");
            closeText.textContent = "Close";
            closeText.style.color = "#475467";
            closeText.style.fontSize = "12px";
            closeText.style.position = "absolute";
            closeText.style.top = "140%";
            closeText.style.border = "1px solid #6C94B836";
            closeText.style.borderRadius = "2px";
            closeText.style.backgroundColor = "#dee2e6";
            closeText.style.padding = "2px";
            closeText.style.left = "50%";
            closeText.style.transform = "translate(-50%, -50%)";
            closeText.style.opacity = "0";
            closeText.style.transition = "opacity 0.3s ease";
            // Append the text element to the button
            newBtn.appendChild(closeText);
            // Set opacity to 1 to show the text
            setTimeout(function () {
                closeText.style.opacity = "1";
            }, 10); // Delay to ensure transition effect is applied
        });
        newBtn.addEventListener("mouseleave", function () {
            var closeText = newBtn.querySelector("span");
            if (closeText) {
                closeText.style.opacity = "0";
                setTimeout(function () {
                    newBtn.removeChild(closeText);
                }, 300); // Delay to match the transition duration
            }
        });
        newBtn.addEventListener("click", function () {
            newDiv.remove();
            myDiv.style.display = "none";
        });
        newDiv.addEventListener("click", (event) => {
            if (dropDownOptionsContainer.style.display == "block") {
                dropDownOptionsContainer.style.display = "none";
            }
        });
        let myFrames = document.getElementsByTagName("iframe");
        let myFramesContent = myFrames[1].contentDocument.body;
        let aa = myFramesContent.getElementsByClassName("js-rootresizer__contents");
        newDiv.addEventListener("dragstart", drag_start);
        newDiv.addEventListener("dragover", drag_over);
        newDiv.addEventListener("drop", drop);
        myDiv.addEventListener("dragstart", drag_start);
        myDiv.addEventListener("dragover", drag_over);
        myDiv.addEventListener("drop", drop);
        iframe2.addEventListener("dragstart", drag_start);
        iframe2.addEventListener("dragover", drag_over);
        iframe2.addEventListener("drop", drop);
        aa[0].addEventListener("dragstart", drag_start);
        aa[0].addEventListener("dragover", drag_over);
        aa[0].addEventListener("drop", drop);
        window.is_DragOver = false;
        window.is_Drop = false;
        let myTimeout;
        function drag_start(event) {
            myDiv.style.display = "block";
            iframe2.style.display = "none";
            if (myTimeout)
                clearTimeout(myTimeout);
            myTimeout = setTimeout(function () {
                if (window.is_DragOver == false) {
                    // console.log("implement");
                    iframe2.style.display = "block";
                    myDiv.style.display = "none";
                }
            }, 1000);
            var style = window.getComputedStyle(event.target, null);
            var str = parseInt(style.getPropertyValue("left")) -
                event.clientX +
                "," +
                (parseInt(style.getPropertyValue("top")) - event.clientY) +
                "," +
                event.target.id;
            event.dataTransfer.setData("Text", str);
        }
        function drop(event) {
            window.is_Drop = true;
            iframe2.style.display = "block";
            myDiv.style.display = "none";
            window.is_DragOver = false;
            var offset = event.dataTransfer.getData("Text").split(",");
            var dm = document.getElementById(offset[2]);
            dm.style.left = event.clientX + parseInt(offset[0], 10) + "px";
            dm.style.top = event.clientY + parseInt(offset[1], 10) + "px";
            event.preventDefault();
            return false;
        }
        function drag_over(event) {
            window.is_DragOver = true;
            window.is_Drop = false;
            event.preventDefault();
            return false;
        }
    }
}
window.tvWidget = new widget({
    symbol: Datafeed.urlParameter.fullname,
    interval: interval,
    fullscreen: true,
    container: "tv_chart_container",
    datafeed: Datafeed,
    timezone: "Asia/Kolkata",
    custom_font_family: "supreme",
    library_path: "charting_library_clonned_data/charting_library/",
    disabled_features: disabledfeatures,
    enabled_features: enabled_features,
    custom_css_url: "custom_style.css",
    auto_save_delay: 2,
    charts_storage_url: !!userId ? "https://ChartsLayout.5paisa.com/api" : null,
    charts_storage_api_version: !!userId ? "v1.0" : null,
    client_id: !!userId ? "5p-tv" : null,
    user_id: !!userId ? localStorage.getItem("ClientCode") : null,
    overrides: {
        "mainSeriesProperties.showCountdown": !1,
        "mainSeriesProperties.style": 1,
        "paneProperties.horzGridProperties.style": 5,
        "paneProperties.vertGridProperties.style": 5,
        "paneProperties.bottomMargin": 10,
        "mainSeriesProperties.showPrevClosePriceLine": true,
        "paneProperties.background": "#020024",
    },
    studies_overrides: {
        "volume.volume.transparency": 75,
    },
    save_load_adapter: {
        charts: [],
        studyTemplates: [],
        drawingTemplates: [],
        getAllCharts: function () {
            if (reqUrl.searchParams.get("isTradeChart") === "true") {
                sessionStorage.setItem("chartContentFlag_Tradechart", chartContentFlag_Tradechart);
            }
            else {
                sessionStorage.setItem("chartContentFlag_noTradechart", chartContentFlag_noTradechart);
            }
            let fetchData = (url, headers) => {
                return new Promise((resolve, reject) => {
                    fetch(url, { method: "GET", headers: headers })
                        .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not OK");
                        }
                        resolve(response.json().then((res) => res));
                    })
                        .catch((error) => reject(error));
                });
            };
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
            };
            return Promise.resolve(fetchData(`https://chartslayout.5paisa.com/api/v1.0/charts?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, headers));
        },
        removeChart: function (id) {
            function fetchData(url, requestOptions) {
                return new Promise((resolve, reject) => {
                    fetch(url, requestOptions)
                        .then((response) => {
                        if (!response.ok) {
                            // const error = new Error("Network response was not OK");
                            loggly_util.logAdvancedMessage(response, {
                                error: response.statusText,
                                APIURL: url,
                                Windowurl: window.location.href,
                                Token: JSON.parse(localStorage.TokenResponseTv).access_token,
                                JWTTOKEN: localStorage
                                    .getItem("JWTToken")
                                    .replaceAll("JwtToken=", ""),
                            });
                            throw new Error("Network response was not OK");
                        }
                        return response.json(); // Ensure to return the JSON response here
                    })
                        .then((data) => resolve(data)) // Resolve the promise with the parsed data
                        .catch((error) => reject(error));
                });
            }
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                // "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
            };
            const raw = JSON.stringify({
                layout: id.trim(),
            });
            const requestOptions = {
                method: "POST",
                headers: headers,
                body: raw,
                redirect: "follow",
            };
            return fetchData(`https://chartslayout.5paisa.com/api/v1.0/charts/DeleteLayout/?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, requestOptions)
                .then((response) => {
                // Handle the response data here if needed
            })
                .catch((error) => {
                console.error("Error:", error);
            });
            return Promise.reject();
        },
        saveChart: function (chartData) {
            let savingLayout = () => {
                fetch(`https://chartslayout.5paisa.com/api/v1.0/charts?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, {
                    method: "POST",
                    body: JSON.stringify({
                        name: chartData.name.trim(),
                        content: chartData.content,
                        completed: false,
                        symbol: chartData.symbol,
                        resolution: chartData.resolution,
                        theme: localStorage.getItem(`tradingview.current_theme.name`),
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: "Bearer " +
                            localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                        // Authorization:
                        //   "Bearer " +
                        //   JSON.parse(localStorage.TokenResponseTv).access_token,
                        "Cache-Control": "no-cache,no-store",
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                    json;
                });
            };
            savingLayout();
            captureEvent("Top_Menu_Click", {
                ClientCode: localStorage.getItem("ClientCode"),
                Action: "Save_Layout",
                Script_Name: sessionStorage.getItem("load_symbols_Tradechart"),
            });
        },
        getChartContent: function (id) {
            let fetchData = (url, headers) => {
                return new Promise((resolve, reject) => {
                    fetch(url, { method: "GET", headers: headers })
                        .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not OK");
                        }
                        resolve(response.json());
                    })
                        .catch((error) => reject(error));
                });
            };
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
            };
            return Promise.resolve(fetchData(`https://chartslayout.5paisa.com/api/v1.0/charts/${id}?client=5p-tv&user=${localStorage.getItem("ClientCode")}&chart=${id.trim()}`, headers)
                .then((responseData) => {
                if (reqUrl.searchParams.get("isTradeChart") === "true") {
                    chartContentFlag_Tradechart = true;
                    sessionStorage.setItem("chartContentFlag_Tradechart", chartContentFlag_Tradechart);
                    sessionStorage.setItem("load_symbols_Tradechart", JSON.parse(responseData.data.content).charts_symbols);
                    let newLoadObj = JSON.parse(JSON.parse(responseData.data.content).charts_symbols);
                    let ChartsContent = JSON.parse(JSON.parse(responseData.data.content).content);
                    sessionStorage.setItem("chartContent_loadLayout", JSON.stringify(ChartsContent));
                    if (ChartsContent.charts.length > 1) {
                        sessionStorage.setItem("autoSaveStorageMultiLayout_Tradechart", JSON.stringify(ChartsContent));
                    }
                    else {
                        //let syms = newLoadObj[1].symbol;
                        let sym_a = (newLoadObj[1].symbol.split("/"))[1].split("-")[0];
                        sessionStorage.setItem("autoSaveStorage_Tradechart" + sym_a, JSON.stringify(ChartsContent));
                        // let syms = JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"));
                        // for (let i = 0; i < ChartsContent.charts.length; i++) {
                        //   var new_res;
                        //   let sym_a = (syms.symbol).split("/");
                        //   let sym_b = sym_a[1].split("-")[0];
                        //   new_res = JSON.parse(
                        //     sessionStorage.getItem("chartContent_loadLayout")
                        //   );
                        //   new_res.charts = [
                        //     JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart"))
                        //       .charts[i],
                        //   ];
                        //   new_res.layout = "s";
                        //   localStorage.setItem(
                        //     "autoSaveStorage_Tradechart" + sym_b,
                        //     JSON.stringify(new_res)
                        //   );
                        //}
                    }
                    let arrObj = [];
                    for (let key in newLoadObj) {
                        arrObj.push(newLoadObj[key].symbol);
                    }
                    sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(arrObj));
                    let layout = JSON.parse(responseData.data.content);
                    captureEvent("Top_Menu_Click", {
                        ClientCode: localStorage.getItem("ClientCode"),
                        Action: "Load_Layout",
                        Layout_loaded: layout.name,
                    });
                }
                return (responseData = responseData.data.content);
            }));
            return Promise.reject();
        },
        removeStudyTemplate: function (studyTemplateData) {
            // getAuthToken()
            // console.log(studyTemplateData,"data");
            function fetchData(url, requestOptions) {
                return new Promise((resolve, reject) => {
                    fetch(url, requestOptions)
                        .then((response) => {
                        if (!response.ok) {
                            const error = new Error("Network response was not OK");
                            loggly_util.logAdvancedMessage(error, {
                                error: error.message,
                                APIURL: url,
                                Windowurl: window.location.href,
                                Token: JSON.parse(localStorage.TokenResponseTv).access_token,
                                JWTTOKEN: localStorage
                                    .getItem("JWTToken")
                                    .replaceAll("JwtToken=", ""),
                            });
                            throw new Error("Network response was not OK");
                        }
                        resolve(); // Resolve with empty response
                    })
                        .catch((error) => {
                        console.log("fetch error callback");
                        reject(error);
                    });
                });
            }
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
            };
            const raw = JSON.stringify({
                template: studyTemplateData.name.trim(),
            });
            // console.log(studyTemplateData.name.trim(),"trimstudydata");
            const requestOptions = {
                method: "POST",
                headers: headers,
                body: raw,
                redirect: "follow",
            };
            //fetchData(`https://chartslayout.5paisa.com/api/v1.0/study_templates/?client=5p-tv&user=${user}&template=${studyTemplateData.name}`,headers);
            // return Promise.resolve(
            //   fetchData(
            //     `https://5pmarketfeed-api.azurewebsites.net/api/v1.0/study_templates/DeleteTemplate/?client=5p-tv&user=${user}`,
            //     requestOptions
            //     // 'https://chartslayout.5paisa.com/api/v1.0/study_templates/DeleteTemplate?client=5p-tv&user=${user}',
            //     // requestOptions
            //   )
            // );
            return fetchData(`https://chartslayout.5paisa.com/api/v1.0/study_templates/DeleteTemplate/?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, requestOptions);
        },
        getStudyTemplateContent: function (studyTemplateData) {
            let fetchData = (url, headers) => {
                return new Promise((resolve, reject) => {
                    fetch(url, { method: "GET", headers: headers })
                        .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not OK");
                        }
                        resolve(response.json());
                        // or response.text() for plain text
                    })
                        .catch((error) => reject(error));
                });
            };
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                // Authorization:
                //   "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
            };
            return Promise.resolve(fetchData(`https://chartslayout.5paisa.com/api/v1.0/study_templates/${studyTemplateData.name}?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, headers).then((responseData) => {
                // Handle the response data here
                localStorage.setItem("studyCode", responseData.data.scripcode);
                localStorage.setItem("studyexchangeType", responseData.data.exchType);
                localStorage.setItem("studyexchange", responseData.data.exchange);
                if (typeof responseData.data.content == "string") {
                    return (responseData = JSON.stringify(JSON.parse(responseData.data.content)));
                }
                else {
                    return (responseData = JSON.parse(responseData.data.content));
                }
            }));
            return Promise.reject();
        },
        saveStudyTemplate: function (studyTemplateData) {
            var res;
            fetch(`https://chartslayout.5paisa.com/api/v1.0/study_templates?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, {
                method: "POST",
                body: JSON.stringify({
                    name: studyTemplateData.name.trim(),
                    content: studyTemplateData.content,
                    scripcode: Datafeed.urlParameter.scripCode,
                    exchType: Datafeed.urlParameter.exchType,
                    exchange: Datafeed.urlParameter.exchange,
                    completed: false,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " +
                        localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                    // Authorization:
                    //   "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
                    "Cache-Control": "no-cache,no-store",
                },
            })
                .then((response) => response.json())
                .then((json) => {
                res = json;
            });
            captureEvent("Top_Menu_Click", {
                ClientCode: localStorage.getItem("ClientCode"),
                Action: "Save_Indicator_Template",
                Template_Name: studyTemplateData.name.trim(),
                Script_Name: (reqUrl.searchParams.get("isTradeChart") === "true") ? sessionStorage.getItem("load_symbols_Tradechart") : sessionStorage.getItem("load_symbols_noTradechart"),
            });
            return Promise.resolve();
        },
        getAllStudyTemplates: function () {
            var res;
            let fetchData = (url, headers) => {
                return new Promise((resolve, reject) => {
                    fetch(url, { method: "GET", headers: headers })
                        .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not OK");
                        }
                        resolve(response.json().then((res) => res.data)); // or response.text() for plain text
                    })
                        .catch((error) => reject(error));
                });
            };
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                // Authorization:
                //   "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
            };
            return Promise.resolve(fetchData(`https://chartslayout.5paisa.com/api/v1.0/study_templates/${studyTemplateData.name}?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, headers).then((responseData) => {
                // Handle the response data here
                localStorage.setItem("studyCode", responseData.data.scripcode);
                localStorage.setItem("studyexchangeType", responseData.data.exchType);
                localStorage.setItem("studyexchange", responseData.data.exchange);
                if (typeof responseData.data.content == "string") {
                    return (responseData = JSON.stringify(JSON.parse(responseData.data.content)));
                }
                else {
                    return (responseData = JSON.parse(responseData.data.content));
                }
            }));
            return Promise.reject();
        },
        getAllStudyTemplates: function () {
            var res;
            function fetchData(url, headers) {
                return new Promise((resolve, reject) => {
                    fetch(url, { method: "GET", headers: headers })
                        .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not OK");
                        }
                        resolve(response.json().then((res) => res.data)); // or response.text() for plain text
                    })
                        .catch((error) => reject(error));
                });
            }
            const headers = {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " +
                    localStorage.getItem("JWTToken").replaceAll("JwtToken=", ""),
                // Authorization:
                //   "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
            };
            return Promise.resolve(fetchData(`https://chartslayout.5paisa.com/api/v1.0/study_templates?client=5p-tv&user=${localStorage.getItem("ClientCode")}`, headers));
        },
    },
    widgetbar: {
        details: data_window,
        news: false,
        watchlist: data_window,
        datawindow: data_window,
        watchlist_settings: {
            default_symbols: [],
        },
    },
    broker_factory: function (host) {
        const reqUrl = new URL(window.location);
        try {
            if (reqUrl.searchParams.get("isTradeChart") === "true") {
                brokerFac = new BrokerSample(host, Datafeed);
            }
            ;
            return brokerFac;
        }
        catch (error) {
            console.log("error", error);
        }
    },
    broker_config: {
        configFlags: {
            supportPlaceOrderPreview: true,
            supportBottomWidget: true,
            supportNativeReversePosition: true,
            supportClosePosition: true,
            supportPLUpdate: true,
            supportLevel2Data: true,
            showQuantityInsteadOfAmount: true,
            supportEditAmount: true,
            supportOrderBrackets: false,
            supportMarketBrackets: true,
            supportPositionBrackets: false,
            supportModifyOrderType: true,
            supportModifyOrderPrice: true,
            supportModifyDuration: true,
            supportModifyBrackets: true,
            supportStopLimitOrders: true,
            supportModifyOrderPreview: true,
            showNotificationsLog: false,
        },
        durations: [
            { name: "DAY", value: "DAY" },
            { name: "IOC", value: "IOC" },
        ],
        orderDialogOptions: {
            customFields: [
                {
                    id: "2410",
                    inputType: "ComboBox",
                    title: "Product",
                    items: [
                        {
                            text: `${localStorage.getItem("productVal")}`,
                            value: "Intraday", //`${localStorage.getItem("productVal") === "Intraday" ? "Intraday" : localStorage.getItem("productVal") === "Delivery" ? "Delivery" : "Intraday"}`,
                        },
                        {
                            text: `${localStorage.getItem("productVal")}`,
                            value: "Delivery", //`${localStorage.getItem("productVal") === "Intraday" ? "Intraday" : localStorage.getItem("productVal") === "Delivery" ? "Delivery" : "Intraday"}`
                        },
                    ],
                },
            ],
        },
    },
    custom_translate_function: (key, options) => {
        if (key === "Units") {
            return "Quantity";
        }
        if (key === "Stop price") {
            return "Trigger Price";
        }
        if (key === "Time in force") {
            return "Validity";
        }
        return key;
    },
    time_frames: timeFrameArr,
    debug: false,
    theme: themeVal,
    preset: window.location.pathname.includes("basic-tv") ? "mobile" : false,
});
function changeThemeFun(themeVal, tvWidget) {
    if (themeVal === "dark" || themeVal === "Dark") {
        setTimeout(() => {
            tvWidget.changeTheme("Dark");
        }, 500);
    }
    else {
        setTimeout(() => {
            tvWidget.changeTheme("Light");
        }, 500);
    }
}
window.tvWidget.onChartReady(async () => {
    const reqUrl = new URL(window.location);
    try {
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            if (reqUrl.searchParams.get("searchFlag") === "true") {
                let newurls = new URL(window.location);
                let redicted_sym = newurls.searchParams.get("exchange") + ":" + newurls.searchParams.get("symbol") + "/" + newurls.searchParams.get("scripCode") + "-" + newurls.searchParams.get("exchType");
                const getAutoSaveData = JSON.parse(localStorage.getItem("autoSaveStorage_noTradechart"));
                if (getAutoSaveData) {
                    getAutoSaveData.charts[0].panes[0].sources[0].state.symbol = redicted_sym;
                    tvWidget.load(getAutoSaveData);
                    async function changeURLByAutoSave(name) {
                        let reqD = await AlterUrlParam(name);
                        await changeURLparam(reqD);
                    }
                    reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("reso" + sessionScripCode, getAutoSaveData.charts[0].panes[0].sources[0].state.interval) : localStorage.setItem("reso", getAutoSaveData.charts[0].panes[0].sources[0].state.interval);
                    changeURLByAutoSave(redicted_sym);
                    newurls.searchParams.delete("searchFlag");
                    window.history.pushState({}, "", newurls);
                }
            }
            else if (reqUrl.searchParams.has("ispopOutRedirection")) {
                let newurls = new URL(window.location);
                let getAutoSaveData = JSON.parse(sessionStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode));
                if (newurls.searchParams.has("ispopOut"))
                    getAutoSaveData = JSON.parse(localStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode));
                if (getAutoSaveData) {
                    tvWidget.load(getAutoSaveData);
                    async function changeURLByAutoSave(name) {
                        let reqD = await AlterUrlParam(name);
                        await changeURLparam(reqD);
                    }
                    getAutoSaveData.charts[0].panes.forEach((pane) => {
                        if (pane.sources[0].type === "MainSeries") {
                            pane.sources[0].state.symbol = reqSymbol;
                            pane.sources[0].state.shortName = reqUrl.searchParams
                                .get("symbol")
                                .trim();
                            changeURLByAutoSave(pane.sources[0].state.symbol);
                            sessionStorage.setItem("reso" + sessionScripCode, pane.sources[0].state.interval);
                        }
                    });
                }
                newurls.searchParams.delete("ispopOutRedirection");
                window.history.pushState({}, "", newurls);
            }
            else if (sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart") != null) {
                const getAutoSaveData = JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart"));
                tvWidget.load(getAutoSaveData);
                getAutoSaveData.charts[0].panes.forEach((pane) => {
                    if (pane.sources[0].type === "MainSeries") {
                        pane.sources[0].state.symbol = reqSymbol;
                        pane.sources[0].state.shortName = reqUrl.searchParams
                            .get("symbol")
                            .trim();
                        changeURLByAutoSave(pane.sources[0].state.symbol);
                        sessionStorage.setItem("reso" + sessionScripCode, pane.sources[0].state.interval);
                    }
                });
                sessionStorage.setItem("isMultiLayOut", "true");
                sessionStorage.setItem("reso" + sessionScripCode, getAutoSaveData.charts[0].panes[0].sources[0].state.interval);
            }
            else {
                let newurls = new URL(window.location);
                let getAutoSaveData = JSON.parse(sessionStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode));
                if (newurls.searchParams.has("ispopOut"))
                    getAutoSaveData = JSON.parse(localStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode));
                if (getAutoSaveData) {
                    tvWidget.load(getAutoSaveData);
                    async function changeURLByAutoSave(name) {
                        let reqD = await AlterUrlParam(name);
                        await changeURLparam(reqD);
                    }
                    getAutoSaveData.charts[0].panes.forEach((pane) => {
                        if (pane.sources[0].type === "MainSeries") {
                            pane.sources[0].state.symbol = reqSymbol;
                            pane.sources[0].state.shortName = reqUrl.searchParams
                                .get("symbol")
                                .trim();
                            changeURLByAutoSave(pane.sources[0].state.symbol);
                            sessionStorage.setItem("reso" + sessionScripCode, pane.sources[0].state.interval);
                        }
                    });
                }
            }
        }
        else {
            if (reqUrl.searchParams.get("searchFlag") === "true") {
                if (sessionStorage.getItem("autoSaveStorageMultiLayout_noTradechart") != undefined) {
                    const getAutoSaveData = JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_noTradechart"));
                    tvWidget.load(getAutoSaveData);
                    async function changeURLByAutoSave(name) {
                        let reqD = await AlterUrlParam(name);
                        await changeURLparam(reqD);
                    }
                    reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("reso" + sessionScripCode, getAutoSaveData.charts[0].panes[0].sources[0].state.interval) : localStorage.setItem("reso", getAutoSaveData.charts[0].panes[0].sources[0].state.interval);
                    changeURLByAutoSave(getAutoSaveData.charts[0].panes[0].sources[0].state.symbol);
                }
                else {
                    const getAutoSaveData = JSON.parse(reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.getItem("autoSaveStorage_noTradechart" + sessionScripCode) : localStorage.getItem("autoSaveStorage_noTradechart"));
                    if (getAutoSaveData) {
                        tvWidget.load(getAutoSaveData);
                        async function changeURLByAutoSave(name) {
                            let reqD = await AlterUrlParam(name);
                            await changeURLparam(reqD);
                        }
                        reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("reso" + sessionScripCode, getAutoSaveData.charts[0].panes[0].sources[0].state.interval) : localStorage.setItem("reso", getAutoSaveData.charts[0].panes[0].sources[0].state.interval);
                        changeURLByAutoSave(getAutoSaveData.charts[0].panes[0].sources[0].state.symbol);
                    }
                }
                ;
            }
            else {
                const getAutoSaveData = JSON.parse(reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.getItem("autoSaveStorage_noTradechart" + sessionScripCode) : localStorage.getItem("autoSaveStorage_noTradechart"));
                let newurls = new URL(window.location);
                let redicted_sym = newurls.searchParams.get("exchange") + ":" + newurls.searchParams.get("symbol") + "/" + newurls.searchParams.get("scripCode") + "-" + newurls.searchParams.get("exchType");
                if (getAutoSaveData) {
                    getAutoSaveData.charts[0].panes[0].sources[0].state.symbol = redicted_sym;
                    tvWidget.load(getAutoSaveData);
                    async function changeURLByAutoSave(name) {
                        let reqD = await AlterUrlParam(name);
                        await changeURLparam(reqD);
                    }
                    reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("reso" + sessionScripCode, getAutoSaveData.charts[0].panes[0].sources[0].state.interval) : localStorage.setItem("reso", getAutoSaveData.charts[0].panes[0].sources[0].state.interval);
                    changeURLByAutoSave(getAutoSaveData.charts[0].panes[0].sources[0].state.symbol);
                }
            }
        }
    }
    catch (_a) {
        console.log("autosave causing probelm");
    }
    // To track the last known state of the tab
    if (reqUrl.searchParams.get("isTradeChart") === "true") {
        if (Datafeed.urlParameter.ispopOut !== "true") {
            observeTabChanges(brokerFac);
        }
    }
    async function changeURLByAutoSave(name) {
        let reqD = await AlterUrlParam(name);
        await changeURLparam(reqD);
    }
    tvWidget.subscribe("onAutoSaveNeeded", function (res) {
        tvWidget.save(async function (res) {
            if (reqUrl.searchParams.get("isTradeChart") === "true") {
                if (res.layout === "s") {
                    res.charts[0].panes.forEach((pane) => {
                        if (pane.sources[0].type === "MainSeries") {
                            let sym = pane.sources[0].state.symbol;
                            let scripCode = sym.split("/")[1].split("-")[0];
                            changeURLByAutoSave(pane.sources[0].state.symbol);
                            sessionStorage.setItem("reso" + sessionScripCode, pane.sources[0].state.interval);
                            sessionStorage.setItem("autoSaveStorage_Tradechart" + scripCode, JSON.stringify(res));
                            localStorage.setItem("autoSaveStorage_Tradechart" + scripCode, JSON.stringify(res));
                        }
                    });
                    if (sessionStorage.autoSaveStorageMultiLayout_Tradechart)
                        sessionStorage.removeItem("autoSaveStorageMultiLayout_Tradechart");
                }
                else {
                    sessionStorage.setItem("autoSaveStorageMultiLayout_Tradechart", JSON.stringify(res));
                    setTimeout(myFun, 0, res);
                    function myFun(res) {
                        for (let i = 0; i < res.charts.length; i++) {
                            var new_res;
                            res.charts[i].panes.forEach((pane) => {
                                if (pane.sources[0].type === "MainSeries") {
                                    let sym = pane.sources[0].state.symbol;
                                    let scripCode = sym.split("/")[1].split("-")[0];
                                    new_res = JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart"));
                                    new_res.charts = [
                                        JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart")).charts[i],
                                    ];
                                    new_res.layout = "s";
                                    localStorage.setItem("autoSaveStorage_Tradechart" + scripCode, JSON.stringify(new_res));
                                }
                            });
                        }
                    }
                }
            }
            else {
                if (res.layout == "s") {
                    reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("autoSaveStorage_noTradechart" + sessionScripCode, JSON.stringify(res)) : localStorage.setItem("autoSaveStorage_noTradechart", JSON.stringify(res));
                    sessionStorage.removeItem("autoSaveStorageMultiLayout_noTradechart");
                }
                else {
                    sessionStorage.setItem("autoSaveStorageMultiLayout_noTradechart", JSON.stringify(res));
                }
            }
        });
    });
    window.tvWidget.subscribe("layout_about_to_be_changed", async function (LayoutType) {
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            if (LayoutType != "s") {
                sessionStorage.setItem("isMultiLayOut_Tradechart", "true");
            }
            try {
                let dd = document
                    .getElementById("tv_chart_container")
                    .getElementsByTagName("iframe")[0]
                    .contentDocument.getElementsByTagName("body")[0]
                    .querySelector(".chart-gui-wrapper")
                    .querySelector("#parentBuySell");
                if (sessionStorage.getItem("isMultiLayOut_Tradechart") == "true") {
                    dd.style.display = "none";
                }
                else {
                    dd.style.display = "block";
                }
            }
            catch (error) {
                console.log(error);
            }
            window.tvWidget.save(async function (res) {
                sessionStorage.setItem("LayoutType_Tradechart", LayoutType);
                sessionStorage.setItem("isMultiLayOut_Tradechart", "true");
                let reqD = await AlterUrlParam(JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"))[sessionStorage.getItem("activeChartChanged_Tradechart")]);
                await changeURLparam(reqD);
                if (sessionStorage.getItem("isMultiLayOut_Tradechart") == "true" &&
                    sessionStorage.getItem("chartContentFlag_Tradechart") != "true") {
                    var symbolInfoDataa = JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"));
                    if (checkingLayoutType(LayoutType) > symbolInfoDataa.length) {
                        var lenn = checkingLayoutType(LayoutType) - symbolInfoDataa.length;
                        for (let i = 0; i < lenn; i++) {
                            symbolInfoDataa.push(symbolInfoDataa[symbolInfoDataa.length - 1]);
                        }
                        sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(symbolInfoDataa));
                    }
                    else {
                        var newarrr = [];
                        for (let i = 0; i < checkingLayoutType(LayoutType); i++) {
                            newarrr.push(symbolInfoDataa[i]);
                        }
                        sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(newarrr));
                    }
                }
            });
        }
        else {
            if (LayoutType != "s") {
                sessionStorage.setItem("isMultiLayOut_noTradechart", "true");
                tvWidget.save(function (res) {
                    sessionStorage.setItem("autoSaveStorageMultiLayout_noTradechart", JSON.stringify(res));
                });
            }
            else {
                if (reqUrl.searchParams.get("searchFlag") === "true")
                    sessionStorage.setItem("autoSaveStorage_noTradechart" + sessionScripCode, JSON.stringify(res));
            }
            try {
                let dd = document
                    .getElementById("tv_chart_container")
                    .getElementsByTagName("iframe")[0]
                    .contentDocument.getElementsByTagName("body")[0]
                    .querySelector(".chart-gui-wrapper")
                    .querySelector("#parentBuySell");
                if (sessionStorage.getItem("isMultiLayOut_noTradechart") == "true") {
                    dd.style.display = "none";
                }
                else {
                    dd.style.display = "block";
                }
            }
            catch (error) {
                console.log(error);
            }
            tvWidget.save(async function (res) {
                sessionStorage.setItem("LayoutType_noTradechart", LayoutType);
                sessionStorage.setItem("isMultiLayOut_noTradechart", "true");
                let reqD = await AlterUrlParam(JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"))[sessionStorage.getItem("activeChartChanged_noTradechart")]);
                await changeURLparam(reqD);
                if (sessionStorage.getItem("isMultiLayOut_noTradechart") == "true" &&
                    sessionStorage.getItem("chartContentFlag_noTradechart") != "true") {
                    var symbolInfoDataa = JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"));
                    if (checkingLayoutType(LayoutType) > symbolInfoDataa.length) {
                        var lenn = checkingLayoutType(LayoutType) - symbolInfoDataa.length;
                        for (let i = 0; i < lenn; i++) {
                            symbolInfoDataa.push(symbolInfoDataa[symbolInfoDataa.length - 1]);
                        }
                        sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify(symbolInfoDataa));
                    }
                    else {
                        var newarrr = [];
                        for (let i = 0; i < checkingLayoutType(LayoutType); i++) {
                            newarrr.push(symbolInfoDataa[i]);
                        }
                        sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify(newarrr));
                    }
                }
            });
        }
    });
    window.tvWidget.subscribe("activeChartChanged", async function (index) {
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            for (let i = 0; i < window.tvWidget.chartsCount(); i++) {
                if (i == index) {
                    window.tvWidget.activeChart();
                }
            }
            window.tvWidget.save(function (res) {
                sessionStorage.setItem("activeChartChanged_Tradechart", index);
                let chartChangeArr = [];
                for (let i = 0; i < res.charts.length; i++) {
                    if (i == index) {
                        let getLocalAutoSaveData = chartSetting_Tradechart;
                        if (getLocalAutoSaveData) {
                            getLocalAutoSaveData.charts[0] = res.charts[i];
                            sessionStorage.setItem("autoSaveStorage_Tradechart" + sessionScripCode, JSON.stringify(getLocalAutoSaveData));
                        }
                    }
                    res.charts[i].panes.forEach((pane) => {
                        if (pane.sources[0].type === "MainSeries") {
                            chartChangeArr.push(pane.sources[0].state.symbol);
                        }
                    });
                }
                sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(chartChangeArr));
            });
            let reqD = await AlterUrlParam(JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"))[index]);
            await changeURLparam(reqD);
            //hiding the timeframe according to the condition
            var myUrlParameters = getUrlParameters(window.location);
            function postMessageURLparams() {
                let url = new URL(window.location);
                let newUrlParam = url.searchParams;
                let name = `${newUrlParam.get("symbol")}`;
                let symbolName = `${newUrlParam.get("symbol")}`;
                if (newUrlParam.get("type") !== "overview") {
                    name += ` ${newUrlParam.get("expiry_date")}`;
                    if (newUrlParam.get("option_type")) {
                        name += ` ${newUrlParam.get("option_type")}`;
                    }
                    if (newUrlParam.get("strike_price")) {
                        name += ` ${newUrlParam.get("strike_price").replaceAll(".00", "")}`;
                    }
                }
                var myObj = {
                    fullName: name,
                    exchange: newUrlParam.has("exchange")
                        ? newUrlParam.get("exchange").split("")[0].toUpperCase()
                        : newUrlParam.get("exch").split("")[0].toUpperCase(),
                    exchangeType: newUrlParam.get("exchType"),
                    symbol: symbolName,
                    scripCode: newUrlParam.get("scripCode"),
                    optionType: newUrlParam.has("option_type")
                        ? newUrlParam.get("option_type")
                        : "",
                    strikePrice: newUrlParam.has("strike_price")
                        ? newUrlParam.get("strike_price")
                        : "",
                    expiry: newUrlParam.has("expiry_date")
                        ? newUrlParam.get("expiry_date")
                        : "",
                    isSearch: true,
                };
                return myObj;
            }
            window.parent.postMessage(postMessageURLparams(), window.location.ancestorOrigins[0]);
            if (myUrlParameters.type != "overview") {
                try {
                    setTimeout(function () {
                        let ddd = document
                            .getElementById("tv_chart_container")
                            .getElementsByTagName("iframe")[0]
                            .contentDocument.getElementsByTagName("body")[0]
                            .querySelector(".dateRangeExpanded-BXXUwft2");
                        let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
                        reqDivs.forEach((div) => {
                            if (div.textContent == "1Y" ||
                                div.textContent == "5Y" ||
                                div.textContent == "MAX") {
                                div.style.display = "none";
                            }
                        });
                    }, 1000);
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        else {
            for (let i = 0; i < tvWidget.chartsCount(); i++) {
                if (i == index) {
                    tvWidget
                        .activeChart()
                        .onIntervalChanged()
                        .subscribe(null, (interval, timeframeObj) => {
                        if (interval == "1D") {
                            var allSym = JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"));
                            let newA = [];
                            for (let i = 0; i < allSym.length; i++) {
                                let matches = allSym[i].split("/")[1];
                                newA.push(matches);
                            }
                            sessionStorage.setItem("layoutScArr_noTradechart", JSON.stringify(newA));
                        }
                        Datafeed.urlParameter.searchFlag === "true" ? sessionStorage.setItem("reso" + sessionScripCode, interval) : localStorage.setItem("reso", interval);
                        var feedLength = localStorage.getItem("length");
                        if (Datafeed.urlParameter.type == "futures" ||
                            Datafeed.urlParameter.type == "options" ||
                            Datafeed.urlParameter.type == "options-curcom" ||
                            Datafeed.urlParameter.type == "future-curcom" ||
                            Datafeed.urlParameter.type == "Future-curcom") {
                            if ([
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "10",
                                "15",
                                "30",
                                "60",
                                "240",
                            ].includes(interval)) {
                                if (localStorage.getItem("intraDaydata_noTradechart") > 0 &&
                                    localStorage.getItem("socket_noTradechart") == "false") {
                                    //document.getElementById("error_msg").style.display = "none";
                                }
                            }
                            else if (feedLength == 0 &&
                                localStorage.getItem("socket_noTradechart") == "false") {
                                //document.getElementById("error_msg").style.display = "block";
                            }
                        }
                    });
                }
            }
            tvWidget.save(function (res) {
                sessionStorage.setItem("activeChartChanged_noTradechart", index);
                let chartChangeArr = [];
                for (let i = 0; i < res.charts.length; i++) {
                    if (i == index) {
                        let getLocalAutoSaveData = JSON.parse(chartSetting_noTradechart);
                        if (getLocalAutoSaveData) {
                            getLocalAutoSaveData.charts[0] = res.charts[i];
                            reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("autoSaveStorage_noTradechart" + sessionScripCode, JSON.stringify(getLocalAutoSaveData)) : localStorage.setItem("autoSaveStorage_noTradechart", JSON.stringify(getLocalAutoSaveData));
                        }
                    }
                    res.charts[i].panes.forEach(pane => {
                        if (pane.sources[0].type === "MainSeries") {
                            chartChangeArr.push(pane.sources[0].state.symbol);
                        }
                    });
                    //chartChangeArr.push(res.charts[i].panes[0].sources[0].state.symbol)
                }
                sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify(chartChangeArr));
            });
            let reqD = await AlterUrlParam(JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"))[index]);
            await changeURLparam(reqD);
            //hiding the timeframe according to the condition
            var myUrlParameters = getUrlParameters(window.location);
            function postMessageURLparams() {
                let url = new URL(window.location);
                let newUrlParam = url.searchParams;
                let name = `${newUrlParam.get("symbol")}`;
                let symbolName = `${newUrlParam.get("symbol")}`;
                if (newUrlParam.get("type") !== "overview") {
                    name += ` ${newUrlParam.get("expiry_date")}`;
                    if (newUrlParam.get("option_type")) {
                        name += ` ${newUrlParam.get("option_type")}`;
                    }
                    if (newUrlParam.get("strike_price")) {
                        name += ` ${newUrlParam.get("strike_price").replaceAll(".00", "")}`;
                    }
                }
                var myObj = {
                    fullName: name,
                    exchange: newUrlParam.has("exchange")
                        ? newUrlParam.get("exchange").split("")[0].toUpperCase()
                        : newUrlParam.get("exch").split("")[0].toUpperCase(),
                    exchangeType: newUrlParam.get("exchType"),
                    symbol: symbolName,
                    scripCode: newUrlParam.get("scripCode"),
                    optionType: newUrlParam.has("option_type") ? newUrlParam.get("option_type") : "",
                    strikePrice: newUrlParam.has("strike_price") ? newUrlParam.get("strike_price") : "",
                    expiry: newUrlParam.has("expiry_date") ? newUrlParam.get("expiry_date") : "",
                    isSearch: true
                };
                return myObj;
            }
            if (browserName != "FIREFOX") {
                window.parent.postMessage(postMessageURLparams(), window.location.ancestorOrigins[0]);
            }
            else {
                try {
                    function getAncestorOrigins() {
                        const urls = [];
                        let parentWin = window;
                        while (parentWin !== window.top) {
                            if (parentWin.document.referrer) {
                                try {
                                    const url = new URL(parentWin.document.referrer);
                                    urls.push(url.origin);
                                }
                                catch (e) {
                                }
                            }
                            parentWin = parentWin.parent;
                        }
                        return urls;
                    }
                    let pp = getAncestorOrigins();
                    window.parent.postMessage(postMessageURLparams(), pp[0]);
                }
                catch (error) {
                    console.error('Error accessing parent window location:', error);
                }
            }
            ;
            if (myUrlParameters.type != "overview") {
                try {
                    setTimeout(function () {
                        let ddd = document
                            .getElementById("tv_chart_container")
                            .getElementsByTagName("iframe")[0]
                            .contentDocument.getElementsByTagName("body")[0]
                            .querySelector(".dateRangeExpanded-BXXUwft2");
                        let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
                        reqDivs.forEach((div) => {
                            if (div.textContent == "1Y" ||
                                div.textContent == "5Y" ||
                                div.textContent == "MAX") {
                                div.style.display = "none";
                            }
                        });
                    }, 1000);
                }
                catch (error) {
                    console.log(error);
                }
            }
            ;
        }
    });
    window.tvWidget.subscribe("layout_changed", function (res) {
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            window.tvWidget.save(function (res) {
                if (res.layout == "s") {
                    localStorage.setItem("autoSaveStorage_Tradechart", JSON.stringify(res));
                }
                let chartChangeArr = [];
                let layoutScArr = [];
                for (let i = 0; i < res.charts.length; i++) {
                    const input = res.charts[i].panes[0].sources[0].state.symbol;
                    const parts = input.split("/")[1];
                    layoutScArr.push(parts);
                    chartChangeArr.push(res.charts[i].panes[0].sources[0].state.symbol);
                }
                sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(chartChangeArr));
                // sessionStorage.setItem("layoutScArr", JSON.stringify(layoutScArr));
            });
        }
        else {
            tvWidget.save(function (res) {
                if (res.layout == "s") {
                    reqUrl.searchParams.get("searchFlag") === "true" ? sessionStorage.setItem("autoSaveStorage_noTradechart" + sessionScripCode, JSON.stringify(res)) : localStorage.setItem("autoSaveStorage_noTradechart", JSON.stringify(res));
                    sessionStorage.removeItem("autoSaveStorageMultiLayout_noTradechart");
                }
                else {
                    sessionStorage.setItem("autoSaveStorageMultiLayout_noTradechart", JSON.stringify(res));
                }
                ;
                let chartChangeArr = [];
                let layoutScArr = [];
                for (let i = 0; i < res.charts.length; i++) {
                    const input = res.charts[i].panes[0].sources[0].state.symbol;
                    const parts = input.split('/')[1];
                    layoutScArr.push(parts);
                    chartChangeArr.push(res.charts[i].panes[0].sources[0].state.symbol);
                }
                ;
                sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify(chartChangeArr));
                sessionStorage.setItem("layoutScArr_noTradechart", JSON.stringify(layoutScArr));
            });
        }
    });
    window.addEventListener("message", async (event) => {
        let origins = ["https://fno.5paisa.com", "https://fno-pre.5paisa.com", "https://fno360.5paisa.com", "https://newweb.5paisa.com", "https://tradestation-pre.5paisa.com"];
        if (origins.includes(event.origin)) {
            let receivedData = event.data;
            if (receivedData.actionType == "buysellform") {
                sessionStorage.setItem("buySellFlag", receivedData.actionType);
                await handleMessageEvent(event, brokerFac._handleMessageEvent);
                // brokerFac._handleMessageEvent?.showOrderDialog ? brokerFac._handleMessageEvent.showOrderDialog(order) : () => {};
            }
            if (receivedData.actionType === "chartNavigation") {
                let getAutoSaveData;
                const reqUrl = new URL(window.location);
                const key = reqUrl.searchParams.get("isTradeChart") === "true" ? "autoSaveStorage_Tradechart" : "autoSaveStorage_noTradechart";
                let sessionScripCode = reqUrl.searchParams.get("scripCode");
                if (sessionStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode) != null) {
                    getAutoSaveData = JSON.parse(sessionStorage.getItem("autoSaveStorage_Tradechart" + sessionScripCode));
                }
                else {
                    getAutoSaveData = JSON.parse(localStorage.getItem(key));
                }
                if (getAutoSaveData == null || getAutoSaveData == undefined) {
                    getAutoSaveData = JSON.parse(localStorage.getItem(autoSaveStorage));
                }
                ;
                var exChange = "NSE";
                switch (receivedData.data.exchange) {
                    case "M":
                        exChange = "MCX";
                        break;
                    case "B":
                        exChange = "BSE";
                        break;
                    default:
                        exChange = "NSE";
                }
                let tickerSymbol = exChange +
                    ":" +
                    receivedData.data.fullName +
                    "/" +
                    Number(receivedData.data.scripCode) +
                    "-" +
                    receivedData.data.exchangeType;
                getAutoSaveData.charts[0].panes[0].sources[0].state.symbol = tickerSymbol;
                window.tvWidget.load(getAutoSaveData);
                let reqD = await AlterUrlParam(tickerSymbol);
                await changeURLparam(reqD);
            }
            if (receivedData.actionType.includes("SENSEX searched") || receivedData.actionType.includes("BANKEX searched") || receivedData.actionType.includes("exch M")) {
                let span_instance = document.getElementById("span_tag_PG");
                let span_greek_div_instance = document.getElementById("span_greek_div");
                span_greek_div_instance.style.display = "none";
                span_instance.innerHTML = "Price";
                sessionStorage.setItem("PriceGreek", "Price");
            }
            else {
                let span_instance = document.getElementById("span_tag_PG");
                let span_greek_div_instance = document.getElementById("span_greek_div");
                span_greek_div_instance.style.display = "block";
                if (sessionStorage.getItem("PriceGreek") == "Price") {
                    span_instance.innerHTML = "Price";
                }
                else {
                    span_instance.innerHTML = "Greeks";
                    sessionStorage.setItem("PriceGreek", "Greeks");
                }
            }
        }
    });
    //option chain button
    window.tvWidget.onContextMenu(function (unixtime, price) {
        const reqUrl = new URL(window.location);
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            return [
                {
                    position: "top",
                    text: "Option Chain",
                    click: function () {
                        let myDivo = document.getElementById("myFrameDiv");
                        if (myDivo == null) {
                            openingOptionChain();
                        }
                    },
                },
            ];
        }
    });
    //watchlist code
    let watchdef;
    if (reqUrl.searchParams.get("isTradeChart") === "true") {
        if (Datafeed.urlParameter.ispopOut !== "true") {
            window.watchlistApi = await tvWidget.watchList();
            watchdef = await (async () => {
                try {
                    const mwatchNames = await getDefault();
                    return mwatchNames;
                }
                catch (error) {
                    // Handle errors here
                    console.error(error);
                }
            })();
        }
    }
    ;
    let filterIsEditable = (watchdef, name) => {
        let returnValue;
        for (let i = 0; i < watchdef.length; i++) {
            if (watchdef[i].MwatchName == name) {
                returnValue = watchdef[i].CanEditOrDelete;
                return returnValue;
            }
        }
    };
    changeThemeFun(themeVal, tvWidget);
    let status;
    if (localStorage.getItem("CDSL") == "true") {
        async function authData() {
            var _a, _b;
            //status?.body?.GetSellAuthorizationStatusList[0].AuthorizationStatus
            status = await getSellAuthStatus();
            if (((_a = status === null || status === void 0 ? void 0 : status.body) === null || _a === void 0 ? void 0 : _a.GetSellAuthorizationStatusList[0].AuthorizationStatus) ===
                "Y") {
                const dialog = document.querySelector("#auth-success");
                dialog.showModal();
                localStorage.removeItem("CDSL");
                const done = document.getElementById("donebtn");
                done.addEventListener("click", function () {
                    dialog.close();
                });
                let cross = document.getElementById("fc");
                cross === null || cross === void 0 ? void 0 : cross.addEventListener("click", function () {
                    dialog === null || dialog === void 0 ? void 0 : dialog.close();
                });
                localStorage.removeItem("CDSL");
            }
            else if (((_b = status === null || status === void 0 ? void 0 : status.body) === null || _b === void 0 ? void 0 : _b.GetSellAuthorizationStatusList[0].AuthorizationStatus) ===
                "N") {
                const dialog1 = document.querySelector("#auth-failed");
                dialog1.showModal();
                localStorage.removeItem("CDSL");
                const ok = document.getElementById("ok");
                ok.addEventListener("click", function () {
                    dialog1.close();
                });
                let cross1 = document.getElementById("sc");
                cross1 === null || cross1 === void 0 ? void 0 : cross1.addEventListener("click", function () {
                    dialog1 === null || dialog1 === void 0 ? void 0 : dialog1.close();
                });
                localStorage.removeItem("CDSL");
            }
        }
        authData();
    }
    // window.tvWidget.subscribe("study", (event) => {
    //   let ind = `${event.value}`;
    //   captureEvent("Top_Menu_Click", {
    //     ClientCode: localStorage.getItem("ClientCode"),
    //     Action: "Indicators",
    //     Script_Name: sessionStorage.getItem("load_symbols"),
    //     Selected_Indicator: ind,
    //   });
    // });
    //hiding the timeframe according to the condition
    var myUrlParameters = getUrlParameters(window.location);
    if (myUrlParameters.type != "overview") {
        try {
            if (firstLoad == true) {
                setTimeout(function () {
                    let ddd = document
                        .getElementById("tv_chart_container")
                        .getElementsByTagName("iframe")[0]
                        .contentDocument.getElementsByTagName("body")[0]
                        .querySelector(".dateRangeExpanded-BXXUwft2");
                    let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
                    reqDivs.forEach((div) => {
                        if (div.textContent == "1Y" ||
                            div.textContent == "5Y" ||
                            div.textContent == "MAX") {
                            div.style.display = "none";
                        }
                    });
                }, 1000);
                firstLoad = false;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    else {
        try {
            let ddd = document
                .getElementById("tv_chart_container")
                .getElementsByTagName("iframe")[0]
                .contentDocument.getElementsByTagName("body")[0]
                .querySelector(".dateRangeExpanded-BXXUwft2");
            let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
            reqDivs.forEach((div) => {
                if (div.textContent == "1Y" ||
                    div.textContent == "5Y" ||
                    div.textContent == "MAX") {
                    div.style.display = "flex";
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    var param = new URLSearchParams(location.search);
    window.tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, async (symbol) => {
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            let getLoadSymbl = JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"));
            getLoadSymbl[sessionStorage.getItem("activeChartChanged_Tradechart")] =
                !symbol.ticker.includes("-") && !symbol.ticker.includes("/")
                    ? `${symbol.ticker}/${symbol.scripCode}-${symbol.exchType}`
                    : symbol.ticker;
            symbol.ticker;
            sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(getLoadSymbl));
            if (!symbol.ticker.includes("-") && !symbol.ticker.includes("/")) {
                let reqD = await AlterUrlParam(`${symbol.ticker}/${symbol.scripCode}-${symbol.exchType}`);
                await changeURLparam(reqD);
            }
            else {
                let reqD = await AlterUrlParam(symbol.ticker);
                await changeURLparam(reqD);
            }
        }
        else {
            let getLoadSymbl = JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"));
            getLoadSymbl[sessionStorage.getItem("activeChartChanged_noTradechart")] =
                !symbol.ticker.includes("-") && !symbol.ticker.includes("/")
                    ? `${symbol.ticker}/${symbol.scripCode}-${symbol.exchType}`
                    : symbol.ticker;
            symbol.ticker;
            sessionStorage.setItem("load_symbols_noTradechart", JSON.stringify(getLoadSymbl));
            if (!symbol.ticker.includes("-") && !symbol.ticker.includes("/")) {
                let reqD = await AlterUrlParam(`${symbol.ticker}/${symbol.scripCode}-${symbol.exchType}`);
                await changeURLparam(reqD);
            }
            else {
                let reqD = await AlterUrlParam(symbol.ticker);
                await changeURLparam(reqD);
            }
        }
    });
    if (reqUrl.searchParams.get("isTradeChart") === "true") {
        if (Datafeed.urlParameter.ispopOut !== "true") {
            if (!sessionStorage.getItem("WatchList_set")) {
                const returnFwatchdef = watchdef.map((item) => item.MwatchName);
                const WatchList_set = new Set(returnFwatchdef);
                const newMap = new Map();
                sessionStorage.setItem("WatchList_set", JSON.stringify(Array.from(WatchList_set)));
                Promise.all(Array.from(WatchList_set).map((watch) => getDefaultSymbolNames(watch).then((symbolList) => {
                    newMap.set(watch, symbolList);
                    return watchlistApi.createList(watch, symbolList);
                })));
                let defaultSetWatchInterval = setInterval(() => {
                    if (watchlistApi) {
                        const existingListsObject = watchlistApi.getAllLists();
                        if (existingListsObject) {
                            const existingListsArrays = Object.values(existingListsObject);
                            // const filterdArr = existingListsArrays.filter((item) =>
                            //   ["NIFTY50", "Watchlist"].includes(item.title)
                            // );
                            for (const item of existingListsArrays) {
                                const watchTitleFromLocalStorage = localStorage.getItem("WatchTitle");
                                // Check if WatchTitle is undefined or null
                                if (watchTitleFromLocalStorage === undefined ||
                                    watchTitleFromLocalStorage === null) {
                                    // Set the active list to NIFTY50
                                    if (item.title === "NIFTY50") {
                                        watchlistApi.setActiveList(item.id);
                                        item.symbols.forEach((element) => {
                                            clearInterval(defaultSetWatchInterval);
                                        });
                                    }
                                }
                                else {
                                    // WatchTitle is defined, set the active list based on WatchTitle
                                    if (item.title === watchTitleFromLocalStorage) {
                                        watchlistApi.setActiveList(item.id);
                                        item.symbols.forEach((element) => {
                                            clearInterval(defaultSetWatchInterval);
                                        });
                                    }
                                }
                                if (item.title === "Watchlist") {
                                    watchlistApi.deleteList(item.id);
                                }
                            }
                            sessionStorage.setItem("newMap", JSON.stringify(Array.from(newMap)));
                        }
                    }
                }, 1000);
            }
            /////////////Watchlist Remaining code
            if (watchlistApi) {
                let counter = 0;
                watchlistApi.onListChanged().subscribe(null, async function (aa, bb) {
                    if (counter == 0) {
                        const activeListsID = watchlistApi.getActiveListId();
                        const existingListsObject = watchlistApi.getAllLists();
                        const existingListsArray = Object.values(existingListsObject);
                        let globalLengthHolder = previousLengthHolder;
                        const existingListNames = existingListsArray.map((list) => list.title);
                        const WatchactiveList = existingListsArray.find((list) => list.id === activeListsID);
                        if (WatchactiveList) {
                            var Watch_title = WatchactiveList.title;
                            var activeListid = WatchactiveList.id;
                            var ActiveWatchListSymbols = WatchactiveList.symbols;
                        }
                        //this if else for watchlist title
                        else {
                            console.log("Active List not found in the existingListsArray");
                        }
                        const saveMW = async (watchlistAction, WatchListname, Watch_exchange, Watch_Scripcode, Watch_Exchtype) => {
                            try {
                                const myHeaders = new Headers();
                                myHeaders.append("Ocp-apim-subscription-key", APP_OCP_APIM_KEY);
                                myHeaders.append("content-type", "application/json");
                                const raw = JSON.stringify({
                                    head: {
                                        appName: "5PTRADE",
                                        appSource: "09",
                                        appVer: "1.0",
                                        key: APP_HEAD_KEY,
                                        osName: "Web",
                                        requestCode: "5PSaveMWV1",
                                    },
                                    body: {
                                        Clientcode: localStorage.getItem("ClientCode"),
                                        MWname: WatchListname,
                                        ClientLoginType: 1,
                                        Data: [
                                            {
                                                Exch: Watch_exchange,
                                                ExchType: Watch_Exchtype,
                                                ScripCode: Watch_Scripcode,
                                                Action: watchlistAction,
                                            },
                                        ],
                                    },
                                });
                                const requestOptions = {
                                    method: "POST",
                                    credentials: "include",
                                    headers: myHeaders,
                                    body: raw,
                                    redirect: "follow",
                                };
                                const response = await fetch(`${APP_BASE_API_URL}tradeapi/SaveMW`, requestOptions);
                                if (!response.ok) {
                                    throw new Error(`Request failed with status ${response.status}`);
                                }
                                const result = await response.text();
                            }
                            catch (error) {
                                console.error("Error:", error.message);
                            }
                        };
                        if (filterIsEditable(watchdef, Watch_title) == true &&
                            ActiveWatchListSymbols.length <= 50) {
                            if (globalLengthHolder.length < ActiveWatchListSymbols.length) {
                                // Separate script code function
                                function isLastElementPresentInGlobalList(globalList, activeList) {
                                    const lastElementActiveList = activeList[activeList.length - 1];
                                    const numberInActiveList = lastElementActiveList
                                        .split(":")[1]
                                        .split("/")[1];
                                    return globalList.some((item) => {
                                        const numberInGlobalList = item.split(":")[1].split("/")[1];
                                        return numberInGlobalList === numberInActiveList;
                                    });
                                }
                                if (!isLastElementPresentInGlobalList(globalLengthHolder, ActiveWatchListSymbols)) {
                                    const latestArr = ActiveWatchListSymbols.map((str) => {
                                        if (str.includes("searchTrue")) {
                                            return get_New_Symbol(str);
                                        }
                                        else {
                                            return str;
                                        }
                                    });
                                    const lastElem = ActiveWatchListSymbols[ActiveWatchListSymbols.length - 1];
                                    const result = get_New_Symbol(lastElem);
                                    const result_after_ticker = getExch_Exchtype_Scripcode(result);
                                    counter++;
                                    watchlistApi.updateList(activeListid, latestArr);
                                    saveMW("A", Watch_title, result_after_ticker.Exch, result_after_ticker.ScripCode, result_after_ticker.ExchType);
                                }
                                else {
                                    const latestArr = ActiveWatchListSymbols.map((str) => {
                                        if (str.includes("searchTrue")) {
                                            return get_New_Symbol(str);
                                        }
                                        else {
                                            return str;
                                        }
                                    });
                                    watchlistApi.updateList(activeListid, latestArr);
                                }
                            }
                            //this if else is for checking add or delete symbol
                            else {
                                function findMissingValues(a, b) {
                                    const missingValues = a.filter((value) => !b.includes(value));
                                    return missingValues;
                                }
                                let desiredSym = findMissingValues(globalLengthHolder, ActiveWatchListSymbols);
                                let Watch_exchangee;
                                switch (desiredSym[0].split(":")[0]) {
                                    case "N":
                                    case "NSE":
                                    case "nse":
                                        Watch_exchangee = "N";
                                        break;
                                    case "B":
                                    case "BSE":
                                    case "bse":
                                        Watch_exchangee = "B";
                                        break;
                                    default:
                                        Watch_exchangee = "M";
                                        break;
                                }
                                let Watch_Scripcodee = desiredSym[0]
                                    .split(":")[1]
                                    .split("/")[1]
                                    .split("-")[0];
                                let Watch_Exchtypee = desiredSym[0]
                                    .split(":")[1]
                                    .split("/")[1]
                                    .split("-")[1];
                                saveMW("D", Watch_title, Watch_exchangee, Watch_Scripcodee, Watch_Exchtypee);
                            }
                        }
                        //this if else if for watchlist is editable or not
                        else {
                            let Watch_Message;
                            if (filterIsEditable(watchdef, Watch_title) == false) {
                                Watch_Message = "Symbol cannot be added or deleted in Watchlist";
                            }
                            else {
                                Watch_Message = " Max 50 symbols can be added in Watchlist";
                            }
                            var latestArray = globalLengthHolder.map((str) => {
                                if (str.includes("searchTrue")) {
                                    const [type, name, code, Check, Check2] = str
                                        .split(/[:/]/)
                                        .filter(Boolean);
                                    return `${type}:${name}/${code}-${Check2}`;
                                }
                                else {
                                    return str;
                                }
                            });
                            counter++;
                            watchlistApi.updateList(activeListid, latestArray);
                            function openCustomAlert(message) {
                                var overlay = document.getElementById("notification-custom-alert-overlay");
                                var alertMessage = document.getElementById("notification-custom-alert-message");
                                var okButton = document.getElementById("notification-custom-alert-ok");
                                alertMessage.innerText = message;
                                okButton.onclick = function () {
                                    overlay.style.display = "none";
                                };
                                overlay.style.display = "flex";
                            }
                            openCustomAlert(`${Watch_Message} ${Watch_title}`);
                        }
                    }
                    //this if else for counter check
                    else {
                        counter = 0;
                    }
                });
                watchlistApi
                    .onActiveListChanged()
                    .subscribe(null, async function (aa, bb) {
                    const activeListsID = watchlistApi.getActiveListId();
                    const existingListsObject = watchlistApi.getAllLists();
                    const existingListsArray = Object.values(existingListsObject);
                    const WatchactiveList = existingListsArray.find((list) => list.id === activeListsID);
                    if (WatchactiveList) {
                        let Watch_title = WatchactiveList.title;
                        localStorage.setItem("WatchTitle", Watch_title);
                    }
                    //this if else for watchlist title
                    else {
                        console.log("Active List not found in the existingListsArray");
                    }
                });
            }
        }
    }
});
//watchlist name calling api function
async function getDefault() {
    return new Promise(async (resolve, reject) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Ocp-Apim-Subscription-Key", APP_OCP_APIM_KEY);
            var raw = JSON.stringify({
                head: {
                    appName: "5PTRADE",
                    appSource: "09",
                    appVer: "1.0",
                    osName: "Web",
                    key: APP_HEAD_KEY,
                    requestCode: "5PMF4",
                },
                body: {
                    ClientCode: `${localStorage.getItem("ClientCode")}`,
                },
            });
            var requestOptions = {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            const response = await fetch(`${APP_BASE_API_URL}tradeapi/GetMWatchListRVMW1`, requestOptions);
            const result = await response.json();
            let defaultList = result.body.MWName;
            const mwatchNames = defaultList.filter((item) => item.IsSelect !== "N" && item.ScripCount !== 0);
            //  .map(item => item.MwatchName);
            resolve(mwatchNames);
        }
        catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
}
//watchlist symbol calling function
async function getDefaultSymbolNames(Watch) {
    //debugger
    return new Promise(async (resolve, reject) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Ocp-Apim-Subscription-Key", APP_OCP_APIM_KEY);
            //comment UserID and Password for the prod
            // myHeaders.append("UserID", "Hek68PU5r76");
            // myHeaders.append("Password", "D86utK8Mn7");
            var raw = JSON.stringify({
                Clientcode: `${localStorage.getItem("ClientCode")}`,
                MWName: Watch,
                ClientLoginType: "1",
            });
            var requestOptions = {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            const response = await fetch(`${APP_BASE_API_URL}tradeapi/GetNewMarketWatch`, requestOptions);
            const result = await response.json();
            let defaultListsymbol = [];
            let socketToBeConnected = [];
            result.Data.map(async (item, index, array) => {
                let formattedEx;
                switch (item.Exch) {
                    case "N":
                    case "NSE":
                    case "nse":
                        formattedEx = "NSE";
                        break;
                    case "B":
                    case "BSE":
                    case "bse":
                        formattedEx = "BSE";
                        break;
                    default:
                        formattedEx = "MCX";
                        break;
                }
                let fullName;
                if (item.ExchType == "U" || item.ExchType == "D") {
                    fullName = `${formattedEx}:${item.Name.trim() + " " + item.FullName}/${item.ScripCode}-${item.ExchType}`;
                }
                else {
                    fullName = `${formattedEx}:${item.Name.trim()}/${item.ScripCode}-${item.ExchType}`;
                }
                defaultListsymbol.push(fullName);
                if (index == array.length - 1) {
                    resolve(defaultListsymbol);
                }
            });
        }
        catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
}
// async function RealTimeHoldings() {
//   return new Promise(async (resolve, reject) => {
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");
//       myHeaders.append("Ocp-Apim-Subscription-Key", APP_OCP_APIM_KEY);
//       var raw = JSON.stringify({
//         head: {
//           appName: "5PTRADE",
//           appSource: "21",
//           appVer: "1.0",
//           osName: "Web",
//           key: APP_HEAD_KEY,
//           requestCode: "5PGRTHV2",
//         },
//         body: {
//           ClientCode: `${localStorage.getItem("ClientCode")}`,
//         },
//       });
//       var requestOptions = {
//         method: "POST",
//         credentials: "include",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };
//       const response = await fetch(
//         `${APP_BASE_API_URL}tradeapi/GetRealTimeHolding`,
//         requestOptions
//       );
//       const result = await response.json();
//       let defaultList = result.body.RealTimeHoldingData;
//      console.log(defaultList);
//        const defaultListsymbol = [];
//       const socketToBeConnected = [];
//       // result.body.RealTimeHoldingData.map(async (item, index, array) => {
//       //   let formattedEx;
//       //   switch (item.Exch) {
//       //     case "N":
//       //     case "NSE":
//       //     case "nse":
//       //       formattedEx = "NSE";
//       //       break;
//       //     case "B":
//       //     case "BSE":
//       //     case "bse":
//       //       formattedEx = "BSE";
//       //       break;
//       //     default:
//       //       formattedEx = "MCX";
//       //       break;
//       //   }
//       //   let fullName;
//       //   if (item.ExchType == "U" || item.ExchType == "D") {
//       //     fullName = `${formattedEx}:${
//       //       item.Name.trim() + " " + item.FullName
//       //     }/${item.ScripCode}-${item.ExchType}`;
//       //   } else {
//       //     fullName = `${formattedEx}:${item.Name.trim()}/${item.ScripCode}-${
//       //       item.ExchType
//       //     }`;
//       //   }
//       //   // let fullName = `${item.Exch}:${(item.Name).trim()}/${item.ScripCode}/${(item.ShortName).trim()}/${item.ExchType}/searchTrue`;
//       //   // let fullName = `${item.Exch}:${(item.Name).trim()}/${item.ScripCode}-${item.ExchType}`;
//       //   let instrument =
//       //     `${item.Exch}` + "" + `${item.ExchType}` + "" + `${item.ScripCode}`;
//       //   socketToBeConnected.push(instrument);
//       //   defaultListsymbol.push(fullName);
//       //   if (index == array.length - 1) {
//       //     await watchlistSocketConnection(socketToBeConnected);
//       //     resolve(defaultListsymbol);
//       //   }
//       // });
//       // const mwatchNames = defaultList.filter(
//       //   (item) => item.IsSelect !== "N" && item.ScripCount !== 0
//       // );
//       //  .map(item => item.MwatchName);
//       resolve(defaultList);
//     } catch (error) {
//       console.log("error", error);
//       reject(error);
//     }
//   });
// }
// RealTimeHoldings();
if (window.tvWidget.headerReady) {
    window.tvWidget.headerReady().then(function () {
        const reqUrl = new URL(window.location);
        window.tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval, timeframeObj) => {
            if (reqUrl.searchParams.get("isTradeChart") === "true") {
                captureEvent("Top_Menu_Click", {
                    ClientCode: localStorage.getItem("ClientCode"),
                    Action: "Tenure",
                    Selected_Tenure: localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution") == null
                        ? 1
                        : localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution"),
                    Script_Name: sessionStorage.getItem("load_symbols_Tradechart"),
                });
                var lastClose;
                localStorage.setItem("reso", interval);
                var feedLength = localStorage.getItem("length");
            }
            else {
                var params = getUrlParameters(window.location);
                if (params.osName == "Windows" || params.osName == "windows" || params.osName == "TTWEB") {
                    const ts = tvWidget.activeChart().getTimeScale();
                    const width = ts.width();
                    const newBarSpacing = width / 202;
                    ts.setRightOffset(0);
                    ts.setBarSpacing(newBarSpacing);
                }
                else {
                    const ts = tvWidget.activeChart().getTimeScale();
                    const width = ts.width();
                    const newBarSpacing = width / 50;
                    ts.setRightOffset(0);
                    ts.setBarSpacing(newBarSpacing);
                }
                ;
                if (interval == "1D") {
                    var allSym = JSON.parse(sessionStorage.getItem("load_symbols_noTradechart"));
                    let newA = [];
                    for (let i = 0; i < allSym.length; i++) {
                        let matches = allSym[i].split("/")[1];
                        newA.push(matches);
                    }
                    sessionStorage.setItem("layoutScArr_noTradechart", JSON.stringify(newA));
                }
                ;
                Datafeed.urlParameter.searchFlag === "true" ? sessionStorage.setItem("reso" + sessionScripCode, interval) : localStorage.setItem("reso", interval);
                var feedLength = localStorage.getItem("length");
                if (Datafeed.urlParameter.type == "futures" ||
                    Datafeed.urlParameter.type == "options" ||
                    Datafeed.urlParameter.type == "options-curcom" ||
                    Datafeed.urlParameter.type == "future-curcom" ||
                    Datafeed.urlParameter.type == "Future-curcom") {
                    if (["1", "2", "3", "4", "5", "6", "10", "15", "30", "60", "240",].includes(interval)) {
                        if (localStorage.getItem("intraDaydata_noTradechart") > 0 && localStorage.getItem("socket_noTradechart") == "false") {
                            //document.getElementById("error_msg").style.display = "none";
                        }
                    }
                    else if (feedLength == 0 &&
                        localStorage.getItem("socket_noTradechart") == "false") {
                        //document.getElementById("error_msg").style.display = "block";
                    }
                }
                ;
            }
        });
        window.tvWidget
            .activeChart()
            .onChartTypeChanged()
            .subscribe(null, (chartType) => {
            let chart_type;
            switch (chartType) {
                case 0:
                    chart_type = "Bars";
                    break;
                case 1:
                    chart_type = "Candles";
                    break;
                case 9:
                    chart_type = "Hollow Candles";
                    break;
                case 13:
                    chart_type = "Columns";
                    break;
                case 2:
                    chart_type = "Line";
                    break;
                case 14:
                    chart_type = "Line with markers";
                    break;
                case 15:
                    chart_type = "Step Line";
                    break;
                case 3:
                    chart_type = "Area";
                    break;
                case 16:
                    chart_type = "HLC Area";
                    break;
                case 10:
                    chart_type = "Baseline ";
                    break;
                case 12:
                    chart_type = "High-low";
                    break;
                case 8:
                    chart_type = "Heikin-Ashi";
                    break;
                case 4:
                    chart_type = "Renko";
                    break;
                case 7:
                    chart_type = "Line break";
                    break;
                case 5:
                    chart_type = "Kagi";
                    break;
                case 6:
                    chart_type = "Point $ figure";
                    break;
            }
            captureEvent("Top_Menu_Click", {
                ClientCode: localStorage.getItem("ClientCode"),
                Action: "Chart_type",
                Script_Name: sessionStorage.getItem("load_symbols_Tradechart"),
                Selected_Chart: chart_type,
            });
        });
        //Buy and Sell buttons on trading-terminal = false;
        if (reqUrl.searchParams.get("isTradeChart" === "false")) {
            if (reqUrl.searchParams.get("searchFlag") == "true" &&
                reqUrl.searchParams.get("source") == "derivative") {
                //Buy Button
                const BuyButton = tvWidget.createButton();
                BuyButton.classList.add("buyIcon");
                BuyButton.setAttribute("id", "buy-Icon");
                BuyButton.addEventListener("click", async function () {
                    console.log("buy button triggerd");
                    await orderObjectUtilityFunction(await objectForOrderForm(true, false));
                });
                //Sell Button
                const SellButton = tvWidget.createButton();
                SellButton.classList.add("sellIcon");
                SellButton.setAttribute("id", "sell-Icon");
                SellButton.addEventListener("click", async function () {
                    console.log("sell button triggerd");
                    await orderObjectUtilityFunction(await objectForOrderForm(false, false));
                });
                // buy/sell button on canvas
                var parentBuySellDiv = document.createElement("div");
                parentBuySellDiv.setAttribute("id", "parentBuySell");
                parentBuySellDiv.setAttribute("class", "errorCardRendererContainer-Kcyx2XsY");
                var buyBtn = document.createElement("button");
                buyBtn.setAttribute("id", "buyBtn");
                buyBtn.setAttribute("class", "buySellBtn");
                parentBuySellDiv.appendChild(buyBtn);
                window.spanTag_b = document.createElement("SPAN");
                window.spanTag_b.setAttribute("id", "priceValue");
                buyBtn.appendChild(spanTag_b);
                var sellBtn = document.createElement("button");
                sellBtn.setAttribute("id", "sellBtn");
                sellBtn.setAttribute("class", "buySellBtn");
                parentBuySellDiv.appendChild(sellBtn);
                window.spanTag_s = document.createElement("SPAN");
                window.spanTag_s.setAttribute("id", "priceValuee");
                sellBtn.appendChild(spanTag_s);
                try {
                    let dd = document
                        .getElementById("tv_chart_container")
                        .getElementsByTagName("iframe")[0]
                        .contentDocument.getElementsByTagName("body")[0]
                        .querySelector(".chart-gui-wrapper");
                    if (dd) {
                        dd.append(parentBuySellDiv);
                    }
                }
                catch (err) {
                    console.log(err);
                }
                //functionality on ask price button in canvas
                buyBtn.addEventListener("click", async function () {
                    console.log("ask price");
                    const bidAsk_price = buyBtn.children[0].innerHTML;
                    await orderObjectUtilityFunction(await objectForOrderForm(true, true, bidAsk_price));
                });
                //functionality on bid price button in canvas
                sellBtn.addEventListener("click", async function () {
                    const bidAsk_price = sellBtn.children[0].innerHTML;
                    await orderObjectUtilityFunction(await objectForOrderForm(false, true, bidAsk_price));
                    console.log("bid price");
                });
            }
            ;
        }
        //Delete button on header
        const deleteButton = window.tvWidget.createButton();
        deleteButton.setAttribute("title", "Click to reset the chart");
        deleteButton.classList.add("deleteIcon");
        if (themeVal === "night" || themeVal === "dark") {
            deleteButton.classList.add("deleteIconDark");
            deleteButton.classList.remove("deleteIcon");
        }
        else {
            deleteButton.classList.add("deleteIcon");
            deleteButton.classList.remove("deleteIconDark");
        }
        deleteButton.addEventListener("click", function () {
            var params = {
                title: "Are you sure?",
                body: "Clearing your preferences will reset your chart",
                callback: function (res) {
                    if (res) {
                        localStorage.removeItem("studyTemplate_Tradechart");
                        localStorage.removeItem("autoSaveStorage_Tradechart");
                        localStorage.removeItem("lastChartSaved_Tradechart");
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.reload();
                    }
                },
            };
            let indic;
            window.tvWidget.subscribe("study", (event) => {
                indic = `${event.value}`;
            });
            window.tvWidget.showConfirmDialog(params);
            captureEvent("Top_Menu_Click", {
                ClientCode: localStorage.getItem("ClientCode"),
                Action: "Delete_Chart",
                Script_Name: sessionStorage.getItem("load_symbols_Tradechart"),
                Selected_Indicator: indic,
            });
        });
        //Fund button
        let fundsButton;
        if (reqUrl.searchParams.get("isTradeChart" === "true")) {
            fundsButton = window.tvWidget.createButton({ align: "right" });
            fundsButton.id = "funds";
            fundsButton.setAttribute("title", "Funds");
            if (localStorage.getItem(`tradingview.current_theme.name`) === "dark" ||
                `tradingview.current_theme.name` === "") {
                fundsButton.classList.add("fundsIconDark");
            }
            else {
                fundsButton.classList.add("fundsIcon");
            }
            fundsButton.addEventListener("click", () => {
                window.open("https://tradestation.5paisa.com/ssologin?redirect=/ssofund?redirect=https://tv.5paisa.com/", "_blank");
            });
        }
        ;
        //Popout button
        let popOut;
        if (!reqUrl.searchParams.has("ispopOut") && reqUrl.searchParams.get("isTradeChart") === "true") {
            popOut = window.tvWidget.createButton({ align: "left" });
            popOut.id = "popOut";
            popOut.setAttribute("title", "Open in New Tab");
            if (localStorage.getItem(`tradingview.current_theme.name`) === "dark" ||
                `tradingview.current_theme.name` === "") {
                popOut.classList.add("popoutIconDark");
            }
            else {
                popOut.classList.add("popoutIcon");
            }
            popOut.addEventListener("click", () => {
                var url = new URL(window.location);
                url.searchParams.set("searchFlag", "false");
                window.open(`${window.location.href}&ispopOut=true&ispopOutRedirection=true`, "_blank");
            });
        }
        //options chain button
        let optionsChainButton;
        if (reqUrl.searchParams.get("isTradeChart") === "true") {
            optionsChainButton = window.tvWidget.createButton({ align: "left" });
            optionsChainButton.classList.add("optionsChain");
            optionsChainButton.setAttribute("title", "Option Chain");
            if (themeVal === "night" || themeVal === "dark") {
                optionsChainButton.classList.add("optionsChainDark");
                optionsChainButton.classList.remove("optionsChain");
            }
            else {
                optionsChainButton.classList.remove("optionsChainDark");
                optionsChainButton.classList.add("optionsChain");
            }
            optionsChainButton.addEventListener("click", () => {
                let myDivFrmHeader = document.getElementById("myFrameDiv");
                if (myDivFrmHeader == null) {
                    openingOptionChain();
                }
            });
        }
        ;
        let username = localStorage.getItem("ClientName");
        if (username.length > 8) {
            username = username.substring(0, 8) + "...";
        }
        var radioLight = document.getElementById("light");
        var radioDark = document.getElementById("dark");
        let logOut;
        if (reqUrl.searchParams.get("isTradeChart") == "true") {
            logOut = window.tvWidget.createButton({ align: "right" });
            logOut.innerHTML = `<svg width="127" height="50" >
      <path transform="translate(0.1,9) scale(1.5)" d="M9.99951 10.625C11.553 10.625 12.812 9.36562 12.812 7.8125C12.812 6.25938 11.5542 5 9.99951 5C8.44482 5 7.18701 6.25781 7.18701 7.8125C7.18701 9.36719 8.44482 10.625 9.99951 10.625ZM11.2495 12.5H8.74951C6.89248 12.5 5.32998 13.6672 4.69873 15.3012C6.05811 16.6602 7.93311 17.5 9.99951 17.5C12.0659 17.5 13.9409 16.659 15.3003 15.3012C14.6675 13.668 13.105 12.5 11.2495 12.5Z" fill="#E31B54"/>
      <path transform="translate(0.1,9) scale(1.5)" d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM10 5C11.5535 5 12.8125 6.25938 12.8125 7.8125C12.8125 9.36563 11.5547 10.625 10 10.625C8.44687 10.625 7.1875 9.36563 7.1875 7.8125C7.1875 6.25938 8.44531 5 10 5ZM10 17.5C7.93242 17.5 6.05859 16.659 4.69922 15.3012C5.33203 13.668 6.89453 12.5 8.75 12.5H11.25C13.107 12.5 14.6695 13.6672 15.3008 15.3012C13.9414 16.6602 12.0664 17.5 10 17.5Z" fill="#FECCD6"/>
      <text x="40" y="21" font-family="Arial" font-size="13" font-weight="bold" >${username}</text>
      <text x="40" y="37" font-family="Arial" font-size="12" text-anchor-"middle" >${localStorage.getItem("ClientCode")}</text>
      <path transform="translate(115,22)" d="M6.3569 6.8407L10.8524 1.94025C11.0492 1.72522 11.0492 1.37684 10.8524 1.16127C10.6556 0.946243 10.3359 0.946243 10.139 1.16127L6.00025 5.67302L1.86147 1.16181C1.66464 0.946786 1.34494 0.946786 1.14762 1.16181C0.950793 1.37684 0.950793 1.72577 1.14762 1.94079L5.6431 6.84124C5.83789 7.05301 6.16261 7.05301 6.3569 6.8407Z" fill="#0080FF" stroke="#0080FF" stroke-width="0.6"/>
      </svg>`;
            logOut.addEventListener("click", function () {
                if (drop.style.display === "none" || drop.style.display === "") {
                    drop.style.display = "block";
                }
                else {
                    drop.style.display = "none";
                }
            }, { passive: true });
        }
        let drop = document.createElement("div");
        drop.id = "dropdown";
        if (localStorage.getItem(`tradingview.current_theme.name`) === "dark" ||
            `tradingview.current_theme.name` === "") {
            drop.style.background = "#1e222d";
            drop.style.color = "#d1d4dc";
        }
        else {
            drop.style.background = "#fff";
            drop.style.color = "#131722";
        }
        drop.style.position = "absolute";
        drop.style.top = "45px";
        drop.style.right = "10px";
        drop.style.display = "none";
        drop.style.padding = "5px 0";
        drop.style.boxShadow = "0 2px 4px #0003";
        drop.style.width = "160px";
        drop.innerHTML = `
    <div id="themeTray" class="menuitem" style="margin: 0 15px; border-bottom:1px solid #d1d4dc;text-align:center; ">Theme</div>
    <div id="orderTray" class="menuitem" style="margin: 0 15px; border-bottom:1px solid #d1d4dc;text-align:center ">Order Setting</div>
    <button id="logoutTray" class="" style="text-align:center; color:#1570ef;border:1px solid #1570ef; background-color:transparent; margin:10px auto; display:flex;border-radius:2px; min-width:130px; text-align:center;justify-content:center;padding:8px 10px; ">Logout</button>
   `;
        document.getElementById("tv_chart_container").appendChild(drop);
        document
            .getElementById("logoutTray")
            .addEventListener("click", function () {
            var myHeaders = new Headers();
            myHeaders.append("ClientId", "NTk5NDkwMDQ=");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("AppName", "5PTRADE");
            myHeaders.append("ocp-apim-subscription-key", APP_OCP_APIM_KEY);
            myHeaders.append("Authorization", `Bearer ${localStorage
                .getItem("JWTToken")
                .replaceAll("JwtToken=", "")}`);
            var raw = JSON.stringify({
                ClientCode: "JaSMYKpkOb1EV9LnfYJYbg==",
                MachineID: "BM4653-D-039377.loca",
                ServerIP: "192.168.88.41",
                ClientIP: "192.168.88.41",
                // appName: "5PTRADE",
                // appSource: "21",
                // appVer: "1.0",
                // osName: "Web",
                // key: "4dddd59bdab77bace6189d001f96487e",
                // requestCode: "5PGOU",
            });
            var requestOptions = {
                method: "POST",
                credentials: "include",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            fetch(`${APP_BASE_API_URL}tradeapi/Logout`, requestOptions)
                .then((response) => {
                if (response.status === 200) {
                    window.location.replace("https://Invest.5paisa.com/home/logout");
                }
                else {
                    console.error("Logout failed:", error);
                }
            })
                .catch((error) => console.log("error", error));
            document.cookie =
                "5paisacookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                "JwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            clearSessionData(true);
        });
        document.getElementById("themeTray").addEventListener("click", function () {
            drop.style.display = "none";
            document
                .getElementById("closeThemeIcon")
                .addEventListener("click", function () {
                dialog.close();
            });
            const dialog = document.querySelector("#theme-popup");
            let currentTheme = localStorage.getItem(`tradingview.current_theme.name`);
            if (currentTheme == "Light" || currentTheme == "light") {
                radioLight.checked = true;
            }
            else if (currentTheme == "Dark" || currentTheme == "dark") {
                radioDark.checked = true;
            }
            dialog.showModal((radioLight.onclick = () => {
                radioLight.checked = true;
                radioDark.checked = false;
            }), (radioDark.onclick = () => {
                radioDark.checked = true;
                radioLight.checked = false;
            }), (saveTheme.onclick = () => {
                if (radioLight.checked &&
                    localStorage.getItem("tradingview.current_theme.name") !== "light") {
                    prefChanged = true;
                    localStorage.setItem(`tradingview.current_theme.name`, "light");
                    themeVal = localStorage.getItem(`tradingview.current_theme.name`);
                    changeThemeFun(themeVal, tvWidget);
                    optionsChainButton.classList.add("optionsChain");
                    optionsChainButton.classList.remove("optionsChainDark");
                    drop.style.background = "white";
                    drop.style.color = "#131722";
                    document.body.classList.remove("dark-theme");
                    deleteButton.classList.remove("deleteIconDark");
                    fundsButton.classList.remove("fundsIconDark");
                    popOut.classList.remove("popoutIconDark");
                    deleteButton.classList.add("deleteIcon");
                    fundsButton.classList.add("fundsIcon");
                    popOut.classList.add("popoutIcon");
                }
                else if (radioDark.checked &&
                    localStorage.getItem("tradingview.current_theme.name") !== "dark") {
                    prefChanged = true;
                    localStorage.setItem(`tradingview.current_theme.name`, "dark");
                    themeVal = localStorage.getItem("tradingview.current_theme.name");
                    changeThemeFun(themeVal, tvWidget);
                    optionsChainButton.classList.add("optionsChainDark");
                    optionsChainButton.classList.remove("optionsChain");
                    drop.style.background = "#1e222d";
                    drop.style.color = "#d1d4dc";
                    document.body.classList.add("dark-theme");
                    deleteButton.classList.add("deleteIconDark");
                    fundsButton.classList.add("fundsIconDark");
                    popOut.classList.add("popoutIconDark");
                }
                preferences = {
                    themeVal,
                    productVal,
                    defaultClose,
                };
                if (prefChanged === true) {
                    setPreferenceApi(preferences, user);
                    prefChanged = false;
                }
                dialog.close();
            }), (cancelTheme.onclick = () => {
                dialog.close();
            }));
        });
        document.getElementById("orderTray").addEventListener("click", function () {
            drop.style.display = "none";
            let radioMarket = document.getElementById("market");
            let radioLimit = document.getElementById("limit");
            let radioIntraday = document.getElementById("intraday");
            let radioDelivery = document.getElementById("delivery");
            let saveOrder = document.getElementById("saveOrder");
            // console.log(dialog);
            document
                .getElementById("closeOrderIcon")
                .addEventListener("click", function () {
                dialog.close();
            });
            const dialog = document.querySelector("#order-popup");
            let currentProd = localStorage.getItem("productVal");
            let currentClose = localStorage.getItem("defaultClose");
            if (currentClose == "Market") {
                radioMarket.checked = true;
                radioLimit.checked = false;
            }
            else if (currentClose == "Limit") {
                radioMarket.checked = false;
                radioLimit.checked = true;
            }
            if (currentProd == "Delivery") {
                radioDelivery.checked = true;
                radioIntraday.checked = false;
            }
            else if (currentProd == "Intraday") {
                radioDelivery.checked = false;
                radioIntraday.checked = true;
            }
            dialog.showModal((radioMarket.onclick = () => {
                radioMarket.checked = true;
                radioLimit.checked = false;
            }), (radioLimit.onclick = () => {
                radioMarket.checked = false;
                radioLimit.checked = true;
            }), (radioDelivery.onclick = () => {
                radioDelivery.checked = true;
                radioIntraday.checked = false;
            }), (radioIntraday.onclick = () => {
                radioDelivery.checked = false;
                radioIntraday.checked = true;
            }), (saveOrder.onclick = () => {
                if (radioMarket.checked &&
                    localStorage.getItem("defaultClose") !== "Market") {
                    prefChanged = true;
                    localStorage.setItem("defaultClose", radioMarket.value);
                    defaultClose = localStorage.getItem("defaultClose");
                }
                else if (radioLimit.checked &&
                    localStorage.getItem("defaultClose") !== "Limit") {
                    prefChanged = true;
                    localStorage.setItem("defaultClose", radioLimit.value);
                    defaultClose = localStorage.getItem("defaultClose");
                }
                if (radioDelivery.checked &&
                    localStorage.getItem("productVal") !== "Delivery") {
                    prefChanged = true;
                    localStorage.setItem("productVal", radioDelivery.value);
                    productVal = localStorage.getItem("productVal");
                }
                else if (radioIntraday.checked &&
                    localStorage.getItem("productVal") !== "Intraday") {
                    prefChanged = true;
                    localStorage.setItem("productVal", radioIntraday.value);
                    productVal = localStorage.getItem("productVal");
                }
                preferences = {
                    themeVal,
                    productVal,
                    defaultClose,
                };
                if (prefChanged === true) {
                    setPreferenceApi(preferences, user);
                    prefChanged = false;
                }
                dialog.close();
            }), (cancelOrder.onclick = () => {
                dialog.close();
            }));
        });
    });
}
setInterval(() => {
    if (prefChanged === true) {
        setPreferenceApi(preferences, user);
        prefChanged = false;
    }
}, 15000);
