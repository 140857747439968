/** @format */
//@ts-nocheck
import { makeApiRequest, getToken, getUrlParameters, setResolution, setTodaysOpenRate, convertTime12To24, AlterUrlParam, captureEvent, getExch_Exchtype_Scripcode, formateDateForGetTimeScaleMarks, formatDate, get_New_Symbol, sortDataforDepth } from "./helpers";
import { subscribeOnStream, unsubscribeFromStream, } from "./streaming_tv";
import loggly_util from "./loggly-util";
import util from "./util";
import { METHOD_FOR_CHART_SOCKET, OPT_FOR_CHART_SOCKET, SOCKET_SUBSCRIPTION_METHODS } from "./utils/constant";
import { subscribeToData, unsubscribeDataBySubscriber } from "./utils/websocketwrapper";
import { subscribeToDepthData, initDepthSocket } from "./utils/depthwrapper";
const browserName = util.getBrowserName();
//define constants
var param = new URLSearchParams(location.search);
var isTradingTerminal = param.get("isTradeChart");
if (isTradingTerminal == "true") {
    sessionStorage.setItem("sym_search_Tradechart", "false");
}
else {
    sessionStorage.setItem("sym_search_noTradechart", "false");
}
let intervalId = undefined;
var _quotesSubscriptions = new Map();
var sc;
let Data;
var symbolInstance_prev;
var symbolInstance_curr;
var counterFirst = 0;
const lastBarData = new Map();
var token = await getToken(localStorage.TokenResponseTv);
var accessToken = "Bearer " + token.access_token;
var firstLoad = true;
let tempSymbolInfo;
const configurationData = {
    supported_resolutions: [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        30,
        "1H",
        "4H",
        "D",
        "1W",
        "1M",
    ],
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true, //Set this totrue Suppose your datafeed provides server time (unix time). It is used to adjust the price ratio on the time scale.
    // exchanges: ["NSE", "BSE", "MCX"], //An array of exchange names that are supported by your datafeed.
    // symbols_types: [{ name: "All types", value: "Overview" },
    // { name: "futures", value: "futures" },
    // { name: "options", value: "options" }],
};
var urlParameter;
var lastDate;
var searchResults = [];
var expiryDate;
var expDateCnt = 0;
var currentResolution;
var counterFirst = 0;
var intraDaydata = [];
var lengthForIntraDay = 0;
var lenforintraday;
var feedArray = [];
var length = 0;
var len;
var optionType;
var strikePrice;
function removeNumberFromArray(array, numberToRemove) {
    return array.filter((number) => number !== numberToRemove);
}
(() => {
    if (param.has("expiry_date")) {
        expiryDate = param.get("expiry_date");
    }
    if (param.has("option_type") && param.has("strike_price")) {
        optionType = param.get("option_type");
        strikePrice = parseInt(param.get("strike_price"));
    }
})();
var getType = (type, et) => {
    var dividerValue = 100;
    if ((type == "FUTURES" || type == "future-curcom") && et == "U") {
        dividerValue = 10000; //crore
    }
    else if ((type == "OPTIONS" || type == "options-curcom") && et == "U") {
        dividerValue = 10000;
    }
    return dividerValue;
};
//corporate action 
const CorporateActionDataService = (() => {
    let instance;
    function getDate(dateString) {
        if (dateString != undefined) {
            var dateObject;
            if (dateString instanceof Date) {
                dateObject = dateString;
            }
            else {
                dateObject = new Date(dateString.replace(/-/g, "/"));
            }
            return dateObject;
        }
        return null;
    }
    ;
    function createInstance() {
        let cachedMarks = {};
        async function fetchCorporateActionData(symbolInfo, startDate, endDate) {
            let fullString = symbolInfo.ticker;
            let parts = fullString.split(":");
            let exchParam = parts[0];
            let symbolDesc = parts[1].split("/")[0];
            let scripCodePart = parts[1].split("/")[1].split("-")[0];
            async function getCoCode() {
                var _a;
                let url;
                if (exchParam.toLowerCase() === "nse") {
                    url = `https://atlas.5paisa.com/datamart/Equity/Market.svc/MultiIndexGetCodes/nse/?symbol=${symbolDesc}&responsetype=json`;
                }
                else {
                    url = `https://atlas.5paisa.com/datamart/Equity/Market.svc/MultiIndexGetCodes/bse/?bsecode=${scripCodePart}&responsetype=json`;
                }
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw==",
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const api_response = await response.json();
                    // Check if response has the expected structure and co_code exists
                    if (api_response.response &&
                        api_response.response.data &&
                        api_response.response.data.multiindexlist &&
                        api_response.response.data.multiindexlist.multiindex) {
                        const companyDetails = api_response.response.data.multiindexlist.multiindex;
                        // Ensure companyDetails array or object contains the co_code
                        if (Array.isArray(companyDetails)) {
                            return ((_a = companyDetails[2]) === null || _a === void 0 ? void 0 : _a.co_code) || null; // Use optional chaining to avoid accessing undefined
                        }
                        else {
                            return companyDetails.co_code || null; // Fallback for when it's not an array
                        }
                    }
                    else {
                        console.warn("Co_code not found in the response");
                        return null;
                    }
                }
                catch (err) {
                    console.error('Fetch error:', err);
                    return null;
                }
            }
            const symbol_co_code = await getCoCode();
            if (!symbol_co_code) {
                console.warn("No valid co_code found for the symbol.");
                return []; // Return empty array as a fallback
            }
            let date = new Date(startDate * 1000);
            let formattedFromDate = date.toISOString().split('T')[0];
            let date1 = new Date(endDate * 1000);
            let formattedToDate = date1.toISOString().split('T')[0];
            let api_url = `https://atlas.5paisa.com/datamart/Equity/Market.svc/AllCorporateActionData/${symbol_co_code}/${formattedFromDate}/${formattedToDate}?responsetype=json`;
            try {
                const searchRequest = await fetch(api_url, {
                    method: "GET",
                    headers: {
                        Authorization: "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw=="
                    },
                });
                const responseData = await searchRequest.json();
                // Check if the expected data structure exists
                if (responseData.response &&
                    responseData.response.data &&
                    responseData.response.data.AllCorporateActionDataList &&
                    responseData.response.data.AllCorporateActionDataList.AllCorporateActionData) {
                    let corporateActions = responseData.response.data.AllCorporateActionDataList.AllCorporateActionData;
                    if (!Array.isArray(corporateActions)) {
                        corporateActions = [corporateActions]; // Ensure it's an array
                    }
                    let marks = [];
                    corporateActions.forEach((data) => {
                        let tempObj = {};
                        let formatedDateOfApi = formateDateForGetTimeScaleMarks(data.exdate);
                        let utcDate = (new Date(getDate(formatedDateOfApi)).getTime()) / 1000;
                        let formattedExDate = formatDate(data.exdate);
                        let formattedAnnouncementDate = formatDate(data.announcementdate);
                        switch (data.purpose) {
                            case 'Dividend':
                                tempObj.id = Math.random().toString().substr(2, 2);
                                tempObj.time = utcDate;
                                tempObj.color = "Green";
                                tempObj.label = 'D';
                                tempObj.tooltip = [
                                    data.purpose,
                                    "Dividend Amount Per share :" + (data.divamount || 0),
                                    "Announcement Date :" + formattedAnnouncementDate,
                                    "Ex-Date :" + formattedExDate
                                ];
                                marks.push(tempObj);
                                break;
                            case 'Bonus':
                                tempObj.id = Math.random().toString().substr(2, 2);
                                tempObj.time = utcDate;
                                tempObj.color = "Red";
                                tempObj.label = 'B';
                                tempObj.tooltip = [
                                    data.purpose,
                                    "Bonus ratio :" + (data.bonusratio || "N/A"),
                                    "Announcement Date :" + formattedAnnouncementDate,
                                    "Ex-Date :" + formattedExDate
                                ];
                                marks.push(tempObj);
                                break;
                            case 'Rights':
                                tempObj.id = Math.random().toString().substr(2, 2);
                                tempObj.time = utcDate;
                                tempObj.color = "Blue";
                                tempObj.label = 'R';
                                tempObj.tooltip = [
                                    data.purpose,
                                    "Right ratio :" + (data.rightsratio || "N/A"),
                                    "Announcement Date :" + formattedAnnouncementDate,
                                    "Ex-Date :" + formattedExDate
                                ];
                                marks.push(tempObj);
                                break;
                            case 'Split':
                                tempObj.id = Math.random().toString().substr(2, 2);
                                tempObj.time = utcDate;
                                tempObj.color = "Yellow";
                                tempObj.label = 'S';
                                tempObj.tooltip = [
                                    data.purpose,
                                    "Split = " + data.fvbefore + "/" + data.fvafter || "N/A",
                                    "Announcement Date :" + formattedAnnouncementDate,
                                    "Ex-Date :" + formattedExDate
                                ];
                                marks.push(tempObj);
                                break;
                        }
                    });
                    return marks;
                }
            }
            catch (error) {
                console.error('Error fetching corporate action data:', error);
                return [];
            }
        }
        return {
            getCorporateActionMarks: async function (symbolInfo, startDate, endDate) {
                const cacheKey = `${symbolInfo.ticker}-${startDate}-${endDate}`;
                if (!cachedMarks[cacheKey]) {
                    cachedMarks[cacheKey] = await fetchCorporateActionData(symbolInfo, startDate, endDate);
                }
                return cachedMarks[cacheKey];
            }
        };
    }
    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();
function opt(data) {
    const myData = [];
    for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj["symbol"] = data[i].ShortName.trim();
        obj["full_name"] = `${data[i].Exch}:${data[i].Name}/${data[i].ScripCode}/${data[i].ShortName.trim()}/${data[i].ExchType}/${data[i].Instrument}/${data[i].CPType}/${data[i].StrikeRate}/searchTrue`;
        obj["description"] = data[i].FullName;
        obj["exchange"] =
            data[i].Exch == "N" ? "NSE" : data[i].Exch == "B" ? "BSE" : "MCX";
        obj["type"] =
            data[i].Instrument == "INDEX FUT"
                ? "futures"
                : data[i].Instrument == "INDEX OPT"
                    ? "options"
                    : data[i].Instrument == "Commodity"
                        ? "future-curcom"
                        : data[i].Instrument == "FUTSTK"
                            ? "futures"
                            : data[i].Instrument == "FUTCUR"
                                ? "futures"
                                : data[i].Instrument == "OPTCUR"
                                    ? "options"
                                    : data[i].Instrument == "OPTSTK"
                                        ? "options"
                                        : "overview";
        // obj["ticker"] = `${
        //   data[i].Exch == "N" ? "NSE" : data[i].Exch == "B" ? "BSE" : "MCX"
        // }:${data[i].Name}/${data[i].ScripCode}-${data[i].ExchType}`;
        myData.push(obj);
    }
    return myData;
}
async function callingElasticSearch(newInput) {
    var _a;
    try {
        const searchRequest = await makeApiRequest(`https://gateway.5paisa.com/tradeapi/elastic-search-v2`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "ocp-apim-subscription-key": "a4af51382266497bb5464d95fbb2017b",
            },
            body: JSON.stringify({
                search: newInput + "*",
                searchMode: "any",
                filter: "",
                orderby: "SymbolOrder,SearchOrder,Expiry,StrikeRate,CPType,Turnoverdata desc, MarketCapital desc, FullName",
                select: "Exch,ExchType,ExpiryDate,MarketCapital,ScripCode,CPType,Name,FullName,ShortName,Turnoverdata,Instrument,Series,IndexId",
                searchFields: "DisplayName,Name",
                count: true,
                top: 100,
            }),
        });
        searchResults = (_a = searchRequest.value) !== null && _a !== void 0 ? _a : [];
    }
    catch (e) {
        loggly_util.logAdvancedMessage(e, {
            error: e.message,
            APIURL: `https://gateway.5paisa.com/tradeapi/elastic-search-v2`,
            Windowurl: window.location.href,
            Token: JSON.parse(localStorage.TokenResponseTv).access_token,
        });
    }
}
export default {
    //This variable contains all the url parameters data which can be used throughout the library
    urlParameter: getUrlParameters(window.location),
    //This method is used by the Charting Library to get a configuration of your datafeed (e.g. supported resolutions, exchanges and so on).
    //This is the first method of the datafeed that is called.
    onReady: (callback) => {
        urlParameter = getUrlParameters(window.location);
        if (urlParameter == null) {
            console.log("[getBarsParameters]: Get error");
            return;
        }
        //Our datafeed should return this configuration to the Charting Library. Note, that the callback must be called asynchronously.
        setTimeout(() => callback(configurationData), 0);
    },
    //This method is used by the Charting Library to search symbols every time a user types a text in the symbol search box.Changing symbols also works using the symbol search.
    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        var newInput = "";
        for (var i = 0; i < userInput.length; i++) {
            if (userInput[i] == " ") {
                newInput += "*+";
            }
            else {
                newInput += userInput[i];
            }
        }
        if (userInput && userInput.length > 2) {
            await callingElasticSearch(newInput);
        }
        const vp = opt(searchResults);
        onResultReadyCallback(vp);
    },
    //This method is used by the library to retrieve information about a specific symbol(exchange, price scale, full symbol etc.).
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, ErrorCallback) => {
        if (!symbolName.includes(":")) {
            const vpp = opt(searchResults);
            symbolName = vpp[0].full_name;
        }
        counterFirst = 0;
        if (symbolName.includes("searchTrue")) {
            if (isTradingTerminal == "true") {
                sessionStorage.setItem("sym_search_Tradechart", "true");
            }
            else {
                sessionStorage.setItem("sym_search_noTradechart", "true");
            }
            var url = new URL(window.location);
            var ssExch = symbolName.split(":")[0];
            sc = symbolName.split("/")[1];
            var ssExchType = symbolName.split("/")[3];
            var ssType = symbolName.split("/")[4];
            var cpType = symbolName.split("/")[5];
            var ssOptType;
            var search_Symbol;
            if (symbolName.includes("BSE BANKEX")) {
                const ChangeinTicker = get_New_Symbol(symbolName);
                const SymbolfromFunction = getExch_Exchtype_Scripcode(ChangeinTicker);
                search_Symbol = SymbolfromFunction.symbol;
            }
            else {
                search_Symbol = symbolName.split("/")[0].split(":")[1].split(" ")[0];
            }
            var name = ssExch == "M"
                ? ssExch + "" + "CX" + ":" + search_Symbol
                : ssExch + "" + "SE" + ":" + search_Symbol;
            if (ssExchType == "D" && ssType == "INDEX OPT") {
                ssOptType = "options";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("scripCode", sc);
                url.searchParams.set("type", "options");
                url.searchParams.set("option_type", symbolName.split("/")[5]);
                url.searchParams.set("strike_price", symbolName.split("/")[0].split(" ")[5]);
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
            }
            if (ssExchType == "D" && (ssType == "Index" || ssType == "INDICE")) {
                ssOptType = "overview";
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
                if (url.searchParams.has("expiry_date")) {
                    url.searchParams.delete("expiry_date");
                }
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("scripCode", sc);
                url.searchParams.set("type", "overview");
            }
            if (ssExchType == "C" && (ssType == "Index" || ssType == "INDICE")) {
                ssOptType = "overview";
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
                if (url.searchParams.has("expiry_date")) {
                    url.searchParams.delete("expiry_date");
                }
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("scripCode", sc);
                url.searchParams.set("type", "overview");
            }
            else if (ssExchType == "D" && ssType == "INDEX FUT") {
                ssOptType = "futures";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.delete("exchange");
                    url.searchParams.set("exch", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                    url.searchParams.set("exch", "bse");
                }
                url.searchParams.set("type", "futures");
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", sc);
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
            }
            else if (ssExchType == "D" &&
                (ssType == "FUTSTK" || ssType == "FUTIDX")) {
                ssOptType = "futures";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.delete("exchange");
                    url.searchParams.set("exch", "nse");
                }
                else {
                    //url.searchParams.set("exchange", "bse");
                    //new added for index fund
                    url.searchParams.delete("exchange");
                    url.searchParams.set("exch", "bse");
                }
                url.searchParams.set("type", "futures");
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
                url.searchParams.set("scripCode", sc);
            }
            else if (ssExchType == "D" &&
                (ssType == "OPTSTK" || ssType == "OPTIDX")) {
                ssOptType = "futures";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                    url.searchParams.delete("exch");
                }
                else {
                    //url.searchParams.set("exchange", "bse");
                    url.searchParams.set("exchange", "bse");
                    url.searchParams.delete("exch");
                }
                url.searchParams.set("type", "options");
                url.searchParams.set("option_type", symbolName.split("/")[5]);
                url.searchParams.set("strike_price", symbolName.split("/")[0].split(" ")[5]);
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("exchType", symbolName.split("/")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", sc);
            }
            else if (ssExchType == "U" && ssType == "FUTCUR") {
                ssOptType = "future-curcom";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("exchType", "U");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("exchType", "U");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", symbolName.split("/")[2].trim());
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
            }
            else if (ssExchType == "U" && ssType == "OPTCUR") {
                ssOptType = "future-curcom";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("exchType", "U");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
                if (url.searchParams.has("strike_price")) {
                    url.searchParams.delete("strike_price");
                }
                if (url.searchParams.has("option_type")) {
                    url.searchParams.delete("option_type");
                }
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("option_type", symbolName.split("/")[5]);
                url.searchParams.set("strike_price", symbolName.split("/")[0].split(" ")[5]);
                url.searchParams.set("exchType", "U");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", symbolName.split("/")[2].trim());
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
            }
            else if (ssExch == "M" &&
                ssType == "Commodity" &&
                (cpType == "CE" || cpType == "PE")) {
                ssOptType = "future-curcom";
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("option_type", symbolName.split("/")[5]);
                url.searchParams.set("strike_price", symbolName.split("/")[0].split(" ")[5]);
                url.searchParams.set("exchange", "M");
                url.searchParams.set("exchType", "D");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
            }
            else if (ssExch == "M" && ssType == "Commodity") {
                ssOptType = "future-curcom";
                url.searchParams.delete("strike_price");
                url.searchParams.delete("option_type");
                url.searchParams.set("type", "future-curcom");
                url.searchParams.set("exchange", "M");
                url.searchParams.set("exchType", "D");
                url.searchParams.set("expiry_date", symbolName.split("/")[0].split(":")[1].split(" ")[1] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[2] +
                    " " +
                    symbolName.split("/")[0].split(":")[1].split(" ")[3]);
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
            }
            else if (ssExchType == "C") {
                ssOptType = "overview";
                if (symbolName.split(":")[0] == "N") {
                    url.searchParams.set("exchange", "nse");
                }
                else {
                    url.searchParams.set("exchange", "bse");
                }
                url.searchParams.delete("option_type");
                url.searchParams.delete("exch");
                url.searchParams.delete("strike_price");
                url.searchParams.delete("expiry_date");
                url.searchParams.set("type", "overview");
                url.searchParams.set("exchType", "C");
                url.searchParams.set("symbol", search_Symbol);
                url.searchParams.set("scripCode", symbolName.split("/")[1]);
            }
            window.history.pushState({}, "", url);
            var myUrlParameters = getUrlParameters(window.location);
            function postMessageURLparams() {
                let newUrlParam = url.searchParams;
                let name = `${newUrlParam.get("symbol")}`;
                let symbolName = `${newUrlParam.get("symbol")}`;
                if (newUrlParam.get("type") !== "overview") {
                    name += ` ${newUrlParam.get("expiry_date")}`;
                    if (newUrlParam.get("option_type")) {
                        name += ` ${newUrlParam.get("option_type")}`;
                    }
                    if (newUrlParam.get("strike_price")) {
                        name += ` ${newUrlParam.get("strike_price").replaceAll(".00", "")}`;
                    }
                }
                var myObj = {
                    fullName: name,
                    exchange: newUrlParam.has("exchange")
                        ? newUrlParam.get("exchange").split("")[0].toUpperCase()
                        : newUrlParam.get("exch").split("")[0].toUpperCase(),
                    exchangeType: newUrlParam.get("exchType"),
                    symbol: symbolName,
                    scripCode: newUrlParam.get("scripCode"),
                    optionType: newUrlParam.has("option_type")
                        ? newUrlParam.get("option_type")
                        : "",
                    strikePrice: newUrlParam.has("strike_price")
                        ? newUrlParam.get("strike_price")
                        : "",
                    expiry: newUrlParam.has("expiry_date")
                        ? newUrlParam.get("expiry_date")
                        : "",
                    isSearch: true,
                };
                return myObj;
            }
            if (browserName != "FIREFOX") {
                window.parent.postMessage(postMessageURLparams(), window.location.ancestorOrigins[0]);
            }
            else {
                try {
                    function getAncestorOrigins() {
                        const urls = [];
                        let parentWin = window;
                        while (parentWin !== window.top) {
                            if (parentWin.document.referrer) {
                                try {
                                    const url = new URL(parentWin.document.referrer);
                                    urls.push(url.origin);
                                }
                                catch (e) { }
                            }
                            parentWin = parentWin.parent;
                        }
                        return urls;
                    }
                    let pp = getAncestorOrigins();
                    window.parent.postMessage(postMessageURLparams(), pp[0]);
                }
                catch (error) {
                    console.error("Error accessing parent window location:", error);
                }
            }
            const symbolShortName = symbolName.split(":")[1] || symbolName.split("/")[1];
            const [exch, sym] = symbolName.toLowerCase().replace("/", ":").split(":");
            sc = symbolName.split("/")[1];
            let session_time = "0915-1530:23456";
            var newUrlParam = new URL(window.location).searchParams;
            if (newUrlParam.get("type") == "future-curcom" ||
                newUrlParam.get("type") == "options-curcom") {
                session_time = "0900-2355:23456";
                if (newUrlParam.get("exchType").toLowerCase() === "u")
                    session_time = "0900-1700:23456";
            }
            var description;
            if (newUrlParam.has("option_type") &&
                newUrlParam.has("strike_price") &&
                newUrlParam.has("expiry_date")) {
                description = `${symbolShortName
                    .split("/")[2]
                    .toUpperCase()
                    .replace(/\s+/g, "")} ${newUrlParam.get("expiry_date")} ${newUrlParam.get("option_type")} ${parseInt(newUrlParam.get("strike_price"))}`;
            }
            else if (newUrlParam.has("expiry_date")) {
                description = `${symbolShortName
                    .split("/")[2]
                    .toUpperCase()
                    .replace(/\s+/g, "")} ${newUrlParam.get("expiry_date")}`;
            }
            else {
                description = symbolShortName
                    .trim()
                    .split("/")[2]
                    .toUpperCase()
                    .replace(/\s+/g, "");
            }
            var nameToSet;
            if (symbolName.split(":")[0] == "M") {
                nameToSet =
                    (symbolName.split(":")[0] + "CX").toUpperCase() +
                        ":" +
                        symbolName.split("/")[0].trim().split(":")[1] +
                        "/" +
                        sc +
                        "-" +
                        myUrlParameters.exchType;
            }
            else {
                nameToSet =
                    (symbolName.split(":")[0] + "SE").toUpperCase() +
                        ":" +
                        symbolName.split("/")[0].trim().split(":")[1] +
                        "/" +
                        sc +
                        "-" +
                        myUrlParameters.exchType;
            }
            var symbolInfo = {
                name: symbolName.split("/")[0].split(":")[1],
                //ticker: symbolName.split("/")[0].trim() + "/" + sc + "-" + myUrlParameters.exchType,
                ticker: nameToSet,
                description: description,
                type: newUrlParam.get("type").toUpperCase(),
                session: session_time,
                corrections: "1815-1915:20231112;0915-1530:20240120;0915-1000,1130-1230:20240302;0915-1000,1130-1230:20240518",
                session_holidays: "20240122,20240126,20240308,20240325,20240329,20240411,20240501",
                //timezone: "Asia/Kolkata",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta"
                    ? "Asia/Kolkata"
                    : Intl.DateTimeFormat().resolvedOptions().timeZone,
                interval: "D",
                exchange: symbolName.split(":")[0] == "M"
                    ? symbolName.split(":")[0] + "CX"
                    : symbolName.split(":")[0] + "SE",
                minmov: 1,
                pricescale: getType(newUrlParam.get("type"), newUrlParam.get("exchType")),
                has_intraday: true,
                intraday_multipliers: ["1", "2", "3", "4", "5", "10", "15", "30", "60", "240"],
                has_daily: true,
                daily_multipliers: ["1"],
                visible_plots_set: true,
                listed_exchange: symbolName.split(":")[0] == "M"
                    ? symbolName.split(":")[0] + "CX"
                    : symbolName.split(":")[0] + "SE",
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                locale: "in",
                volume_precision: 2,
                data_status: "streaming",
                scripCode: newUrlParam.get("scripCode"),
                exchType: newUrlParam.get("exchType"),
            };
            localStorage.setItem("scriptCode", symbolInfo.scripCode);
            localStorage.setItem("exchange", exch);
            localStorage.setItem("exchType", newUrlParam.exchType);
            localStorage.setItem("strikePrice", newUrlParam.strikePrice);
            localStorage.setItem("type", newUrlParam.type);
            await AlterUrlParam(symbolInfo.ticker);
            if (sessionStorage.getItem("isMultiLayOut") == "true") {
                var getSymLocalData = JSON.parse(sessionStorage.getItem("load_symbols_Tradechart"));
                for (let j = 0; j < getSymLocalData.length; j++) {
                    if (j == sessionStorage.getItem("activeChartChanged_Tradechart")) {
                        getSymLocalData[j] = symbolInfo.ticker;
                    }
                }
                sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(getSymLocalData));
            }
            else {
                let narr = [symbolInfo.ticker];
                sessionStorage.setItem("load_symbols_Tradechart", JSON.stringify(narr));
            }
            setTimeout(function () {
                // onSymbolResolvedCallback(symbolInfo);
                onSymbolResolvedCallback(JSON.parse(JSON.stringify(symbolInfo)));
            }, 0);
            var myUrlParameters = getUrlParameters(window.location);
            if (myUrlParameters.type != "overview") {
                try {
                    let ddd = document
                        .getElementById("tv_chart_container")
                        .getElementsByTagName("iframe")[0]
                        .contentDocument.getElementsByTagName("body")[0]
                        .querySelector(".dateRangeExpanded-BXXUwft2");
                    let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
                    reqDivs.forEach((div) => {
                        if (div.textContent == "1Y" ||
                            div.textContent == "5Y" ||
                            div.textContent == "MAX") {
                            div.style.display = "none";
                        }
                    });
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        else {
            const symbolShortName = symbolName.split(":")[1] || symbolName.split("/")[1];
            const [exch, sym] = symbolName.toLowerCase().replace("/", ":").split(":");
            sc = symbolName.split("/")[1];
            let Marketsession_time = "0915-1530:23456";
            var description;
            let saveReqData;
            if (symbolShortName == "NIFTY50" ||
                symbolShortName == "nifty50" ||
                symbolShortName == "Nifty50") {
                symbolName = symbolName.split(":")[0] + ":" + "NIFTY";
            }
            if (firstLoad) {
                let nameToBePassed = symbolName.includes("/")
                    ? symbolName
                    : symbolName +
                        "/" +
                        urlParameter.scripCode +
                        "-" +
                        urlParameter.exchType;
                saveReqData = await AlterUrlParam(nameToBePassed);
            }
            else {
                if (symbolName.includes("-"))
                    saveReqData = await AlterUrlParam(symbolName);
            }
            let nameForTicker;
            var url = new URL(window.location);
            if (urlParameter.type == "options") {
                nameForTicker =
                    urlParameter.exchange.toUpperCase() +
                        ":" +
                        url.searchParams.get("symbol") +
                        " " +
                        url.searchParams.get("expiry_date") +
                        " " +
                        url.searchParams.get("option_type") +
                        " " +
                        url.searchParams.get("strike_price");
            }
            else if (urlParameter.type == "future" ||
                urlParameter.type == "future-curcom" ||
                urlParameter.type == "options-curcom") {
                nameForTicker =
                    urlParameter.exchange.toUpperCase() +
                        ":" +
                        url.searchParams.get("symbol") +
                        " " +
                        url.searchParams.get("expiry_date");
            }
            else {
                nameForTicker =
                    urlParameter.exchange.toUpperCase() +
                        ":" +
                        url.searchParams.get("symbol");
            }
            if (saveReqData != undefined && saveReqData.InstrumentType == null) {
                if (saveReqData.Exch == "M") {
                    Marketsession_time = "0900-2355:23456";
                    if (saveReqData.ExchType == "U")
                        Marketsession_time = "0900-1700:23456";
                }
                var symbolInfo = {
                    name: url.searchParams.get("symbol") +
                        " " +
                        url.searchParams.get("expiry_date") +
                        " " +
                        url.searchParams.get("option_type") +
                        " " +
                        url.searchParams.get("strike_price"),
                    ticker: nameForTicker,
                    description: symbolName,
                    type: urlParameter.type,
                    session: Marketsession_time,
                    corrections: "1815-1915:20231112;0915-1530:20240120;0915-1000,1130-1230:20240302;0915-1000,1130-1230:20240518",
                    session_holidays: "20240122,20240126,20240308,20240325,20240329,20240411,20240501",
                    // timezone: "Asia/Kolkata",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta"
                        ? "Asia/Kolkata"
                        : Intl.DateTimeFormat().resolvedOptions().timeZone,
                    interval: "D",
                    exchange: symbolName.substring(0, symbolName.indexOf(":")),
                    minmov: 1,
                    pricescale: getType(saveReqData.InstrumentType, saveReqData.ExchType),
                    has_intraday: true,
                    intraday_multipliers: ["1", "2", "3", "4", "5", "10", "15", "30", "60", "240"],
                    has_daily: true,
                    daily_multipliers: ["1"],
                    visible_plots_set: true,
                    listed_exchange: symbolName.substring(0, 3),
                    has_weekly_and_monthly: false,
                    supported_resolutions: configurationData.supported_resolutions,
                    locale: "in",
                    volume_precision: 2,
                    data_status: "streaming",
                    scripCode: urlParameter.scripCode,
                    exchType: urlParameter.exchType,
                    delay: 0,
                };
                tempSymbolInfo = symbolInfo;
                localStorage.setItem("scriptCode", symbolInfo.scripCode);
                localStorage.setItem("exchange", exch);
                setTimeout(function () {
                    onSymbolResolvedCallback(JSON.parse(JSON.stringify(symbolInfo)));
                }, 0);
            }
            else if (saveReqData != undefined) {
                var reqtype;
                if (saveReqData.ExchType == "U" || saveReqData.Exch == "M") {
                    reqtype = "future-curcom";
                    Marketsession_time = "0900-2355:23456";
                    if (saveReqData.ExchType == "U")
                        Marketsession_time = "0900-1700:23456";
                }
                else if (saveReqData.InstrumentType == "INDICE" ||
                    saveReqData.InstrumentType == "EQUITY") {
                    reqtype = "overview";
                }
                else {
                    reqtype = saveReqData.InstrumentType.toLowerCase();
                }
                var symbolInfoName = saveReqData.Exch == "M"
                    ? saveReqData.Exch + "" + "CX" + ":" + saveReqData.Name
                    : saveReqData.Exch + "" + "SE" + ":" + saveReqData.Name;
                var symbolInfo = {
                    name: saveReqData.Name,
                    ticker: symbolInfoName +
                        "/" +
                        saveReqData.ScripCode +
                        "-" +
                        saveReqData.ExchType,
                    description: saveReqData.Name,
                    type: reqtype,
                    session: Marketsession_time,
                    corrections: "1815-1915:20231112;0915-1530:20240120;0915-1000,1130-1230:20240302;0915-1000,1130-1230:20240518",
                    session_holidays: "20240122,20240126,20240308,20240325,20240329,20240411,20240501",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta"
                        ? "Asia/Kolkata"
                        : Intl.DateTimeFormat().resolvedOptions().timeZone,
                    interval: "D",
                    exchange: symbolName.substring(0, symbolName.indexOf(":")),
                    minmov: 1,
                    pricescale: getType(saveReqData.InstrumentType, saveReqData.ExchType),
                    has_intraday: true,
                    intraday_multipliers: ["1", "2", "3", "4", "5", "10", "15", "30", "60", "240"],
                    has_daily: true,
                    daily_multipliers: ["1"],
                    visible_plots_set: true,
                    listed_exchange: symbolName.substring(0, 3),
                    has_weekly_and_monthly: false,
                    supported_resolutions: configurationData.supported_resolutions,
                    locale: "in",
                    volume_precision: 2,
                    data_status: "streaming",
                    scripCode: saveReqData.ScripCode,
                    exchType: saveReqData.ExchType,
                };
                tempSymbolInfo = symbolInfo;
                localStorage.setItem("scriptCode", symbolInfo.scripCode);
                localStorage.setItem("exchange", exch);
                setTimeout(function () {
                    onSymbolResolvedCallback(JSON.parse(JSON.stringify(symbolInfo)));
                }, 0);
                if (sessionStorage.getItem("chartContentFlag") == "false") {
                }
            }
            else {
                var symbolInfo = tempSymbolInfo;
            }
        }
    },
    //This method is used by the charting library to get historical data for the symbol.
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, ErrorCallback) => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (resolution == "1D") {
            symbolInstance_curr = symbolInfo.full_name;
            symbolInstance_prev
                ? undefined
                : (symbolInstance_prev = symbolInfo.full_name);
            if (symbolInstance_curr != symbolInstance_prev) {
                symbolInstance_prev = symbolInstance_curr;
                counterFirst = 0;
            }
        }
        else {
            counterFirst = 0;
        }
        //hiding the timeframe according to the condition
        var myUrlParameters = getUrlParameters(window.location);
        if (myUrlParameters.type != "overview") {
            try {
                if (firstLoad == true) {
                    setTimeout(function () {
                        let ddd = document
                            .getElementById("tv_chart_container")
                            .getElementsByTagName("iframe")[0]
                            .contentDocument.getElementsByTagName("body")[0]
                            .querySelector(".dateRangeExpanded-BXXUwft2");
                        let reqDivs = ddd
                            .querySelectorAll("div")[0]
                            .querySelectorAll("div");
                        reqDivs.forEach((div) => {
                            if (div.textContent == "1Y" ||
                                div.textContent == "5Y" ||
                                div.textContent == "MAX") {
                                div.style.display = "none";
                            }
                        });
                    }, 1000);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            try {
                let ddd = document
                    .getElementById("tv_chart_container")
                    .getElementsByTagName("iframe")[0]
                    .contentDocument.getElementsByTagName("body")[0]
                    .querySelector(".dateRangeExpanded-BXXUwft2");
                let reqDivs = ddd.querySelectorAll("div")[0].querySelectorAll("div");
                reqDivs.forEach((div) => {
                    if (div.textContent == "1Y" ||
                        div.textContent == "5Y" ||
                        div.textContent == "MAX") {
                        div.style.display = "flex";
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        const { from, to, firstDataRequest } = periodParams;
        var bars = [];
        setResolution(resolution);
        var feed = [];
        var exchangeType = symbolInfo.exchType;
        let exchange = symbolInfo.exchange;
        if (exchange.toLowerCase() == "nse" || exchange.toLowerCase() == "n")
            exchange = "N";
        else if (exchange.toLowerCase() == "bse" || exchange.toLowerCase() == "b")
            exchange = "B";
        else {
            exchange = "M";
        }
        const getFormattedDate = (dateObj) => dateObj.toJSON().split("T")[0];
        const getPeriod = (res) => {
            currentResolution = res;
            switch (res) {
                case "1H":
                    return "60m";
                case "240":
                    return "240m";
                case "1W":
                case "1M":
                case "1D":
                    return "1d";
                default:
                    currentResolution = res + "m";
                    return currentResolution;
            }
        };
        let fromDate = new Date(0);
        fromDate.setSeconds(from);
        let toDate = new Date(to * 1000).toISOString();
        toDate = toDate.split("T");
        toDate = toDate[0];
        var period = getPeriod(resolution).toLowerCase();
        var scripCode = symbolInfo.scripCode;
        var payloadObj = null;
        var resolutionArr = ["1D", "1W"];
        ;
        var resolutionInt = 240;
        var indexFundsSymbols = [
            "FINNIFTY",
            "BANKNIFTY",
            "MIDCPNIFTY",
            "BANK NIFTY",
            "NIFTY",
            "NIFTY 50",
            "SENSEX",
            "CNX 500",
            "CNX IT",
            "NIFTY JR",
            "NIFTY 500",
            "NIFTY MIDCAP 100",
            "NIFTY 100",
            "NIFTY IT",
            "NIFTYMID50",
            "BANKEX",
            "BSE 100",
            "BSE 500",
            "BSE MIDCAP",
            "BSE 200",
            "BSE PSU",
            "BSE IPO",
            "BSE BANKEX",
            "BSE CAPITAL GOODS",
            "BSE OIL&GAS",
            "BSE METAL",
            "BSE CONSUMER DURABLE",
            "BSE AUTO",
            "BSE IT",
            "BSE FMCG",
            "BSE HEALTHCARE",
            "Nifty Mid100 Free",
            "Nifty Auto",
            "Nifty Metal",
        ];
        let param = new URLSearchParams(location.search);
        urlParameter = getUrlParameters(window.location);
        var indexSymbols = symbolInfo.exchType == "D"
            ? symbolInfo.full_name.split(" ")[0].split(":")[1]
            : symbolInfo.full_name.split("/")[0].split(":")[1];
        var papiToken = "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw==";
        if (lastDate && !resolutionArr.includes(resolution))
            fromDate = lastDate;
        fromDate = getFormattedDate(fromDate);
        if (indexFundsSymbols.includes(indexSymbols.toUpperCase()) ||
            indexFundsSymbols.includes(indexSymbols.toLowerCase())) {
            resolutionArr = ["1D", "1W", "1M"];
            resolutionInt = 240;
        }
        var getMaxDate = function (dateNum) {
            if (dateNum == null || isNaN(dateNum))
                return;
            var year = +dateNum.toString().substr(0, 4), month = +dateNum.toString().substr(4);
            return new Date(year, month, 0);
        };
        async function papiApi() {
            const description = symbolInfo.description;
            const pattern = /([A-Z]+)\s+(\d+\s+[A-Za-z]+\s+\d+)/;
            const matches = description.match(pattern);
            const symbol = matches[1];
            expiryDate = matches[2];
            expiryDate = expiryDate.replace(/ /g, "-");
            expiryDate = expiryDate.replace(/,/g, "");
            let fullString = symbolInfo.ticker;
            let parts = fullString.split("/");
            let firstPart = parts[0];
            let paramExch = firstPart.split(":");
            let exchParam = paramExch[0];
            //console.log(exchParam,"exchangeeêg");
            var futType = "futstk";
            if (indexFundsSymbols.includes(indexSymbols.toUpperCase()) ||
                indexFundsSymbols.includes(indexSymbols.toLowerCase())) {
                futType = "futidx";
            }
            let derType = futType;
            if (symbolInfo.type === "futures" || symbolInfo.type === "FUTURES")
                derType = futType;
            //debugger;
            try {
                let url = ``;
                if (exchParam.toUpperCase() === "NSE") {
                    url = `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/Get-Derivatives-Quotes/GQ/${expiryDate}/${derType}/${symbol}/all/all/all/desc/1/10?responsetype=json`;
                }
                else {
                    url = `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/BSEGetDerivativesQuotes/GQ/${expiryDate}/${derType}/${symbol}/all/all/all/desc/1/10?responsetype=json`;
                }
                let response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw==",
                    },
                });
                // if (!response.ok) {
                //   throw new Error(`API call failed with status: ${response.status}`);
                // }
                let data = await response.json();
                let dataToday;
                if (exchParam.toUpperCase() === "NSE") {
                    dataToday = data.response.data.getexpirydatelist.getexpirydate[0];
                }
                else {
                    dataToday =
                        data.response.data.FNOStockIdentifierList.FNOStockIdentifier;
                }
                return dataToday;
            }
            catch (e) {
                // loggly_util.logAdvancedMessage(e, {
                //   error: e.message,
                //   APIURL: `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/Get-Derivatives-Quotes/GQ/${expiryDate}/${derType}/${symbol}/all/all/all/desc/1/10?responsetype=json`,
                //   Windowurl: window.location.href,
                //   Token: JSON.parse(localStorage.TokenResponseTv).access_token,
                // });
            }
        }
        async function papiApiOptions() {
            const description = symbolInfo.description;
            const pattern = /([A-Z]+)\s+(\d+\s+[A-Za-z]+\s+\d+)\s+(CE|PE)\s+(\d+(?:\.\d+)?)/;
            const matches = description.match(pattern);
            const symbol = matches[1];
            const optionType = matches[3];
            const strikePrice = matches[4];
            expiryDate = matches[2];
            expiryDate = expiryDate.replace(/ /g, "-");
            expiryDate = expiryDate.replace(/,/g, "");
            let fullString = symbolInfo.ticker;
            let parts = fullString.split("/");
            let firstPart = parts[0];
            let paramExch = firstPart.split(":");
            let exchParam = paramExch[0];
            //console.log(exchParam,"exchangeeêg");
            var otpType = "optstk";
            if (indexFundsSymbols.includes(indexSymbols.toUpperCase()) ||
                indexFundsSymbols.includes(indexSymbols.toLowerCase())) {
                otpType = "optidx";
            }
            let derType = otpType;
            let strk;
            if (symbolInfo.type === "options" || symbolInfo.type === "OPTIONS")
                derType = otpType;
            try {
                if (exchParam == "NSE" || exchParam == "nse") {
                    var response = await fetch(`https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/Get-Derivatives-Quotes/GQ/${expiryDate}/${otpType}/${symbol}/${optionType}/${strikePrice}/all/desc/1/10?responsetype=json`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw==",
                        },
                    })
                        .then((response) => response.json())
                        .then((data) => {
                        if (data.response.data.getexpirydatelist.recordcount > 0) {
                            dataToday =
                                data.response.data.getexpirydatelist.getexpirydate[0];
                        }
                    });
                    return dataToday;
                }
                else {
                    var response = await fetch(`https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/BSEGetDerivativesQuotes/GQ/${expiryDate}/${otpType}/${symbol}/${optionType}/${strikePrice}/all/desc/1/10?responsetype=json`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Basic aW5kaWFpbmZvbGluZVxpaWZsd2ViOkdsYXhvQDEyMw==",
                        },
                    })
                        .then((response) => response.json())
                        .then((data) => {
                        // dataToday = data.response.data.FNOStockIdentifierList.FNOStockIdentifier[0];
                        if (data.response.data.FNOStockIdentifierList.recordcount > 0) {
                            dataToday =
                                data.response.data.FNOStockIdentifierList.FNOStockIdentifier;
                        }
                    });
                    return dataToday;
                }
            }
            catch (e) {
                // loggly_util.logAdvancedMessage(e, {
                //   error: e.message,
                //   APIURL: `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/Get-Derivatives-Quotes/GQ/${expiryDate}/${derType}/${symbol}/${optionType}/${~~parseInt(
                //     strikePrice
                //   )}/all/desc/1/10?responsetype=json`,
                //   Windowurl: window.location.href,
                //   Token: JSON.parse(localStorage.TokenResponseTv).access_token,
                // });
            }
        }
        try {
            var dateParam = fromDate.replace(/-/gi, "");
            dateParam.replace(/-/g, "");
            var dataToday;
            //  debugger;
            if (firstDataRequest)
                dateParam = 0;
            if ((firstDataRequest && firstLoad) || resolution <= resolutionInt) {
                firstLoad = false;
                var url = `https://chartstt.5paisa.com/chart/historicalintradayV1/${exchange}/${exchangeType}/${scripCode}/${period}/` +
                    dateParam; //live
                accessToken = "Bearer " + token.access_token;
                //counterFirst = 0;
            }
            else {
                var otpType = "optstk";
                var futType = "futstk";
                if (indexFundsSymbols.includes(indexSymbols.toUpperCase()) ||
                    indexFundsSymbols.includes(indexSymbols.toLowerCase())) {
                    otpType = "optidx";
                }
                if (indexFundsSymbols.includes(indexSymbols.toUpperCase()) ||
                    indexFundsSymbols.includes(indexSymbols.toLowerCase())) {
                    futType = "futidx";
                }
                if (exchangeType === "D" &&
                    (symbolInfo.type == "futures" || symbolInfo.type == "FUTURES") &&
                    (resolution == "1M" || resolution == "1W" || resolution == "1D")) {
                    if (counterFirst == 0) {
                        papiApi();
                        const description = symbolInfo.description;
                        const pattern = /([A-Z]+)\s+(\d+\s+[A-Za-z]+\s+\d+)/;
                        const matches = description.match(pattern);
                        const symbol = matches[1];
                        expiryDate = matches[2];
                        expiryDate = expiryDate.replace(/ /g, "-");
                        expiryDate = expiryDate.replace(/,/g, "");
                        if (symbolInfo.exchange == "NSE" || symbolInfo.exchange == "nse") {
                            var url = `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/fandohistoricalchartdata/${futType}/${symbol}/${expiryDate}/A/xx/m/3?responsetype=json`;
                        }
                        else {
                            var url = `https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/BSEfandohistoricalchartdata/${futType}/${symbol}/${expiryDate}/A/xx/m/3?responsetype=json`;
                        }
                        accessToken = papiToken;
                        counterFirst++;
                    }
                }
                else if (exchangeType === "D" &&
                    (symbolInfo.type == "options" || symbolInfo.type == "OPTIONS")) {
                    if (counterFirst == 0) {
                        papiApiOptions();
                        const description = symbolInfo.description;
                        const pattern = /([A-Z]+)\s+(\d+\s+[A-Za-z]+\s+\d+)\s+(CE|PE)\s+(\d+(?:\.\d+)?)/;
                        const matches = description.match(pattern);
                        const symbol = matches[1];
                        const optionType = matches[3];
                        const strikePrice = matches[4];
                        expiryDate = matches[2];
                        expiryDate = expiryDate.replace(/ /g, "-");
                        expiryDate = expiryDate.replace(/,/g, "");
                        if (symbolInfo.exchange == "NSE" ||
                            symbolInfo.exchange == "nse" ||
                            symbolInfo.exchange == "N" ||
                            symbolInfo.exchange == "n") {
                            var url = ` https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/fandohistoricalchartdata/${otpType}/${symbol}/${expiryDate}/${optionType}/${strikePrice}/m/3?responsetype=json`;
                        }
                        else {
                            var url = ` https://atlas.5paisa.com/datamart/Derivative/Derivative.svc/BSEfandohistoricalchartdata/${otpType}/${symbol}/${expiryDate}/${optionType}/${strikePrice}/m/3?responsetype=json`;
                        }
                        accessToken = papiToken;
                        counterFirst++;
                    }
                }
                else if (resolutionArr.includes(resolution)) {
                    accessToken = "Bearer " + token.access_token;
                    var url = `https://chartstt.5paisa.com/chart/historicalsummaryV1/${exchange}/${exchangeType}/${scripCode}/${period}?from=${fromDate}&end=${toDate}`; //live
                    counterFirst++;
                    var res = url != undefined
                        ? (_a = (await makeApiRequest(url, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer " + token.access_token,
                            },
                            res: payloadObj,
                        }))) === null || _a === void 0 ? void 0 : _a.data //data for intradayV1 and summary v1 //body for intraday
                        : false;
                    if (res.candles && res.candles.length && res.candles.length > 0) {
                    }
                    else {
                        let fromDate_Object = new Date(fromDate);
                        let toDate_Object = new Date(toDate);
                        fromDate_Object.setDate(fromDate_Object.getDate() - 1);
                        toDate_Object.setDate(toDate_Object.getDate() + 1);
                        fromDate = getFormattedDate(fromDate_Object);
                        toDate = getFormattedDate(toDate_Object);
                        var url = `https://chartstt.5paisa.com/chart/historicalsummaryV1/${exchange}/${exchangeType}/${scripCode}/${period}?from=${fromDate}&end=${toDate}`; //live
                        accessToken = "Bearer " + token.access_token;
                        //counterFirst++
                    }
                }
                expDateCnt = expDateCnt + 1;
            }
            //console.log("data before makeapi");
            var data = url != undefined
                ? await makeApiRequest(url, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: url.includes("historicalsummaryV1")
                            ? "Bearer " + token.access_token
                            : url.includes("historicalintradayV1")
                                ? "Bearer " + token.access_token
                                : papiToken,
                    },
                    data: payloadObj,
                })
                : false;
            if (data.data &&
                data.data.candles.length &&
                data.data.candles.length > 0) {
                // console.log("data candle");
                feed = data.data.candles; // intradayV1 and summary v1
                if (feed.length > 0) {
                    localStorage.setItem("lastRate", JSON.stringify({
                        LastRate: feed[feed.length - 1][4],
                        previousClose: feed[feed.length - 1][4],
                    }));
                }
            }
            else if (data.body && !!data.body.Data) {
                feed = data.body.Data;
                if (feed.length > 0) {
                    localStorage.setItem("lastRate", JSON.stringify({
                        LastRate: feed[feed.length - 1][4],
                        previousClose: feed[feed.length - 1][4],
                    }));
                }
            }
            else if (((_b = data === null || data === void 0 ? void 0 : data.response) === null || _b === void 0 ? void 0 : _b.data) &&
                (((_d = (_c = data.response.data.FNOStockIdentifierList) === null || _c === void 0 ? void 0 : _c.FNOStockIdentifier) === null || _d === void 0 ? void 0 : _d.length) >
                    0 ||
                    ((_e = data.response.data.fandohistoricalchartdatalist) === null || _e === void 0 ? void 0 : _e.recordcount) > 0)) {
                // feed = data.response.data.fandohistoricalchartdatalist.fandohistoricalchartdata;
                feed =
                    ((_f = data.response.data.FNOStockIdentifierList) === null || _f === void 0 ? void 0 : _f.FNOStockIdentifier.length) >
                        0
                        ? data.response.data.FNOStockIdentifierList.FNOStockIdentifier
                        : ((_g = data.response.data.fandohistoricalchartdatalist) === null || _g === void 0 ? void 0 : _g.recordcount) > 0
                            ? data.response.data.fandohistoricalchartdatalist
                                .fandohistoricalchartdata
                            : [];
                if (feed.length > 0) {
                    localStorage.setItem("lastRate", JSON.stringify({
                        LastRate: feed[feed.length - 1].Close,
                        previousClose: feed[feed.length - 1].Close,
                    }));
                }
                ;
                async function getTodaydateFutures() {
                    dataToday = await papiApi();
                    return dataToday;
                }
                async function getTodaydateOptions() {
                    dataToday = await papiApiOptions();
                    return dataToday;
                }
                if (exchangeType === "D" && symbolInfo.type === "futures") {
                    dataToday = await getTodaydateFutures();
                    // console.log("when data is undefined",dataToday);
                }
                if (exchangeType === "D" && symbolInfo.type === "options") {
                    dataToday = await getTodaydateOptions();
                    // console.log("when data is undefined",dataToday);
                }
                if (dataToday) {
                    dataToday.closeprice = dataToday.LTP || dataToday.ltp;
                    dataToday.openprice = dataToday.OpenPrice || dataToday.openprice;
                    dataToday.highprice = dataToday.HighPrice || dataToday.highprice;
                    dataToday.lowprice = dataToday.LowPrice || dataToday.lowprice;
                    dataToday.volume = dataToday.TradedQtyCnt || dataToday.tradedqtyCnt;
                    dataToday.tradedate = dataToday.UpdTime || dataToday.updtime;
                    dataToday.tradedate = dataToday.tradedate.split(" ");
                    dataToday.tradedate = dataToday.tradedate[0] + " 12:00:00 AM";
                    feed.push(dataToday);
                }
                feed.forEach((ele) => {
                    ele.Close = parseFloat(ele.closeprice);
                    ele.Open = parseFloat(ele.openprice);
                    ele.High = parseFloat(ele.highprice);
                    ele.Low = parseFloat(ele.lowprice);
                    ele.Volume = parseFloat(ele.volume);
                    if (ele.Open == 0) {
                        ele.Open = parseFloat(ele.ltp);
                        ele.High = parseFloat(ele.ltp);
                        ele.Low = parseFloat(ele.ltp);
                    }
                    let timeDate = ele.tradedate.split(" ");
                    ele.Date = timeDate[0] + " " + timeDate[1] + " PM";
                });
            }
            if (firstDataRequest && resolution <= resolutionInt && feed.length > 0) {
                const setOpenRate = feed[0][1];
                setTodaysOpenRate(setOpenRate);
            }
            if (["1M", "1D", "1W"].includes(resolution)) {
                feedArray = [].concat(feed);
                len = feedArray.length;
                length = length + len;
            }
            localStorage.setItem("length", length);
            if (resolution != "1M" || resolution != "1D" || resolution != "1W") {
                intraDaydata = [].concat(feed);
                lenforintraday = intraDaydata.length;
                lengthForIntraDay = lengthForIntraDay + lenforintraday;
            }
            localStorage.setItem("intraDaydata", lengthForIntraDay);
            if (feed.length === 0 && firstDataRequest) {
                onHistoryCallback([], { noData: true });
                counterFirst = 0;
                return;
            }
            feed.forEach((bar) => {
                let Open, High, Low, Close, Volume;
                if (typeof bar === "object" && !Array.isArray(bar)) {
                    Open = bar.Open;
                    High = bar.High;
                    Low = bar.Low;
                    Close = bar.Close;
                    Volume = bar.Volume;
                    //console.log("bar2 is an object");
                }
                else {
                    Open = bar[1];
                    High = bar[2];
                    Low = bar[3];
                    Close = bar[4];
                    Volume = bar[5];
                }
                let timeDiffAdj = 0;
                var maxDate;
                let format;
                function convertToEpochTime(dateString) {
                    // Parse the date string using Moment.js with the specified format
                    const momentObj = moment(dateString, "YYYY-MM-DD hh:mm:ss A");
                    // Convert the Moment.js object to epoch time in milliseconds
                    const epochTime = momentObj.valueOf();
                    // Return the epoch time
                    return epochTime;
                }
                function convertToEpochTimeCustom(dateString) {
                    // Parse the date string using Moment.js with the specified format
                    const momentObj = moment(dateString, "M/D/YYYY h:mm:ss A");
                    // Convert the Moment.js object to epoch time in milliseconds
                    const epochTime = momentObj.valueOf();
                    // Return the epoch time
                    return epochTime;
                }
                if (["1D", "1M", "1W"].includes(resolution))
                    timeDiffAdj = 19800000;
                if (symbolInfo.type === "overview" ||
                    symbolInfo.type === "OVERVIEW" ||
                    symbolInfo.type === "future-curcom" ||
                    symbolInfo.type === "FUTURE-CURCOM") {
                    if (symbolInfo.type === "overview" && resolution == "1M") {
                        maxDate = getMaxDate(bar.TradeMonth);
                        maxDate = maxDate.getTime();
                    }
                    else if (symbolInfo.type === "futures" && resolution == "1M") {
                        let tempDateTimeArr = bar.Date.split(" ");
                        let correctedDateFormat = convertTime12To24(tempDateTimeArr[0], tempDateTimeArr[1], tempDateTimeArr[2]);
                        if (correctedDateFormat.includes("T")) {
                            format = correctedDateFormat.split("T");
                            // console.log(format[0]);
                            correctedDateFormat = format;
                        }
                        else {
                            correctedDateFormat = correctedDateFormat.substring(0, 9);
                        }
                        var time = Math.floor(new Date(correctedDateFormat).getTime() + timeDiffAdj);
                    }
                    else if (resolution == "1D" || resolution == "1W") {
                        let tempDateTimeArr;
                        if (typeof bar === "object" && !Array.isArray(bar)) {
                            tempDateTimeArr = new Date(bar.Date.replace(/-/g, "/"));
                        }
                        else {
                            tempDateTimeArr = new Date(bar[0].replace(/-/g, "/"));
                        }
                        //code to convert date to UTC format
                        const utcDate = new Date(tempDateTimeArr - tempDateTimeArr.getTimezoneOffset() * 60000);
                        var time = utcDate.toISOString();
                    }
                    else {
                        let tempDateTimeArr;
                        if (typeof bar === "object" && !Array.isArray(bar)) {
                            tempDateTimeArr = bar.Date.split(" ");
                        }
                        else {
                            tempDateTimeArr = bar[0].split(" ");
                        }
                        let correctedDateFormat = convertTime12To24(tempDateTimeArr[0], tempDateTimeArr[1], tempDateTimeArr[2]);
                        var time = Math.floor(new Date(correctedDateFormat).getTime() + timeDiffAdj);
                    }
                }
                else {
                    if (typeof bar === "object" && !Array.isArray(bar)) {
                        var time = convertToEpochTimeCustom(bar.Date);
                    }
                    else {
                        var time = convertToEpochTime(bar[0]);
                    }
                }
                bars = [
                    ...bars,
                    {
                        time: new Date(time).setSeconds(0, 0),
                        low: parseFloat(Low),
                        high: parseFloat(High),
                        open: parseFloat(Open),
                        close: parseFloat(Close),
                        volume: parseFloat(Volume),
                    },
                ];
            });
            let sortedBar = bars.sort((a, b) => a.time - b.time);
            var unique = (src) => [...new Set(src)];
            sortedBar = unique(sortedBar);
            Data = sortedBar;
            if (firstDataRequest) {
                lastBarData.set(symbolInfo.ticker, {
                    ...sortedBar[sortedBar.length - 1],
                });
            }
            if (!resolutionArr.includes(resolution) &&
                sortedBar &&
                sortedBar.length > 0) {
                lastDate = new Date(sortedBar[0].time);
            }
            else {
                lastDate = undefined;
            }
            if (sortedBar.length > 0) {
                onHistoryCallback(sortedBar, { noData: false });
            }
            else {
                onHistoryCallback([], { noData: true });
                //ErrorCallback("no data available");
                //return;
            }
        }
        catch (error) {
            // loggly_util.logAdvancedMessage(error, {
            //   error: error.message,
            //   APIURL: url,
            //   Windowurl: window.location.href,
            //   Token: JSON.parse(localStorage.TokenResponseTv).access_token,
            // });
            console.log("[getBars]: Get error", error);
            ErrorCallback("no data available");
        }
    },
    //market depth data update
    subscribeDepth: async (symbol, DOMCallback) => {
        initDepthSocket();
        captureEvent("RHS_Menu_Click", {
            Action: "DOM_Click",
        });
        let result = getExch_Exchtype_Scripcode(symbol);
        if (result.Exch === "N" && (result.ExchType === "C" || result.ExchType === "D")) {
            subscribeToDepthData({
                Method: METHOD_FOR_CHART_SOCKET,
                Operation: SOCKET_SUBSCRIPTION_METHODS.DEPTH_20_SERVICE,
                MarketFeedData: [
                    {
                        Exch: result.Exch,
                        ScripCode: result.ScripCode,
                        ExchType: result.ExchType,
                    },
                ],
            }, "DOMPanel" + result.ScripCode, (callbackData) => {
                let DOMdata = [];
                if (callbackData != null) {
                    DOMdata =
                        {
                            asks: sortDataforDepth(callbackData.Details).askPrice,
                            bids: sortDataforDepth(callbackData.Details).bidPrice,
                            snapshot: true,
                        },
                        DOMCallback(DOMdata);
                }
            });
        }
        else {
            async function apiReq(symbol) {
                let DOMData;
                let exchange = symbol.split(":")[0].split("")[0];
                let exchType = symbol.split("-")[1];
                let token = symbol.split("/")[1].split("-")[0];
                let apiResponse = await makeApiRequest("https://gateway.5paisa.com/tradeapi/MarketDepth", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": "a4af51382266497bb5464d95fbb2017b",
                        Authorization: "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
                    },
                    body: JSON.stringify({
                        ScripCode: Number(token),
                        Exch: exchange,
                        ExchType: exchType,
                    }),
                });
                return (DOMData = {
                    asks: apiResponse ? sortDataforDepth(apiResponse.Data.Details).askPrice : [],
                    bids: apiResponse ? sortDataforDepth(apiResponse.Data.Details).bidPrice : [],
                    snapshot: true,
                });
            }
            if (intervalId === undefined) {
                const marketFeed = async () => {
                    // if (intervalId) clearTimeout(intervalId);
                    let data = await apiReq(symbol);
                    DOMCallback(data);
                    intervalId = window.setTimeout(marketFeed, 1000);
                };
                await marketFeed();
            }
        }
    },
    //unsubscribe from update market depth data
    unsubscribeDepth: (subscriberUID) => {
        if (intervalId !== undefined) {
            clearTimeout(intervalId);
            intervalId = undefined;
        }
    },
    getQuotes: async (symbols, QuotesCallback, QuotesErrorCallback) => {
        // let pattern = /^(NSE|BSE|MCX):[A-Z]+$/;
        // symbols = symbols.filter((symbol) => !pattern.test(symbol));
        symbols = symbols.filter((symbol) => symbol.includes("/"));
        //watchlist code
        if ((param.get("isTradechart") == "true")) {
            if (param.get("ispopOut") !== "true") {
                const watchlistApi = await window.tvWidget.watchList();
                const activeListId = watchlistApi.getActiveListId();
                const currentListItems = watchlistApi.getList(activeListId);
                window.previousLengthHolder = currentListItems;
            }
        }
        symbols.forEach((name) => {
            var myCheckInterval = setInterval(async () => {
                let data = [];
                if (name.includes("searchTrue"))
                    name = get_New_Symbol(name);
                let result = getExch_Exchtype_Scripcode(name);
                // console.log(name, result, "Priyansh 30 July");
                subscribeToData({
                    Method: OPT_FOR_CHART_SOCKET,
                    Operation: METHOD_FOR_CHART_SOCKET,
                    MarketFeedData: [
                        {
                            Exch: result.Exch,
                            ScripCode: result.ScripCode,
                            ExchType: result.ExchType,
                        },
                    ],
                }, "GetQuotes" + result.ScripCode, (barData) => {
                    if (barData != null) {
                        let ch = barData.LastRate - barData.PClose;
                        let chp = ((barData.LastRate - barData.PClose) / barData.PClose) * 100;
                        data.push({
                            n: name,
                            s: "ok",
                            v: {
                                ch: ch == undefined ? 0 : Number(ch),
                                chp: chp == undefined ? 0 : Number(chp),
                                lp: barData != null ? barData.LastRate : 0,
                                ask: barData != null ? barData.BidRate : 0,
                                bid: barData != null ? barData.OffRate : 0,
                                spread: (barData != null ? barData.BidRate : 0) !== 0 &&
                                    (barData != null ? barData.OffRate : 0) !== 0
                                    ? (barData != null ? barData.OffRate : 0) -
                                        (barData != null ? barData.BidRate : 0)
                                    : 0,
                                open_price: barData != null ? barData.OpenRate : 0,
                                high_price: barData != null ? barData.High : 0,
                                low_price: barData != null ? barData.Low : 0,
                                prev_close_price: barData != null ? barData.PClose : 0,
                                original_name: name,
                                volume: barData != null ? barData.TotalQty : 0,
                            },
                        });
                        unsubscribeDataBySubscriber("GetQuotes" + result.ScripCode);
                        QuotesCallback(data);
                        clearInterval(myCheckInterval);
                    }
                });
            }, 1000);
        });
    },
    subscribeQuotes: async (symbols, fastSymbols, onRealtimeCallback, listenerGUID) => {
        //  console.log(symbols)
        symbols = symbols.filter((symbol) => symbol.includes("/"));
        // console.log("This is a tribute GD", symbols);
        // let loopData = JSON.parse(sessionStorage.getItem("load_symbols"));
        // var combinedArr = currentListItems.concat(loopData);
        // let concatsymbols = symbols.concat(fastSymbols);
        //let combinedArr = loopData.concat(concatsymbols);
        symbols.forEach((name) => {
            let updateData;
            if (name.includes("searchTrue"))
                name = get_New_Symbol(name);
            let resultSubs = getExch_Exchtype_Scripcode(name);
            // if (resultSubs.ScripCode == "15414") {
            // console.log(resultSubs, "PS");
            // }
            subscribeToData({
                Method: OPT_FOR_CHART_SOCKET,
                Operation: METHOD_FOR_CHART_SOCKET,
                MarketFeedData: [
                    {
                        Exch: resultSubs.Exch,
                        ScripCode: resultSubs.ScripCode,
                        ExchType: resultSubs.ExchType,
                    },
                ],
            }, listenerGUID, (barData) => {
                // if (barData.Token == "429029" && barData != undefined) {
                //   console.log(barData, "I am getting 429029 data");
                // } else if (barData.Token == "428869" && barData != undefined) {
                //   console.log(barData, "I am getting 428869 data");
                // }
                if (barData != null) {
                    var ch = barData.LastRate - barData.PClose;
                    var chp = ((barData.LastRate - barData.PClose) / barData.PClose) * 100;
                    updateData = [
                        {
                            s: "ok",
                            n: name,
                            v: {
                                ask: barData != null ? barData.BidRate : 0,
                                bid: barData != null ? barData.OffRate : 0,
                                ch: ch == undefined ? 0 : Number(ch),
                                chp: chp == undefined ? 0 : Number(chp),
                                lp: barData != null ? barData.LastRate : 0,
                                spread: (barData != null ? barData.BidRate : 0) !== 0 &&
                                    (barData != null ? barData.OffRate : 0) !== 0
                                    ? (barData != null ? barData.OffRate : 0) -
                                        (barData != null ? barData.BidRate : 0)
                                    : 0,
                                open_price: barData != null ? barData.OpenRate : 0,
                                high_price: barData != null ? barData.High : 0,
                                low_price: barData != null ? barData.Low : 0,
                                prev_close_price: barData != null ? barData.PClose : 0,
                                original_name: name,
                                volume: barData != null ? barData.TotalQty : 0,
                            },
                        },
                    ];
                    onRealtimeCallback(updateData);
                }
            });
        });
    },
    //Corporate Action
    getTimescaleMarks: async function (symbolInfo, startDate, endDate, onDataCallback, resolution) {
        try {
            const marks = await CorporateActionDataService.getInstance().getCorporateActionMarks(symbolInfo, startDate, endDate);
            // Use empty array as fallback if marks is undefined or null
            const validMarks = marks || [];
            // Map through validMarks to process data
            const formattedMarks = validMarks.map(mark => {
                return {
                    id: mark.id,
                    time: mark.time,
                    color: mark.color,
                    label: mark.label,
                    tooltip: mark.tooltip // Assuming tooltip is an array
                };
            });
            onDataCallback(formattedMarks);
        }
        catch (error) {
            console.error('Error in getTimescaleMarks:', error);
            onDataCallback([]); // Callback with an empty array in case of error
        }
    },
    unsubscribeQuotes: (listenerGUID) => {
        // console.log("This is a tribute GD unsubscribeQuotes", listenerGUID);
        // unsubscribeDataBySubscriber(listenerGUID);
        clearInterval(_quotesSubscriptions[listenerGUID]);
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastBarData.get(symbolInfo.ticker));
    },
    unsubscribeBars: (subscriberUID) => {
        unsubscribeFromStream(subscriberUID);
    },
};
