const getInternetStatus = ({ setIsInternetConnected, }) => {
    let isConnected = navigator.onLine;
    const onInternetStatusChange = () => {
        // const currStatus = navigator.onLine;
        setIsInternetConnected(navigator.onLine);
        // console.log(
        //   `Internet connection status: ${currStatus ? "online" : "offline"}`
        // );
    };
    window.addEventListener("online", onInternetStatusChange);
    window.addEventListener("offline", onInternetStatusChange);
    onInternetStatusChange();
    return isConnected;
};
export default getInternetStatus;
