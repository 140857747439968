//@ts-nocheck
import { parseFullSymbol, getUrlParameters, checkTimeDiff, } from "./helpers";
import { METHOD_FOR_CHART_SOCKET, OPT_FOR_CHART_SOCKET, } from "./utils/constant";
import { initFeedSocket, subscribeToData } from "./utils/websocketwrapper";
//define constants
let channelToSubscription = new Map();
let subscribedSymbols = new Set();
let channelString = null;
let subcribedSocket = new Map();
let flag = true;
let isConnectionInterval;
let param = getUrlParameters(window.location);
function pushUniqueNumber(array, number, sc, arr) {
    if (array.includes(number)) {
        return false;
    }
    else {
        array.push(number);
        arr.push(sc);
        return true;
    }
}
initFeedSocket();
export const callBackForWebsocket = (data) => {
    var bar1;
    var bar2;
    let openRate;
    var close = parseInt(localStorage.getItem("closeP"));
    var ch = (data.LastRate - close).toFixed(2);
    sessionStorage.setItem("ch", ch);
    var chp = (((data.LastRate - close) / close) * 100).toFixed(2);
    sessionStorage.setItem("chp", chp);
    let tradeTime;
    const isoDateString = data.TickDt;
    const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}\+\d{2}:\d{2}/;
    const match = isoDateString.match(regex);
    try {
        if (match) {
            const [, year, month, day, hour, minute] = match.map(Number);
            tradeTime = new Date(year, month - 1, day, hour, minute, 0);
            tradeTime.setSeconds(0);
            tradeTime.setMilliseconds(0);
            tradeTime = tradeTime.getTime();
        }
    }
    catch (e) {
        console.log(e);
    }
    let subscriptionItem_minute;
    let subscriptionItem_day;
    for (let [key, value] of channelToSubscription) {
        var scFromkey = Number(key.split("~")[3]);
        if (scFromkey == data.Token) {
            for (let i = 0; i < value.length; i++) {
                if (["1", "2", "3", "4", "5", "10", "15", "30", "60", "240"].includes(value[i].resolution)) {
                    subscriptionItem_minute = value[i];
                }
                else {
                    subscriptionItem_day = value[i];
                }
            }
        }
    }
    ;
    if (subscriptionItem_day === undefined &&
        subscriptionItem_minute === undefined) {
        return;
    }
    let lastDailyBar_minute;
    let lastDailyBar_day;
    if (subscriptionItem_minute != undefined &&
        subscriptionItem_minute.lastDailyBar != undefined) {
        lastDailyBar_minute = subscriptionItem_minute.lastDailyBar;
        var nextDailyBarTime_minute = getNextDailyBarTime(lastDailyBar_minute.time);
    }
    if (subscriptionItem_day != undefined &&
        subscriptionItem_day.lastDailyBar != undefined) {
        lastDailyBar_day = subscriptionItem_day.lastDailyBar;
        var nextDailyBarTime_day = getNextDailyBarTime(lastDailyBar_day.time);
    }
    if (subscriptionItem_minute != undefined &&
        lastDailyBar_minute != undefined) {
        if (tradeTime >= nextDailyBarTime_minute) {
            bar1 = {
                time: new Date(tradeTime).setSeconds(0, 0),
                open: data.LastRate,
                high: data.LastRate,
                low: data.LastRate,
                close: data.LastRate,
                volume: data.LastQty,
            };
        }
        else {
            bar1 = {
                ...lastDailyBar_minute,
                open: openRate ? openRate : lastDailyBar_minute.open,
                high: Math.max(lastDailyBar_minute.high, data.LastRate),
                low: Math.min(lastDailyBar_minute.low, data.LastRate),
                close: data.LastRate,
                volume: (lastDailyBar_minute.volume + data.LastQty),
            };
        }
    }
    if (subscriptionItem_day != undefined && lastDailyBar_day != undefined) {
        if (tradeTime >= nextDailyBarTime_day) {
            bar2 = {
                time: new Date(tradeTime).setUTCHours(0, 0, 0),
                open: data.OpenRate,
                high: data.High,
                low: data.Low,
                close: data.LastRate,
                volume: data.TotalQty,
            };
        }
        else {
            bar2 = {
                ...lastDailyBar_day,
                open: openRate ? openRate : lastDailyBar_day.open,
                high: Math.max(lastDailyBar_day.high, data.LastRate),
                low: Math.min(lastDailyBar_day.low, data.LastRate),
                close: data.LastRate,
            };
        }
    }
    if (subscriptionItem_minute != undefined &&
        subscriptionItem_minute.lastDailyBar != undefined) {
        subscriptionItem_minute.lastDailyBar = bar1;
        subscriptionItem_minute.handlers[0].callback(bar1);
    }
    if (subscriptionItem_day != undefined &&
        subscriptionItem_day.lastDailyBar != undefined) {
        if (bar2 != undefined) {
            subscriptionItem_day.lastDailyBar = bar2;
            subscriptionItem_day.handlers[0].callback(bar2);
        }
    }
    checkTimeDiff();
};
var getNextDailyBarTime = (barTime) => {
    const date = new Date(barTime);
    date.setSeconds(date.getSeconds() + 1);
    return date.getTime();
};
function getDate(dateString) {
    //2023-10-30T11:17:13+00:00
    if (dateString != undefined) {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const date = new Date(dateString);
        const day = days[date.getUTCDay()];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        const offset = "+0530";
        var dateObject;
        if (dateString instanceof Date) {
            dateObject = dateString;
        }
        else {
            dateObject = `${day} ${month} ${("0" + date.getUTCDate()).slice(-2)} ${year} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${"0" + "0"} GMT${offset}`;
        }
        return dateObject;
    }
    return null;
}
export var subscribeOnStream = async (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastDailyBar) => {
    //onResetCacheNeededCallback(subscribeUID)
    let symbolToBePassed = symbolInfo.full_name.includes("/")
        ? symbolInfo.full_name.split("/")[0]
        : symbolInfo.full_name;
    const parsedSymbol = parseFullSymbol(symbolToBePassed.replace(/\s/g, ""));
    channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${symbolInfo.scripCode}`;
    const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
    };
    let subscriptionItem;
    let subsItem;
    subsItem = channelToSubscription.get(channelString);
    if (subsItem == undefined)
        subsItem = [];
    //let lastDailyBarr = lastDailyBar.get(symbolInfo.ticker);
    subscriptionItem = {
        subscribeUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };
    subscriptionItem.handlers.push(handler);
    subsItem.push(subscriptionItem);
    subcribedSocket.set(symbolInfo.scripCode, subscribeUID);
    subscribeToData({
        Method: OPT_FOR_CHART_SOCKET,
        Operation: METHOD_FOR_CHART_SOCKET,
        MarketFeedData: [
            {
                Exch: symbolInfo.exchange.split("")[0],
                ExchType: symbolInfo.exchType,
                ScripCode: symbolInfo.scripCode,
            },
        ],
    }, subscribeUID, callBackForWebsocket);
    channelToSubscription.set(channelString, subsItem);
    if (localStorage.getItem("sym_search_Tradechart") == "true") {
        localStorage.setItem("sym_search_Tradechart", "false");
    }
    localStorage.removeItem("layout_symbols");
};
export var unsubscribeFromStream = (subscriberUID) => {
    // find a subscription with id === subscriberUID
    for (const channelString of channelToSubscription.keys()) {
        const subscriptionItem = channelToSubscription.get(channelString);
        // const handlerIndex = subscriptionItem.handlers.findIndex(
        //   handler => handler.id === subscriberUID
        // );
        let handlerIndex;
        for (let i = 0; i < subscriptionItem.length; i++) {
            handlerIndex = subscriptionItem[i].handlers.findIndex((handler) => handler.id === subscriberUID);
        }
        if (handlerIndex !== -1) {
            if (subscriptionItem[0].handlers.length === 0) {
                subscriptionItem[0].handlers.splice(handlerIndex, 1);
                channelToSubscription.delete(channelString);
                break;
            }
        }
    }
};
