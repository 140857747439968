export const ordersPageColumns = [
    // {
    //   label: "Symbol",
    //   formatter: StandardFormatterName.Symbol,
    //   id: CommonAccountManagerColumnId.Symbol,
    //   dataFields: ["symbol", "symbol", "message"],
    // },
    {
        label: "Symbol",
        id: "sym",
        dataFields: ["sym"],
    },
    {
        label: "Side",
        id: "side",
        dataFields: ["side"],
        formatter: "side" /* StandardFormatterName.Side */,
    },
    {
        label: "Type",
        id: "type",
        dataFields: ["type", "parentId", "stopType"],
        formatter: "type" /* StandardFormatterName.Type */,
    },
    {
        label: "Qty",
        alignment: "right",
        id: "qty",
        dataFields: ["qty"],
        help: "Size in lots",
    },
    {
        label: "Limit Price",
        alignment: "right",
        id: "limitPrice",
        dataFields: ["limitPrice"],
        formatter: "formatPrice" /* StandardFormatterName.FormatPrice */,
    },
    {
        label: "Trigger Price",
        alignment: "right",
        id: "stopPrice",
        dataFields: ["stopPrice"],
        formatter: "formatPrice" /* StandardFormatterName.FormatPrice */,
    },
    // {
    // 	label: 'Last',
    // 	alignment: 'right',
    // 	id: 'last',
    // 	dataFields: ['last'],
    // 	formatter: StandardFormatterName.FormatPriceForexSup,
    // 	highlightDiff: true,
    // },
    // {
    // 	label: 'Execution',
    // 	id: 'execution',
    // 	dataFields: ['execution'],
    // },
    {
        label: "Exchange",
        id: "exchange",
        dataFields: ["exchange"],
    },
    {
        label: "Product",
        id: "product",
        dataFields: ["product"],
    },
    {
        label: "Time",
        id: "time",
        dataFields: ["time"],
    },
    {
        label: "TradedQty",
        id: "tradedqty",
        dataFields: ["tradedqty"],
    },
    {
        label: "PendingQty",
        id: "pendingqty",
        dataFields: ["pendingqty"],
    },
    {
        label: "Validity",
        id: "durat",
        dataFields: ["durat"],
    },
    {
        label: "Status",
        id: "status",
        dataFields: ["status"],
        formatter: "status" /* StandardFormatterName.Status */,
        supportedStatusFilters: [0 /* OrderStatusFilter.All */],
    },
    {
        label: "BrokerOrderID",
        id: "brokerorderid",
        dataFields: ["brokerorderid"],
    },
    {
        label: "ExchOrderID",
        id: "exchangeorderid",
        dataFields: ["exchangeorderid"],
    },
    {
        label: "Message",
        id: "msg",
        dataFields: ["msg"],
    },
    // {
    // 	label: 'Order id',
    // 	id: 'id',
    // 	dataFields: ['id'],
    // },
];
export const positionsPageColumns = [
    // {
    //   label: "Symbol",
    //   formatter: StandardFormatterName.Symbol,
    //   id: "symbol",
    //   dataFields: ["symbol", "symbol"],
    //   notHideable: true,
    // },
    {
        label: "Symbol",
        id: "possym",
        dataFields: ["possym"],
    },
    {
        label: "Exchange",
        // alignment: "left",
        id: "exchange",
        dataFields: ["exchange"],
    },
    {
        label: "Buy/Sell",
        id: "side",
        dataFields: ["side"],
        formatter: "side" /* StandardFormatterName.Side */,
    },
    {
        label: "Net Qty.",
        // alignment: "right",
        id: "qty",
        dataFields: ["qty"],
        help: "Size in lots",
    },
    {
        label: "Product",
        // alignment: "right",
        id: "product",
        dataFields: ["product"],
    },
    {
        label: "Avg Price",
        // alignment: "right",
        id: "avgPrice",
        dataFields: ["avgPrice"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Unrealized P/L",
        // alignment: "right",
        id: "upl",
        dataFields: ["upl"],
        formatter: "profit" /* StandardFormatterName.Profit */,
    },
    // {
    //   label: "Stop Loss",
    //   alignment: "right",
    //   id: "stopLoss",
    //   dataFields: ["stopLoss"],
    // },
    {
        label: "Realized P/L",
        // alignment: "right",
        id: "rpl",
        dataFields: ["rpl"],
        formatter: "profit" /* StandardFormatterName.Profit */,
    },
    {
        label: "Last Rate",
        // alignment: "right",
        id: "last",
        dataFields: ["last"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
        highlightDiff: true,
    },
];
export const accountSummaryColumns = [
    {
        label: "Net Available Balance",
        // alignment: "right",
        id: "balance",
        dataFields: ["balance"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Ledger Balance",
        // alignment: "right",
        id: "lbalance",
        dataFields: ["lbalance"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Funds Added",
        // alignment: "right",
        id: "funds_added",
        dataFields: ["funds_added"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Funds Withdrawn",
        // alignment: "right",
        id: "funds_withdrawn",
        dataFields: ["funds_withdrawn"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Collateral",
        // alignment: "right",
        id: "coll",
        dataFields: ["coll"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
        // notSortable: true,
    },
    {
        label: "Margin Utilized",
        // alignment: "right",
        id: "muti",
        dataFields: ["muti"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
        // notSortable: true,
    },
];
export const holdingsColumns = [
    {
        label: "Stock Name",
        // alignment: "right",
        id: "stockname",
        dataFields: ["stockname"],
        //formatter: StandardFormatterName.Fixed,
    },
    {
        label: "Quantity",
        // alignment: "right",
        id: "quantity_vol",
        dataFields: ["quantity_vol"],
        //  formatter: StandardFormatterName.Fixed,
    },
    {
        label: "Average Price",
        // alignment: "right",
        id: "average_price",
        dataFields: ["average_price"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Last Traded Price",
        // alignment: "right",
        id: "last_traded_price",
        dataFields: ["last_traded_price"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
    },
    {
        label: "Investment",
        // alignment: "right",
        id: "investment",
        dataFields: ["investment"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
        // notSortable: true,
    },
    {
        label: "Current Value",
        // alignment: "right",
        id: "current_value",
        dataFields: ["current_value"],
        formatter: "fixed" /* StandardFormatterName.Fixed */,
        // notSortable: true,
    },
    {
        label: "Returns",
        // alignment: "right",
        id: "returns",
        dataFields: ["returns"],
        formatter: "profit" /* StandardFormatterName.Profit */,
        // notSortable: true,
    },
    {
        label: "Returns in Percentage",
        // alignment: "right",
        id: "returns_percent",
        dataFields: ["returns_percent"],
        formatter: "profit" /* StandardFormatterName.Profit */,
        // notSortable: true,
    },
];
export const closed_positionsPageColumns = [
    // {
    //   label: "Symbol",
    //   formatter: StandardFormatterName.Symbol,
    //   id: "symbol",
    //   dataFields: ["symbol", "symbol"],
    //   notHideable: true,
    // },
    {
        label: "Symbol",
        id: "possym",
        dataFields: ["possym"],
        notHideable: true,
    },
    {
        label: "Exchange",
        // alignment: "left",
        id: "exchange",
        dataFields: ["exchange"],
    },
    {
        label: "Buy/Sell",
        id: "side",
        dataFields: ["side"],
        // formatter: StandardFormatterName.Side,
    },
    {
        label: "Buy Qty.",
        // alignment: "right",
        id: "bqty",
        dataFields: ["bqty"],
    },
    {
        label: "Buy Avg. Price",
        // alignment: "right",
        id: "bap",
        dataFields: ["bap"],
    },
    {
        label: "Sell Qty.",
        // alignment: "right",
        id: "sqty",
        dataFields: ["sqty"],
        // formatter: StandardFormatterName.Fixed,
    },
    {
        label: "Sell Avg. Price",
        // alignment: "right",
        id: "sap",
        dataFields: ["sap"],
        // formatter: StandardFormatterName.Profit,
    },
    {
        label: "Product",
        // alignment: "right",
        id: "product",
        dataFields: ["product"],
    },
    {
        label: "Realized P/L",
        // alignment: "right",
        id: "rpl",
        dataFields: ["rpl"],
        formatter: "profit" /* StandardFormatterName.Profit */,
    },
];
