import util from "./util";
import moment from "moment";
const osName = util.checkAndGetOsName();
const browserName = util.getBrowserName();
const source = "CHARTS";
const chartType = "TradingViewNewChart";
// const url = new URL(window.location);
const loginId = localStorage.getItem("ClientCode");
function logSimpleMessage(message) {
    if (window._LTracker) {
        console.table(message);
        window._LTracker.push(message);
    }
}
export default {
    checkLogglyInitialization: () => {
        if (window._LTracker) {
            window._LTracker.push({
                logglyKey: "ad9ff009-44b9-4ab1-8caa-90a4280b08ce",
                sendConsoleErrors: true,
                tag: "Charts",
            });
        }
    },
    logAdvancedMessage: (connectionStatus, otherParam) => {
        let otherParam_temp = {};
        if (otherParam) {
            otherParam_temp = { ...otherParam };
        }
        const message = {
            source,
            chartType,
            clientCode: loginId,
            method: connectionStatus,
            osName,
            browserName,
            time: Date.now(),
            ...otherParam_temp,
        };
        logSimpleMessage(message);
    },
    getSocketLogMessage: (clientCode, connectionStatus, Script_Code, Script_Name, Chart_Source) => {
        return {
            source: "CHARTS",
            clientCode: clientCode,
            method: connectionStatus,
            osName,
            browserName,
            time: moment().format("dddd, Do MMMM YYYY h:mm:ss a"),
            scriptCode: Script_Code,
            scriptName: Script_Name,
            chartSource: Chart_Source,
        };
    },
};
