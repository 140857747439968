//@ts-nocheck
import { checkDevLogin, checkLogin } from "./apis";
import { initializeFireBaseAppAndRemoteConfig } from "../firebase-config";
const devLogin = async () => {
    var _a;
    const response = await checkDevLogin(
    // "JaSMYKpkOb1EV9LnfYJYbg==",//  For Ganga Saran ID
    // "pL6ci9sOb19zXdVeXM247A==", // For 52715111
    "szTHEHY+9MclcCxksEB6eA==", // 50000091 Inder Arora
    "+UypOWasDL/DK/5bNATAkA==", // Must be same for All Passwod for 50000091 Inder Arora
    // "G/4txWu/ryzebd+Gq7EXNg==", // Must be same for All Passwod for 52715111 and Ganga Saran
    "YAftqUV3rrXnbuCkD9Z+LA==" // Must be same for All 01011990 DOB
    );
    if (((_a = response === null || response === void 0 ? void 0 : response.body) === null || _a === void 0 ? void 0 : _a.Status) == 0) {
        const obj_collection = {
            ClientCode: response.body.ClientCode,
            ClientName: response.body.ClientName,
            JWTToken: response.head._5Pjwt.trim(),
            Cookie: response.head._5Pcookie.trim(),
            LoginTime: Date.now(),
        };
        //for writing in local storage
        for (const key in obj_collection) {
            //console.log(`${key}: ${obj_collection[key]}`);
            localStorage.setItem(key, obj_collection[key]);
        }
    }
    else {
        //handle accordinglly
    }
    //console.log(213231);
};
const isDomainValid = () => {
    let domainName = window.location.hostname;
    //if(domainName)
    if (domainName === "localhost") {
        return false;
    }
    else if (domainName === "dev.5paisa.in") {
        devLogin();
        return false;
    }
    // if (domainName === "localhost" || domainName === "dev.5paisa.in") {
    //   return false;
    // }
    return true;
};
// const checkValidLogin = () => {
//   const loginTime = localStorage.getItem("LoginTime");
//   const clientCode = localStorage.getItem("ClientCode");
//   const jwtToken = localStorage.getItem("JWTToken");
//   const cookie = localStorage.getItem("Cookie");
//   // You can get url_string from window.location.href if you want to work with
//   // the URL of the current page
//   const url_string = window.location.href;
//   const url = new URL(url_string);
//   const loginParam = url.searchParams.get("isLoggedIn");
//   if (loginTime) {
//     const hr_diff = (Date.now() - loginTime) / 1000 / 60 / 60;
//     if (
//       hr_diff > 8 ||
//       !clientCode ||
//       !jwtToken ||
//       !cookie ||
//       loginParam !== "true"
//     ) {
//       return false;
//     } else {
//       return true;
//     }
//   } else {
//     return false;
//   }
// };
export const checkLoginTradestation = async () => {
    var _a, _b;
    try {
        // const keydata = await initializeFireBaseAppAndRemoteConfig();
        //console.log(keydata);
        if (!isDomainValid()) {
            return;
        }
        // if (checkValidLogin()) {
        //   return;
        // }
        //setIsChecking(true);
        const response = await checkLogin();
        //const response = { data: { ...getLoginPass } };
        if (((_a = response === null || response === void 0 ? void 0 : response.body) === null || _a === void 0 ? void 0 : _a.Status) == 0) {
            const obj_collection = {
                ClientCode: response.body.ClientCode,
                ClientName: response.body.ClientName,
                JWTToken: response.head._5Pjwt.trim(),
                Cookie: response.head._5Pcookie.trim(),
                LoginTime: Date.now(),
            };
            //for writing in local storage
            for (const key in obj_collection) {
                localStorage.setItem(key, String(obj_collection[key]));
            }
            //logic to implement
            //load default params and execute the login
            //query param trasactionstatus
            const urlObject = new URL(window.location.href);
            const searchParams = urlObject.searchParams;
            if (searchParams.has("TrasactionStatus")) {
                window.location.replace(`https://tv.5paisa.com/index.html?type=overview&exchange=nse&exchType=C&symbol=NIFTY&scripCode=999920000&isTradeChart=true&searchFlag=true`);
            }
            else if (window.location.href === "https://tv.5paisa.com/") {
                window.location.replace(`https://tv.5paisa.com/index.html?type=overview&exchange=nse&exchType=C&symbol=NIFTY&scripCode=999920000&isTradeChart=true&searchFlag=true`);
            }
            else if (window.location.href === "https://tv-pre.5paisa.com/" || window.location.origin === "https://tradechart-uat.5paisa.com") {
                window.location.replace(`${window.location.origin}${window.location.pathname}?type=overview&exchange=nse&exchType=C&symbol=NIFTY&scripCode=999920000&isTradeChart=true&searchFlag=true`);
            }
            else if (window.location.href.includes("theme") &&
                window.location.origin === "https://tv.5paisa.com") {
                if (searchParams.size < 2) {
                    window.location.replace(`https://tv.5paisa.com/index.html?type=overview&exchange=nse&exchType=C&symbol=NIFTY&scripCode=999920000&isTradeChart=true&searchFlag=true&theme=${searchParams.get("theme")}`);
                }
                else
                    return;
            }
            else if (window.location.href.includes("theme") &&
                window.location.origin === "https://tv-pre.5paisa.com" || window.location.origin === "https://tradechart-uat.5paisa.com") {
                if (searchParams.size < 2) {
                    window.location.replace(`${window.location.origin}${window.location.pathname}?type=overview&exchange=nse&exchType=C&symbol=NIFTY&scripCode=999920000&isTradeChart=true&searchFlag=true&theme=${searchParams.get("theme")}`);
                }
                else
                    return;
            }
            //   window.location.reload();
        }
        else if (((_b = response === null || response === void 0 ? void 0 : response.body) === null || _b === void 0 ? void 0 : _b.Status) == 9) {
            initializeFireBaseAppAndRemoteConfig();
        }
        else {
            console.log(response, "API Login");
        }
    }
    catch (error) {
        if (error instanceof Error) {
        }
        else {
            console.log("An unexpected error occurred.");
        }
    }
    finally {
    }
};
