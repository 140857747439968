//@ts-nocheck
// interface Window {
//   clevertap: any;
// }
import { GET_PREFERENCE_BASE_URL, POST_PREFERENCE_BASE_URL, } from "./utils/constant";
import { getConfig } from "./config/config";
import util from "./util";
const browserName = util.getBrowserName();
var resolution = "5";
var openRate;
let lastRecdDataTime = new Date().getTime();
let lastActiveTabState = null;
let scripInfo_cache_value = {};
const { APP_BASE_API_URL, APP_OCP_APIM_KEY, APP_HEAD_KEY, APP_HEAD_REQUEST_CODE, } = getConfig();
export var makeApiRequest = async (path, options) => {
    try {
        const response = await fetch(`${path}`, options);
        return response.json();
    }
    catch (error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
};
export var getResolution = () => {
    return resolution;
};
export var setResolution = (reso) => {
    resolution = reso;
};
export var setTodaysOpenRate = (openRateVal) => {
    openRate = openRateVal;
};
//require for markers
export function formateDateForGetTimeScaleMarks(dateString) {
    const originalDateString = dateString;
    const originalDate = new Date(originalDateString);
    // Set the hours, minutes, and seconds to 09:30:00 AM
    originalDate.setHours(9); // Set hours to 9 AM
    originalDate.setMinutes(15); // Set minutes to 30
    originalDate.setSeconds(0); // Set seconds to 0
    // Format the updated date into a string
    const updatedDateString = originalDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });
    updatedDateString.replace(/,/g, '');
    return updatedDateString;
}
export function formatDate(date) {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero-based
    const year = d.getFullYear();
    // Add leading zeros to day and month if needed
    if (day < 10)
        day = '0' + day;
    if (month < 10)
        month = '0' + month;
    return `${month}/${day}/${year}`;
}
export const checkIsEmpty = (inputValue) => {
    let returnValue = false;
    // '"string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"'
    if (inputValue == undefined) {
        returnValue = true;
    }
    else {
        if (typeof inputValue === "string") {
            returnValue = inputValue.length == 0;
        }
        else if (typeof inputValue === "number" ||
            typeof inputValue === "bigint") {
        }
        else if (typeof inputValue === "boolean") {
            returnValue = inputValue;
        }
        else if (typeof inputValue === "undefined") {
            returnValue = true;
        }
        else if (typeof inputValue === "object") {
            if (Array.isArray(inputValue)) {
                returnValue = inputValue.length == 0;
            }
        }
    }
    return returnValue;
};
export var getTodaysOpenRate = () => {
    return openRate;
};
export var parseFullSymbol = (fullSymbol) => {
    fullSymbol = fullSymbol.replace("-", "");
    fullSymbol = fullSymbol.replace(".", "");
    fullSymbol = fullSymbol.replace("&", "");
    const match1 = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
    const match2 = fullSymbol.match(/^(\w+):(\w+)$/);
    if (!match1 && !match2) {
        return null;
    }
    if (match1) {
        return {
            exchange: match1[1],
            fromSymbol: match1[2],
            toSymbol: match1[3],
        };
    }
    else if (match2) {
        return {
            exchange: match2[1],
            fromSymbol: match2[2],
            toSymbol: null,
        };
    }
};
export const uintToJson = (uintArray) => {
    const encodedString = String.fromCharCode.apply(null, Array.from(uintArray));
    const decodedString = decodeURIComponent(escape(atob(encodedString)));
    return JSON.parse(decodedString);
};
export const jsonToUint = (inputValue) => {
    const string = JSON.stringify(inputValue);
    return stringToUint(string);
};
export const stringToUint = (inputValue) => {
    const string = btoa(unescape(encodeURIComponent(inputValue)));
    const charList = string.split("");
    const uintArray = [];
    for (let i = 0; i < charList.length; i++) {
        uintArray.push(charList[i].charCodeAt(0));
    }
    return new Uint8Array(uintArray);
};
export var getToken = async (token) => {
    //if(token!==undefined || token!=="undefined") token = JSON.parse(token);
    if (token && token !== "undefined") {
        token = JSON.parse(token);
    }
    var authKeyExpired = false;
    if (token && token !== "undefined") {
        authkey = token;
        if (new Date(parseInt(authkey.expires_on) * 1000) < new Date()) {
            authKeyExpired = true;
        }
    }
    if (!authkey || authKeyExpired) {
        var authkey = "";
        var settings = {
            async: false,
            crossDomain: true,
            method: "POST",
            headers: {
                "ocp-apim-subscription-key": "d396f0ca354f4a08b4c226343ae3ed1b",
            },
        };
        const data = await makeApiRequest(`https://dataservice.5paisa.com/TokenService/ChartToken`, settings);
        if ((data.Response && data.Response === "Error") || data.length === 0) {
            onHistoryCallback([], {
                noData: true,
            });
            return;
        }
        localStorage.TokenResponseTv = JSON.stringify(data);
        authkey = data;
    }
    return authkey;
};
export var getUrlParameters = (location) => {
    let expiryDate = "";
    let strikePrice = "";
    const params = new URLSearchParams(location.search);
    if (params.has("expiry_date"))
        expiryDate = params.get("expiry_date");
    if (params.has("strike_price"))
        strikePrice = params.get("strike_price");
    //const exch = params.get('type')=="futures"?params.get('exch'):params.get('exchange');
    var exch;
    if (params.get("type") == "futures") {
        if (params.has("exch")) {
            exch = params.get("exch");
        }
        else {
            exch = params.get("exchange");
        }
    }
    else {
        exch = exch = params.has("exch")
            ? params.get("exch")
            : params.get("exchange");
    }
    if (location && params) {
        if (exch &&
            params.has("exchType") &&
            params.has("scripCode") &&
            params.has("symbol") &&
            params.has("type") &&
            params.has("isTradeChart")) {
            return {
                exchange: exch.toUpperCase(),
                fullname: `${exch.toUpperCase()}:${params.get("symbol").toUpperCase()}`,
                symbol: params.has("expiry_date")
                    ? params.get("symbol").toUpperCase() + " " + params.get("expiry_date")
                    : params.get("symbol").toUpperCase() +
                        " " +
                        expiryDate +
                        " " +
                        strikePrice,
                watchList: exch.toUpperCase().charAt(0) +
                    params.get("exchType").toUpperCase() +
                    params.get("scripCode"),
                tsym: null,
                toTs: null,
                limit: 2000,
                type: params.get("type").toLowerCase(),
                exchType: params.get("exchType").toUpperCase(),
                scripCode: params.get("scripCode"),
                theme: params.get("theme")
                    ? params.get("theme").toLowerCase() === "dark" ||
                        params.get("theme").toLowerCase() === "night"
                        ? "dark"
                        : "light"
                    : "light",
                redirectionTv: params.get("redirectionTv"),
                source: params.get("source"),
                ispopOut: params.get("ispopOut"),
                isTradeChart: params.get("isTradeChart"),
                osName: params.get('osName'),
            };
        }
    }
    return null;
};
export var setParam = (key, value) => {
    let url = new URLSearchParams(url.search.slice(1));
    if (url.searchParams.has(key)) {
        url.searchParams.set(key, value);
    }
    else {
        url.searchParams.append(key, value);
    }
};
export var convertTime12To24 = (date, time, AMPM) => {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var secondes = Number(time.match(/:(\d+)/)[1]);
    if (AMPM === "PM" && hours < 12)
        hours = hours + 12;
    if (AMPM === "AM" && hours === 12)
        hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    var sSec = secondes.toString();
    if (hours < 10)
        sHours = "0" + sHours;
    if (minutes < 10)
        sMinutes = "0" + sMinutes;
    if (secondes < 10)
        sSec = "0" + sSec;
    return date + "T" + sHours + ":" + sMinutes + ":" + sSec;
};
// export var getClientCodeDetails = (osType) => {
//   let clientCodeIOS;
//   if (osType == "iOS") {
//     clientCodeIOS = localStorage.getItem("ClientCode");
//   } else if (osType.toLowerCase() == "android") {
//     clientCodeIOS = AndroidNativeInterface.getClientCode();
//   } else {
//     clientCodeIOS = localStorage.getItem("ClientCode");
//   }
//   return clientCodeIOS;
// };
export var getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export var checkingLayoutType = (type) => {
    if (type === "s") {
        return "1";
    }
    else if (type === "2h" || type === "2v") {
        return "2";
    }
    else if (type === "3v" ||
        type === "3h" ||
        type === "3s" ||
        type === "2-1" ||
        type === "1-2" ||
        type === "3r") {
        return "3";
    }
    else if (type === "4" ||
        type === "4h" ||
        type === "4v" ||
        type === "4s" ||
        type === "1-3" ||
        type === "2-2") {
        return "4";
    }
    else if (type === "1-4" ||
        type === "5s" ||
        type === "2-3" ||
        type === "5h") {
        return "5";
    }
    else if (type === "6" || type === "6c" || type === "6h") {
        return "6";
    }
    else if (type === "7h") {
        return "7";
    }
    else if (type === "8" || type === "8c" || type === "8h") {
        return "8";
    }
};
export const AlterUrlParam = async (reqInfo) => {
    var finalResult;
    var scriptCode = reqInfo.includes(":")
        ? Number(reqInfo.split(":")[1].split("/")[1].split("-")[0])
        : reqInfo.split("/")[1].split("-")[0];
    var exchangeType = reqInfo.includes(":")
        ? reqInfo.split(":")[1].split("/")[1].split("-")[1]
        : reqInfo.split("/")[1].split("-")[1];
    var exchange = reqInfo.split(":")[0].split("")[0];
    if (scripInfo_cache_value[exchange + exchangeType + scriptCode]) {
        return scripInfo_cache_value[exchange + exchangeType + scriptCode];
    }
    try {
        await fetch(`https://chartstt.5paisa.com/chart/scripinfo/${exchange}/${exchangeType}/${scriptCode}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + JSON.parse(localStorage.TokenResponseTv).access_token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
            finalResult = data.Data;
            scripInfo_cache_value[exchange + exchangeType + scriptCode] = data.Data;
        });
    }
    catch (error) {
        //  loggly_util.logAdvancedMessage(error, {
        //    error: error.message,
        //    APIURL: `https://chartstt.5paisa.com/chart/scripinfo/${exchange}/${exchangeType}/${scriptCode}`,
        //    Windowurl: window.location.href,
        //    Token: JSON.parse(localStorage.TokenResponseTv).access_token,
        //  });
    }
    //sessionStorage.setItem("IsIndice", finalResult.IsIndice)
    sessionStorage.setItem("IsIndice" + scriptCode, finalResult.IsIndice);
    return finalResult;
};
export const changeURLparam = async (passData) => {
    var url = new URL(window.location);
    var reqtype;
    var ShortName;
    if (passData.ExchType == "U" || passData.Exch == "M") {
        reqtype = "future-curcom";
        ShortName = passData.Name.split(" ")[0];
    }
    else if (passData.InstrumentType == "INDICE" ||
        passData.InstrumentType == "EQUITY") {
        reqtype = "overview";
        if (passData.Name.includes("BSE BANKEX")) {
            ShortName = passData.Name.split(" ")[1];
        }
        else {
            ShortName = passData.Name.split(" ")[0];
        }
    }
    else if (passData.InstrumentType == "FUTURES" ||
        passData.InstrumentType == "OPTIONS") {
        ShortName = passData.Name.split(" ")[0];
        reqtype = passData.InstrumentType.toLowerCase();
    }
    else {
        reqtype = passData.InstrumentType.toLowerCase();
    }
    if (passData.InstrumentType == "OPTIONS") {
        if (passData.Exch == "N") {
            url.searchParams.set("exchange", "nse");
        }
        else if (passData.Exch == "M") {
            url.searchParams.has("exch")
                ? url.searchParams.delete("exch")
                : url.searchParams.set("exchange", "M");
        }
        else {
            url.searchParams.set("exchange", "bse");
        }
        url.searchParams.set("symbol", ShortName.trim());
        url.searchParams.set("exchType", passData.ExchType);
        url.searchParams.set("scripCode", passData.ScripCode);
        url.searchParams.set("type", reqtype);
        url.searchParams.set("option_type", passData.CPType);
        url.searchParams.set("strike_price", passData.StrikeRate);
        url.searchParams.set("expiry_date", passData.Expiry);
    }
    else if (passData.InstrumentType == "FUTURES") {
        if (passData.Exch == "N") {
            url.searchParams.delete("exchange");
            url.searchParams.set("exch", "nse");
        }
        else if (passData.Exch == "M") {
            url.searchParams.has("exch")
                ? url.searchParams.delete("exch")
                : url.searchParams.set("exchange", "M");
        }
        else {
            url.searchParams.set("exchange", "bse");
        }
        url.searchParams.set("type", reqtype);
        url.searchParams.set("exchType", passData.ExchType);
        url.searchParams.set("expiry_date", passData.Expiry);
        url.searchParams.set("symbol", passData.ShortName);
        url.searchParams.set("scripCode", passData.ScripCode);
        if (url.searchParams.has("strike_price")) {
            url.searchParams.delete("strike_price");
        }
        if (url.searchParams.has("option_type")) {
            url.searchParams.delete("option_type");
        }
    }
    else if (passData.InstrumentType == "EQUITY" ||
        passData.InstrumentType == "INDICE") {
        if (url.searchParams.has("strike_price")) {
            url.searchParams.delete("strike_price");
        }
        if (url.searchParams.has("option_type")) {
            url.searchParams.delete("option_type");
        }
        if (url.searchParams.has("expiry_date")) {
            url.searchParams.delete("expiry_date");
        }
        if (passData.Exch == "N") {
            url.searchParams.set("exchange", "nse");
        }
        else {
            url.searchParams.set("exchange", "bse");
        }
        url.searchParams.set("symbol", ShortName);
        url.searchParams.set("exchType", passData.ExchType);
        url.searchParams.set("scripCode", passData.ScripCode);
        url.searchParams.set("type", reqtype);
    }
    window.history.pushState({}, "", url);
    try {
        setTimeout(function () {
            let new_ddd;
            let ddd = document
                .getElementById("tv_chart_container")
                .getElementsByTagName("iframe");
            if (ddd.length == 2) {
                new_ddd = document
                    .getElementById("tv_chart_container")
                    .getElementsByTagName("iframe")[1]
                    .contentDocument.getElementsByTagName("body")[0]
                    .querySelector(".dateRangeExpanded-BXXUwft2");
            }
            else {
                new_ddd = document
                    .getElementById("tv_chart_container")
                    .getElementsByTagName("iframe")[0]
                    .contentDocument.getElementsByTagName("body")[0]
                    .querySelector(".dateRangeExpanded-BXXUwft2");
            }
            let reqDivs = new_ddd.querySelectorAll("div")[0].querySelectorAll("div");
            var newUrl = new URL(window.location);
            reqDivs.forEach((div) => {
                if (newUrl.searchParams.get("type") != "overview") {
                    if (div.textContent == "1Y" ||
                        div.textContent == "5Y" ||
                        div.textContent == "MAX") {
                        div.style.display = "none";
                    }
                }
                else {
                    div.style.display = "block";
                }
            });
        }, 1000);
    }
    catch (error) {
        console.log(error);
    }
};
export async function getSellAuthStatus() {
    var _a;
    let orderdata = JSON.parse(localStorage.getItem(localStorage.getItem("ClientCode") + "-order"));
    let data = orderdata.symbol.split("/");
    let ExchType = data[1].split("-")[1];
    let scripCode = data[1].split("-")[0];
    let exchange;
    if (data[0].split(":")[0] == "BSE") {
        exchange = "B";
    }
    else if (data[0].split(":")[0] == "NSE") {
        exchange = "N";
    }
    else if (data[0].split(":")[0] == "M" || data[0].split(":")[0] == "MCX") {
        exchange = "M";
    }
    else if (data[0].split(":")[0] == "U") {
        exchange = "U";
    }
    let res = await fetch("https://gateway.5paisa.com/tradeapi/GetSellAuthorizationStatus", {
        method: "POST",
        credentials: "include",
        headers: {
            Authorization: `Bearer ${(_a = localStorage
                .getItem("JWTToken")) === null || _a === void 0 ? void 0 : _a.replace("JWTToken", "")} `,
            clientid: "NTc4MDMyOTY=",
            "ocp-apim-subscription-key": "a4af51382266497bb5464d95fbb2017b",
            "content-type": "application/json",
        },
        body: JSON.stringify({
            head: {
                appName: "5PTRADE",
                appSource: "09",
                appVer: "1.0",
                osName: "Web",
                key: "8eeee59bdab88bace6189d001f96487e",
                requestCode: "5PGetSellAuthorizationStatus",
            },
            body: {
                ClientCode: localStorage.getItem("ClientCode"),
                GetSellAuthorizationStatusReqList: [
                    {
                        ExchType: ExchType,
                        Exch: exchange,
                        ScripCode: scripCode,
                        Qty: orderdata.qty,
                    },
                ],
            },
        }),
    });
    const authStatus = await res.json();
    return authStatus;
}
export const getPreferenceApi = async (clientCode) => {
    const url = `${GET_PREFERENCE_BASE_URL}${clientCode}`;
    const headers = {
        "Ocp-apim-subscription-key": APP_OCP_APIM_KEY,
        "content-type": "application/json",
        access_token: localStorage.getItem("JWTToken"),
    };
    const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
    };
    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.json();
    }
    catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        throw error;
    }
};
export const setPreferenceApi = async (prefData, clientCode) => {
    const url = `${POST_PREFERENCE_BASE_URL}${clientCode}`;
    const headers = {
        "Ocp-apim-subscription-key": APP_OCP_APIM_KEY,
        access_token: localStorage.getItem("JWTToken"),
        "content-type": "application/json",
    };
    const body = {
        chart: {
            themeVal: prefData.themeVal,
            productVal: prefData.productVal,
            defaultClose: prefData.defaultClose,
        },
    };
    const requestOptions = {
        method: "POST",
        headers: headers,
        redirect: "follow",
        body: JSON.stringify(body),
    };
    await fetch(url, requestOptions);
};
export let captureEvent = (EventName, property) => {
    window.clevertap.event.push(EventName, property);
};
export const getExch_Exchtype_Scripcode = (input) => {
    const [exch, rest] = input.split(":");
    const lastDashIndex = rest.lastIndexOf("-");
    const fullSymbol = rest.substring(0, lastDashIndex);
    const [symbolNamefromticker, leftover] = fullSymbol.split("/");
    const symbol = symbolNamefromticker.split(" ")[1];
    const ExchType = rest.substring(lastDashIndex + 1);
    const ScripCode = fullSymbol.split("/")[1];
    const Exch = exch[0];
    return {
        Exch,
        ScripCode,
        ExchType,
        symbol,
    };
};
export const get_New_Symbol = (input) => {
    const parts = input.split(":");
    const exchangeCode = parts[0];
    const details = parts[1].split("/");
    const name = details[0];
    const scripCode = details[1];
    const scripType = details[3];
    let exchange;
    switch (exchangeCode) {
        case "N":
        case "NSE":
            exchange = "NSE";
            break;
        case "B":
        case "BSE":
            exchange = "BSE";
            break;
        case "M":
        case "MCX":
            exchange = "MCX";
            break;
        default:
            exchange = "NSE";
    }
    return `${exchange}:${name}/${scripCode}-${scripType}`;
};
export const unsubscribeExch_Exchtype_Scripcode = (input) => {
    const [exch, rest] = input.split(":");
    const [name, remaining] = rest.split("/");
    const [scripCode, restWithAdditional] = remaining.split("-");
    const [exchType, additional] = restWithAdditional.split("_#_");
    const exchangeFirstLetter = exch[0];
    return {
        exchangeFirstLetter,
        scripCode,
        exchType,
    };
};
export const checkTimeDiff = () => {
    const currentTime = new Date().getTime();
    let differenceInMillis = currentTime - lastRecdDataTime;
    // differenceInMinutes = differenceInMillis / (1000 * 60);
    if (differenceInMillis >= 180000) {
        const param = getUrlParameters(window.location);
        const getAutoSaveData = sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart") != null
            ? JSON.parse(sessionStorage.getItem("autoSaveStorageMultiLayout_Tradechart"))
            : JSON.parse(sessionStorage.getItem("autoSaveStorage_Tradechart" + param.scripCode));
        window.tvWidget.load(getAutoSaveData);
    }
    lastRecdDataTime = currentTime;
};
export const getDepthToken = async (token) => {
    //if(token!==undefined || token!=="undefined") token = JSON.parse(token);
    if (token && token !== "undefined") {
        token = JSON.parse(token);
    }
    let authKeyExpired = false;
    if (token && token !== "undefined") {
        authkey = token;
        if (new Date(parseInt(authkey.expires_in) * 1000) < new Date()) {
            authKeyExpired = true;
        }
    }
    if (!authkey || authKeyExpired) {
        var authkey = "";
        var settings = {
            async: false,
            crossDomain: true,
            method: "POST",
            headers: {
                "ocp-apim-subscription-key": "d396f0ca354f4a08b4c226343ae3ed1b",
            },
        };
        const data = await makeApiRequest(`https://dataservice.5paisa.com/TokenService/Marketfeed-Token`, settings);
        if ((data.Response && data.Response === "Error") || data.length === 0) {
            onHistoryCallback([], {
                noData: true,
            });
            return;
        }
        localStorage.setItem('DepthTokenResponseTv', JSON.stringify(data));
        authkey = data;
    }
    return authkey;
};
export const sortDataforDepth = (data) => {
    let askPrice = [];
    let bidPrice = [];
    for (let i = 0; i < data.length; i++) {
        const obj = { price: data[i].Price, volume: data[i].Quantity };
        if (data[i].BbBuySellFlag === 66) {
            bidPrice.push(obj);
        }
        else {
            askPrice.push(obj);
        }
    }
    return { askPrice, bidPrice };
};
/// Netposition Tab Call function Button Observer
export const Alltab = (firstTab, brokerFac) => {
    let activeTab = "positions"; // Start with "positions" as the active tab
    // Function to handle tab switching
    function handleTabClick(newTab) {
        if (newTab !== activeTab) {
            if (newTab === "positions") {
                brokerFac.positions(); // Trigger brokerFac.positions() only when switching to the Positions tab
            }
            activeTab = newTab;
        }
    }
    // Function to retrieve the tab element by index
    function getTabElement(index, firstTab) {
        if (firstTab === 'Hide panel') {
            const tabElement = document
                .getElementById("tv_chart_container")
                .getElementsByTagName("iframe")[0]
                .contentDocument
                .getElementsByTagName("body")[0]
                .getElementsByTagName("div")[1]
                .getElementsByClassName("layout__area--bottom")[0]
                .childNodes[1].childNodes[3]
                .getElementsByClassName("scroll-wrap-SmxgjhBJ")[0]
                .childNodes[0].childNodes[index];
            return tabElement || null; // Return null if the element is not found
        }
        else {
            const tabElement = document.getElementById("tv_chart_container").getElementsByTagName("iframe")[0].contentDocument.getElementsByTagName("body")[0].getElementsByTagName("div")[1].getElementsByClassName("layout__area--bottom")[0].childNodes[1].childNodes[2].childNodes[0].childNodes[2].nextSibling.getElementsByClassName("scroll-wrap-SmxgjhBJ")[0].childNodes[0].childNodes[index];
            return tabElement || null; // Return null if the element is not found
        }
    }
    // Retrieve and cache tab elements
    const positionTab = getTabElement(0, firstTab);
    const ordersTab = getTabElement(1, firstTab);
    const accountSummaryTab = getTabElement(2, firstTab);
    const holdingsTab = getTabElement(3, firstTab);
    const closedPositionsTab = getTabElement(4, firstTab);
    // Ensure the elements are found before adding event listeners
    if (positionTab) {
        positionTab.addEventListener("click", () => handleTabClick("positions"));
    }
    if (ordersTab) {
        ordersTab.addEventListener("click", () => handleTabClick("orders"));
    }
    if (accountSummaryTab) {
        accountSummaryTab.addEventListener("click", () => handleTabClick("accountsummary"));
    }
    if (holdingsTab) {
        holdingsTab.addEventListener("click", () => handleTabClick("holdings"));
    }
    if (closedPositionsTab) {
        closedPositionsTab.addEventListener("click", () => handleTabClick("closed_positions"));
    }
};
export const observeTabChanges = (brokerFac) => {
    // Select the target node
    const targetNode = document
        .getElementById("tv_chart_container")
        .getElementsByTagName("iframe")[0]
        .contentDocument
        .getElementsByTagName("body")[0]
        .getElementsByTagName("div")[1].getElementsByClassName("layout__area--bottom")[0]
        .childNodes[0].childNodes[0]
        .getElementsByClassName("buttons-dA6R3Y1X")[0].children[0];
    // Check initial state
    const initialTabName = targetNode.getAttribute('aria-label');
    if (initialTabName === 'Hide panel' && lastActiveTabState !== initialTabName) {
        Alltab(initialTabName, brokerFac);
        lastActiveTabState = initialTabName; // Update last active state
    }
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.attributeName === 'aria-label') {
                const tabName = mutation.target.getAttribute('aria-label');
                if (tabName === 'Hide panel' && lastActiveTabState !== tabName) {
                    setTimeout(() => Alltab(undefined, brokerFac), 2000); // Delay the function call to ensure the tab elements are available
                    lastActiveTabState = tabName; // Update last active state
                }
            }
        });
    });
    // Configuration for the observer
    const config = { attributes: true };
    // Start observing the target node
    observer.observe(targetNode, config);
};
export const objectForOrderForm = async (buySellFlag, isLimitOrder, askprice_val) => {
    let newUrlParam = new URL(window.location).searchParams;
    let name = `${newUrlParam.get("symbol")}`;
    if (newUrlParam.get("type") !== "overview") {
        name += ` ${newUrlParam.get("expiry_date")}`;
        if (newUrlParam.has("option_type")) {
            name += ` ${newUrlParam.get("option_type")}`;
        }
        if (newUrlParam.has("strike_price")) {
            name += ` ${newUrlParam.get("strike_price").replaceAll(".00", "")}`;
        }
    }
    var myObj = {
        isBuy: buySellFlag,
        name: name,
        exchange: newUrlParam.has("exchange")
            ? newUrlParam.get("exchange").split("")[0].toUpperCase()
            : newUrlParam.get("exch").split("")[0].toUpperCase(),
        exchangeType: newUrlParam.get("exchType"),
        scripCode: newUrlParam.get("scripCode"),
        lastTradePrice: JSON.parse(localStorage.getItem("lastRate")).LastRate,
        previousClose: JSON.parse(localStorage.getItem("lastRate"))
            .previousClose,
        isBasket: false,
        openedFrom: "chart",
        orderFor: "P",
    };
    if (isLimitOrder) {
        const orderData = {
            AHProcess: "N",
            AfterHours: "N",
            AtMarket: "N",
            BrokerOrderId: 0,
            BuySell: buySellFlag ? "B" : "S",
            DelvIntra: "",
            DisClosedQty: 0,
            Exch: newUrlParam.has("exchange")
                ? newUrlParam.get("exchange").split("")[0].toUpperCase()
                : newUrlParam.get("exch").split("")[0].toUpperCase(),
            ExchOrderID: 0,
            ExchType: newUrlParam.get("exchType"),
            MarketLot: 0,
            OrderID: 0,
            OrderStatus: "",
            OrderValidUpto: "",
            OrderValidity: 0,
            PendingQty: 0,
            Qty: 0,
            Rate: askprice_val ? parseFloat(askprice_val) : 0,
            SLTriggerRate: 0,
            SMOProfitRate: 0,
            SMOSLLimitRate: 0,
            SMOSLTriggerRate: 0,
            SMOTrailingSL: 0,
            StopLossPrice: 0,
            TradedQty: 0,
            WithSL: "N",
        };
        myObj.orderData = orderData;
    }
    console.log(myObj);
    return myObj;
};
export const orderObjectUtilityFunction = async (obj) => {
    if (browserName != "FIREFOX") {
        window.parent.postMessage(obj, window.location.ancestorOrigins[0]);
    }
    else {
        try {
            function getAncestorOrigins() {
                const urls = [];
                let parentWin = window;
                while (parentWin !== window.top) {
                    if (parentWin.document.referrer) {
                        try {
                            const url = new URL(parentWin.document.referrer);
                            urls.push(url.origin);
                        }
                        catch (e) {
                        }
                    }
                    parentWin = parentWin.parent;
                }
                console.log(urls);
                return urls;
            }
            let pp = getAncestorOrigins();
            window.parent.postMessage(obj, pp[0]);
        }
        catch (error) {
            console.error('Error accessing parent window location:', error);
        }
    }
    ;
};
